import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { Organization, OrganizationProcessors } from 'src/app/models/organization.model';
import { OrganizationsService } from 'src/app/pages/views/organizations/organizations.service';
import { FormCreateActions } from 'src/app/util/constants';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { Processor } from 'src/app/models/processor.model';
import { ProcessorService } from '../../processors/processor.service';
import { ObjHasMap } from 'src/app/models/objHasMap.model';
import { CommonService } from 'src/app/services/common.service';
import { MerchantService } from '../../merchants/merchant.service';
import { Merchants, MerchantsInfo } from 'src/app/models/merchants.model';
import { UtilService } from 'src/app/services/util.service';
import { ModalManagerService } from 'src/app/services/modalManager.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-region',
  templateUrl: './form-region.component.html',
  styleUrls: ['./form-region.component.css']
})
export class FormRegionComponent implements OnInit {
  registerForm!: FormGroup;
  desactivado: boolean = false;
  listaOrg: Organization[] = [];
  
  verProcessor = false;  
  listprocessorBBDD: Processor[] = []
  listProcesadorFinal: Processor[] = []

  listPropiedadesSession: any[] | undefined = []

  selectedProc!: Processor
  selectedOrg: Organization = new Organization();

  objBBDD: Merchants = new Merchants();



  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private services: MerchantService,
    private orgService: OrganizationsService,
    public commonService : CommonService,
    private translate: TranslateService,
    public utilService : UtilService,
    private processorServices: ProcessorService,
    private modalManager: ModalManagerService,
    public dialogRef: MatDialogRef<FormRegionComponent>,
    public dialog: MatDialog) {

  }


  async cargar() {

    //organizaciones
    let mapQuery = new Map();
    let filter = QueryFilterList.construirFilterComplet(mapQuery,"beq" ,"template.hasRegions", "true");
  
    let rfOrg: RequestFilter = new RequestFilter(filter, 0, 100, "name,desc");
    let srOrg = await this.orgService.find(rfOrg,true);
    if (srOrg) {
      this.listaOrg = srOrg.content;
      if (this.listaOrg.length == 1) {
        // Si solo hay 1 ponemos por defecto valor
        this.selectedOrg = this.listaOrg[0];
      }
    }

    let rfProces: RequestFilter = new RequestFilter(null, 0, 100, "name,desc");
    let srProces = await this.processorServices.find(rfProces);
    if (srProces) {
      this.listprocessorBBDD = srProces.content;    
    }

  }


  async ngOnInit(): Promise<void> {

    this.registerForm = this.formBuilder.group({
      name: ["", Validators.compose([Validators.required,
      Validators.minLength(4),
      Validators.maxLength(50),
      Validators.pattern("^(?=[a-zA-Z0-9\\s._-]{4,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")])],   
      shortname: ["", Validators.compose([
        Validators.maxLength(5),
        Validators.pattern("^[a-zA-Z0-9]*$")
      ])],   
      procesador: [""], 
      mapaDatos:  this.formBuilder.array([]),
      orgMarcado: [null, Validators.compose([Validators.required])],
      phone: [""],
      email: ["", Validators.compose([
      Validators.email,
      Validators.maxLength(255),
      Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")
      ])]
    });


    await this.cargar();

    let nombre, email, phone,shortname,processor
    let orgBBDD = null;
    if (this.data?.obj) {
      //si entra es EDICION
      this.desactivado = true;

      this.objBBDD = this.data.obj;


      // alert(JSON.stringify(this.objBBDD))
      if (this.objBBDD) {
        let dominio = this.objBBDD.domain
        
        
        let dominioOrg = ""
        if (dominio.includes(".")) {
          dominioOrg = dominio.substring(0, dominio.indexOf("."));
        } else {
          dominioOrg = dominio
        }

        this.listaOrg.forEach(element => {
          if (element.domainRoot == dominioOrg) {
            this.selectorganizacion(element);
            orgBBDD = element
          };
        });
                
        if (this.objBBDD.info) {
          email = this.objBBDD.info.email;
          phone = this.objBBDD.info.phone;        
        }

        nombre = this.objBBDD.name;
        shortname = this.objBBDD.merchantId
      


      this.listPropiedadesSession=this.objBBDD.processors
      if (this.objBBDD.processors && this.objBBDD.processors.length > 0) {
        let tempShortName = this.objBBDD.processors[0].short
        this.listProcesadorFinal.forEach(element => {
          if (element.shortName == tempShortName) {
            processor = element
          };
        });
      }
    }// if (this.objBBDD) {
    }//if (this.data && this.data.obj) {
      
    this.registerForm = this.formBuilder.group({
      name: [nombre, Validators.compose([Validators.required])],  
      shortname  : [{value: shortname, disabled: true}],
      email: [email],
      procesador: [processor, Validators.compose([Validators.required])],
      phone: [phone],
      orgMarcado: [orgBBDD, Validators.compose([Validators.required])],
      mapaDatos:  this.formBuilder.array([])
    });

    this.inicialializarLista();

  }



  inicialializarLista(): void {
    const mapaDatos = this.registerForm.get('mapaDatos') as FormArray;
    //console.log(">>>>>>>>>>>>>"+JSON.stringify(this.listPropiedadesSession))
    let fb = this.formBuilder
    if(this.listPropiedadesSession){

      this.listPropiedadesSession.forEach(elementProper => { 
        let short = elementProper.short
        let estado = elementProper.active

        Object.keys(elementProper).forEach(function(key) {
            let value = elementProper[key];
            if(key!=="short" && key!=="active"){

              const grupo = fb.group({
                shortName:[short],
                llave: [key],
                valor: [value]
              })        
              mapaDatos.push(grupo);
            }
        });

        this.listprocessorBBDD.forEach(elementBBDD => {  
          if(elementBBDD.shortName===short){
            elementBBDD.active=estado;
          }
        });

        this.listProcesadorFinal.forEach(final => {
          if (final.shortName == short) {
            this.selectedProc=final
            this.verProcessor = true;
          };
    });


      
       
        
      });
      
    }   

  }


  async selectProcess(select: any) {
    this.selectedProc=select
    this.verProcessor = true;
  }

  async selectorganizacion(select: Organization) {
    
    let listaP: OrganizationProcessors[] | undefined = []
    listaP = select.processors
    this.listProcesadorFinal=[]
    if(listaP){
      listaP.forEach(element => {
          this.listprocessorBBDD.forEach(bbdd => {
                if (element.short == bbdd.shortName) {
                  if(element.active){
                      this.listProcesadorFinal.push(bbdd);
                  }
                };
          });
      });
    }

    if(this.listProcesadorFinal && this.listProcesadorFinal.length>0){
      this.selectedProc=this.listProcesadorFinal[0]
      this.verProcessor = true;
    }


  }



  onAddLista(): void {
    const mapaDatos = this.registerForm.get('mapaDatos') as FormArray;

    const grupo = this.formBuilder.group({
      shortName:[this.selectedProc.shortName],
      llave: [null],
      valor: [null]
    })

    mapaDatos.push(grupo);
  }

  borrarGrupo(i: number) {
    const mapaDatos = this.registerForm.get('mapaDatos') as FormArray;
    mapaDatos.removeAt(i);
 }

 get mapaDatosFormGroups () {
  return this.registerForm.get('mapaDatos') as FormArray
}
 getshortNamemapaDatosFormGroups (i: number) {
    const lines = this.registerForm.get('mapaDatos') as FormArray;   
    return lines.controls[i].get('shortName')?.value;
}




  onFormSubmit(): void {
    console.log(this.registerForm);

    if (this.registerForm.invalid) {
      return;
    }
    const data = this.applyFormats(this.registerForm);




    let objNew: Merchants = new Merchants();
    if (this.objBBDD.id) {
      objNew = this.objBBDD
    }

    objNew.name = data['name']
    objNew.merchantId = data['shortname']

    let orgNew: Organization
    orgNew = data['orgMarcado']
    objNew.domain = orgNew.domainRoot
    objNew.structure = this.utilService.obtenerStructure(true,false,false,false)



    let tempEmail = data['email']
    let tempphonP = data['phone']

    

    let properties: MerchantsInfo = new MerchantsInfo();
    if (tempEmail || tempphonP) {
      properties.email = tempEmail;
      properties.phone = tempphonP;
    }
    
    objNew.info = properties;
    objNew.type = "REGION";

    objNew.processors= this.procesarHasMap()

    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = this.translate.instant("REGION.create_title");
    dialogData.textI18n = this.translate.instant("REGION.create_desc");


    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });
    this.modalManager.registerModal(this.dialogRef);

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {

        console.log('Form SAVE action');
        let val:any;
        if (objNew.id) {
          val = await this.services.updateEditService(objNew)
        } else {
          val = await this.services.saveEditService(objNew)
        }

          if(val){
            this.dialogRef.close(FormCreateActions.SAVED);
          }
      //} else if (result === ConfirmDialogActions.CANCEL)
      }
    });
  }



  

  procesarHasMap(): any[] {
    let listProcesor: OrganizationProcessors[] = [];
    let procesor: OrganizationProcessors = new OrganizationProcessors();
    procesor.short=this.selectedProc.shortName
    procesor.active=true
    listProcesor[0]=procesor
        
    const lines = this.registerForm.get('mapaDatos') as FormArray;   
    lines.controls.forEach((element, index) => {
        let llave = element.get('llave')?.value;
        let valor = element.get('valor')?.value;      
        let shortName = element.get('shortName')?.value;
        listProcesor.forEach(temp => {
          if(temp.short===shortName){
            let obj:ObjHasMap=new ObjHasMap
            obj.llave=llave
            obj.valor=valor
            if(valor && llave){
              temp.listaValores.push(obj)              
            }
          }
        });
       //console.log("llave:"+llave+" valor:"+valor+" short:"+shortName)
    });

    let lista:any[]=[]
    let i=0;
    listProcesor.forEach(obj => {
      let myObj: {[index: string]:any} = {}  
      if(obj.active){
        myObj['active']=obj.active;
      }else{
        myObj['active']=false;
      }
      myObj['short']=obj.short;

      if(obj.listaValores && obj.listaValores.length>0){                        
          obj.listaValores.forEach(objT => {
            if(objT.llave){
                myObj[objT.llave]=objT.valor;
            }
          });
        }
        lista[i]=myObj
        i++
        
    });
    console.log("lista:"+JSON.stringify(lista))

    return lista;
  }




  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }



  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body: any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

}




