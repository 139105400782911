export class Rol {
  id: string | undefined;
  name!: string ;
  description!: string ;
  permissions: Permisos | undefined;  
  createdBy!: string;
  updatedBy!: string;
  createdAt!: string;
  updatedAt!: string;

  constructor() {
  }
}


export class Permisos {
  authserver:AuthserverPermisos | undefined;
  merchantsApi:MerchantApiPermisos | undefined;
  adminXiibero:XiiberoPermisos | undefined;

  constructor() {    
  }
}


export class AuthserverPermisos {
  role:string | undefined;
  user:string | undefined;
  group:string | undefined;

  constructor() {    
  }
}

export class MerchantApiPermisos {
  organization:string | undefined;
  merchant:string | undefined;
  terminal:string | undefined;
  notification:string | undefined;
  transaction:string | undefined;

  constructor() {    
  }
}

export class XiiberoPermisos {
  organization:string | undefined;
  merchant:string | undefined;
  region:string | undefined;
  division:string | undefined;
  terminal:string | undefined;
  notification:string | undefined;
  transaction:string | undefined;

  constructor() {    
  }
}

