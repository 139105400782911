
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { RequestFilter } from 'src/app/models/requestFilter.model';
import { FormCreateActions, TypeJerarquia } from 'src/app/util/constants';
import { MerchantService } from 'src/app/pages/views/merchants/merchant.service';
import { OrganizationsService } from 'src/app/pages/views/organizations/organizations.service';
import { Organization } from 'src/app/models/organization.model';
import { Merchants } from 'src/app/models/merchants.model';
import { Order, OrderTerminals } from 'src/app/models/order.model';
import { OrderService } from '../order.service';
import { TMSProfileService } from '../tmsprofile.service';
import { TMSProfile } from 'src/app/models/tmsProfile.model';
import { UtilService } from 'src/app/services/util.service';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { Manufacture, Model } from 'src/app/models/manufacture.model';
import { ModalManagerService } from '../../../../services/modalManager.service';
import { TranslateService } from '@ngx-translate/core';
import { CloseDialogData } from 'src/app/components/close-dialog/close-dialog.model';
import { CloseDialogComponent } from 'src/app/components/close-dialog/close-dialog.component';


@Component({
  selector: 'app-form-new-orders',
  templateUrl: './form-new-orders.component.html',
  styleUrls: ['./form-new-orders.component.css']
})
export class FormNewOrdersComponent implements OnInit,AfterViewInit {
  
  verRegion:boolean = false;  
  verDivision:boolean = false;  

  registerForm!: FormGroup;

  listaOrg:Organization[] = [];
  listaRegion:Merchants[] = [];
  listaDivision:Merchants[] = [];
  listaMerchant:Merchants[] = [];
  listaTMSProfiles: TMSProfile[] = [];
  listFabricantes: Manufacture[]  = [];
  listModelos:Model[]  = [];
  

  selectedManufacture="";
  selectedModel="";
  selectedOrg:Organization = new Organization();
  selectedRegion: Merchants = new Merchants();
  selectedDivision: Merchants = new Merchants();
  selectedMerch:Merchants = new Merchants();
  selectedTMSProfile:TMSProfile = new TMSProfile();
  showTmsTemplate:boolean = false;
  

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private popupDialogRef!: MatDialogRef<CloseDialogComponent>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private services: OrderService,
    private orgService: OrganizationsService,
    private merchService: MerchantService,
    private commonService: UtilService,
    private translate: TranslateService,
    private tmsProfileService: TMSProfileService,
    private modalManager: ModalManagerService,
    public dialogRef: MatDialogRef<FormNewOrdersComponent>,
    public dialog: MatDialog) { 
     
  }


  async ngAfterViewInit():  Promise<void>  {
      //ponemos por defecto valores
      if (this.listaTMSProfiles && this.listaTMSProfiles.length>0){
        this.selectedTMSProfile = this.listaTMSProfiles[0];
        this.showTmsTemplate = true;
      }
     
  }



  async cargar(){
   //Fabricantes
    let rfFab: RequestFilter= new RequestFilter(null,0, 100,"name,desc");       
    let srFab = await this.services.findManufacture(rfFab);   
    if(srFab){
      this.listFabricantes= srFab.content;
    }

   //organizaciones
   let rfOrg: RequestFilter= new RequestFilter(null,0, 100,"name,desc");       
   let srOrg = await this.orgService.find(rfOrg,true);     
   if(srOrg){
     this.listaOrg= srOrg.content;    
     if(this.listaOrg.length==1){
       // Si solo hay 1 ponemos por defecto valor
       this.selectedOrg = this.listaOrg[0];
     }
   }



    //Solo si hay una organización seleccionada
        
      let rfTms: RequestFilter= new RequestFilter(null,0, 100,"name,desc");       
      let srTms = await this.tmsProfileService.find(rfTms);
      if(srTms){
        this.listaTMSProfiles= srTms.content;   
        this.showTmsTemplate = true;
        if(this.listaTMSProfiles.length==1){
          // Si solo hay 1 ponemos por defecto valor
          this.selectedTMSProfile = this.listaTMSProfiles[0];
        }   
      } else {
        this.showTmsTemplate = false;
      }
    
  }


  async ngOnInit(): Promise<void> {
    
    this.registerForm = this.formBuilder.group({
      manufacture: ["", Validators.compose([Validators.required])]      ,
      model: ["", Validators.compose([Validators.required ])]      ,
      numTer: ["", Validators.compose([Validators.required, 
        Validators.maxLength(255),
        Validators.pattern(new RegExp("^[0-9\\s\\-.]*$"))
      ])]  ,   
     
      orgMarcado:[null, Validators.compose([Validators.required])],
      regionMarcado: [null],
      divisionMarcado: [null], 
      merchMarcado:[null, Validators.compose([Validators.required])],
      tmsProfileMarcado:[null, null]
    });
    

    await this.cargar();

    this.registerForm = this.formBuilder.group({
      manufacture: ["", Validators.compose([Validators.required])]      ,
      model: ["", Validators.compose([Validators.required ])]      ,
      numTer: ["", Validators.compose([Validators.required, 
        Validators.maxLength(255),
        Validators.pattern(new RegExp("^[0-9\\s\\-.]*$"))
      ])]  ,   
     
      orgMarcado:[null, Validators.compose([Validators.required])],
      regionMarcado: [null],
      divisionMarcado: [null], 
      merchMarcado:[null, Validators.compose([Validators.required])],
      tmsProfileMarcado:[null, null]
    });

   


  }




  async organizationFilter(ob: MatSelectChange) {
    let org: Organization;
    org = ob.value; 
    this.selectedOrg = org;
  
    let mostrarRegion = false;
    let mostrarDivision = false;
  
    // Restablece los valores de los campos relacionados al cambiar de organización
    this.registerForm.get('regionMarcado')?.setValue(null);
    this.registerForm.get('divisionMarcado')?.setValue(null);
    this.registerForm.get('merchMarcado')?.setValue(null);
    this.listaRegion = [];
    this.listaDivision = [];
    this.listaMerchant = [];
  
    if (org?.template) {
      let tieneRegion = this.commonService.checkJerarquia(org.template, TypeJerarquia.REGION);
      let tieneDivision = this.commonService.checkJerarquia(org.template, TypeJerarquia.DIVISION);
  
      if (tieneRegion) {
        mostrarRegion = true;
        // Traer regiones
        let sr = await this.commonService.pedirRegiones(org.domainRoot, "R");
        if (sr) {
          this.listaRegion = sr.content;
        }
      }
      
      if (tieneDivision) {
        mostrarDivision = true;
        // Traer divisiones
        let sr = await this.commonService.pedirDivision(org.domainRoot, "R");
        if (sr) {
          this.listaDivision = sr.content;
        }
      }
  
      if (!tieneRegion && !tieneDivision) {
        // Traer comerciantes si no hay región o división
        let sr = await this.commonService.pedirMerchant(org.domainRoot, "R");
        if (sr) {
          this.listaMerchant = sr.content;
        }
      }
    }
  
    this.verDivision = mostrarDivision;
    this.verRegion = mostrarRegion;
  }
  
 

  async regionFilter(ob:MatSelectChange) {
    let reg:Merchants
    reg=ob.value; 

    let mostrarDivision=false

    if(reg && this.selectedOrg.template){

      let tieneDivision = this.commonService.checkJerarquia(this.selectedOrg.template, TypeJerarquia.DIVISION)
      if(tieneDivision){  mostrarDivision=true   }


      if(tieneDivision){         
          //traemos las DIVISIONES
          let srDiv = await this.commonService.pedirDivision(reg.domain,"R");   
          if(srDiv){
            this.listaDivision= srDiv.content;
          }
      }else{
          //traemos las  MECHARNT
          let sr = await this.commonService.pedirMerchant(reg.domain,"R");   
          if(sr){
            this.listaMerchant= sr.content;
          }
      }
    }

    this.verDivision=mostrarDivision
  }


  async divisionFilter(ob:MatSelectChange) {
    let reg:Merchants
    reg=ob.value; 

    if(reg){
         //traemos las  MECHARNT
         let sr = await this.commonService.pedirMerchant(reg.domain,"R");   
         if(sr){
           this.listaMerchant= sr.content;
         }      
    }

  }

  
async fabricanteFilter(ob: MatSelectChange) {
  let reg: Manufacture;
  reg = ob.value;

  if (reg) {
    // Vaciar la lista de modelos antes de agregar los nuevos modelos
    this.listModelos = [];

    // Si el fabricante tiene modelos, añadirlos a la lista de modelos
    if (reg.model) {
      reg.model.forEach(m => {
        this.listModelos.push(m);
      });
    }
  }
}


  onFormSubmit(): void {
    console.log(this.registerForm);
    
    if (this.registerForm.invalid) {
      return;
    }    
    const data = this.applyFormats(this.registerForm);

    let objNew : Order = new Order();
    let orderTerminals : OrderTerminals = new OrderTerminals();

    orderTerminals.manufacturer=data['manufacture'].name 
    orderTerminals.model=data['model'].name
    orderTerminals.numberOfTerminals=data['numTer']   
    
    let orgNew: Organization
    orgNew = data['orgMarcado']
    let domainRoot= orgNew.domainRoot
    
    let regionNew:Merchants
    regionNew=data['regionMarcado']
    if(regionNew){
      domainRoot=regionNew.domain
    }
    
    let divisionNew:Merchants
    divisionNew=data['divisionMarcado']
    if(divisionNew){
      domainRoot=divisionNew.domain
    }

    let merchantNew:Merchants
    merchantNew=data['merchMarcado']
    if(merchantNew){
      domainRoot=merchantNew.domain
    }

    objNew.domain=domainRoot

    objNew.terminals=orderTerminals    
    objNew.tmsProfileId = data['tmsProfileMarcado']?.id
    


    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = this.translate.instant("ORDER.create_title") ;
    dialogData.textI18n = this.translate.instant("ORDER.create_desc");



    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '28%', panelClass: 'custom-modalbox',
      data: dialogData
    });
    this.modalManager.registerModal(this.dialogRef);

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        
          console.log('Form SAVE action');
          let val:any;
          val = await this.services.doOrder(objNew)


          const popUpData = new CloseDialogData(); 
          popUpData.titleI18n = val.orderNumber; 
          popUpData.textI18n =  this.translate.instant("ORDER.Number");
          popUpData.visibleButtom=false
          this.popupDialogRef= this.dialog.open(CloseDialogComponent, {
            width: '300px', panelClass: 'custom-modalbox',
            data: popUpData
          });
          this.modalManager.registerModal(this.dialogRef);

          this.dialogRef.close(FormCreateActions.SAVED);

      } else if (result === ConfirmDialogActions.CANCEL) {
        
      }
    });

  }


  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }



  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }


}
