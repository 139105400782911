import { Merchants } from "../merchants.model";

export class DataExport_Region_Xiibero {

  organizationName: string | undefined; 
  name: string | undefined;


  constructor(values: Merchants = new Merchants) {
    this.organizationName= values.hierarchy?.ORGANIZATION?.name
    this.name= values.name
  }


}


