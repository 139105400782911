<main>
   

   <div *ngIf="isDefault" class="grid-block" style="background-color: #e4effb; background-image: url('assets/img/welcome.svg'); width: 100%; height: 100vh; background-position:center center;background-repeat: no-repeat;"></div>
   
   
   
   <div class="container">
      
      <section *ngIf="isGetnet" class="section error-404 min-vh-90 d-flex flex-column align-items-center justify-content-center">
            <img src="assets/themes/getnet/img/logo.png" class="img-fluid" alt="Page Not Found">
            <br>
            <img src="assets/themes/getnet/img/welcome.png" class="img-fluid" alt="Page Not Found">
      </section>

      <section *ngIf="isUnicaja" class="section error-404 min-vh-90 d-flex flex-column align-items-center justify-content-center">
         <img src="assets/themes/unicaja/img/logo.png" class="img-fluid" alt="Page Not Found">
         <br>
         <img src="assets/themes/unicaja/img/welcome.png" class="img-fluid" alt="Page Not Found">
      </section>

      <section *ngIf="isIbercaja" class="section error-404 min-vh-90 d-flex flex-column align-items-center justify-content-center">
         <img src="assets/themes/ibercaja/img/logo.png" class="img-fluid" alt="Page Not Found">
         <br>
         <img src="assets/themes/ibercaja/img/welcome.png" class="img-fluid" alt="Page Not Found">
      </section>

      <section *ngIf="isEuronet" class="section error-404 min-vh-90 d-flex flex-column align-items-center justify-content-center">
         <img src="assets/themes/euronet/img/logo.png" class="img-fluid" alt="Page Not Found">
         <br>
         <img src="assets/themes/euronet/img/welcome.png" class="img-fluid" alt="Page Not Found">
      </section>

      <section *ngIf="isIngenico" class="section error-404 min-vh-90 d-flex flex-column align-items-center justify-content-center">
         <img src="assets/themes/ingenico/img/logo.svg" class="img-fluid" alt="Page Not Found">
         <br>
         <img src="assets/themes/ingenico/img/welcome.jpg" class="img-fluid" alt="Page Not Found">
      </section>

 
   </div>
</main>
