<main>
   <div class="container">
      <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div class="d-flex justify-content-center py-4 d-none">
                    <a routerLink="/" class="logo d-flex align-items-center w-auto">
                      <img src="assets/img/logo.png" alt="">
                      <span>Admin</span>
                    </a>
                  </div>
                  <div class="card mb-3">
                     <div class="card-body">
                        <div class="pt-4 pb-2">
                           <h5 class="card-title text-center pb-0 fs-4">{{ "register.Create an Account" | translate }}</h5>
                           <p class="text-center small">{{ "register.Enter the following details to create your account" | translate }}</p>
                        </div>
                        <form class="row g-3 needs-validation" novalidate [formGroup]="registerForm">
                           <div class="col-12">
                              <label for="yourEmail" class="form-label">{{ "register.Your Email" | translate }}</label> 
                              <input formControlName="yourEmail" type="email" name="email" class="form-control" id="yourEmail" readonly required>
                              <div class="invalid-feedback">{{ "register.Please enter a valid Email address!" | translate }}</div>
                           </div>
                           <div class="col-12">
                              <label for="yourUsername" class="form-label">{{ "register.Username" | translate }}</label>
                              <div class="input-group has-validation">
                                 <span class="input-group-text" id="inputGroupPrepend">&#64;</span> 
                                 <input formControlName="yourUsername" type="text" name="username" class="form-control" id="yourUsername" readonly required>
                                 <div class="invalid-feedback">{{ "register.Please choose a username." | translate }}</div>
                              </div>
                           </div>
                           <div class="col-12">
                              <label for="yourName" class="form-label">{{ "register.First Name" | translate }}</label> 
                              <input formControlName="yourName" type="text" name="name" class="form-control" id="yourName" required>
                              <div class="invalid-feedback">{{ "register.Please, enter your name!" | translate }}</div>
                              <mat-error *ngIf="registerForm.get('yourName')?.hasError('pattern')">{{ "register.Invalid input format" | translate }}</mat-error>
                              <mat-error *ngIf="registerForm.get('yourName')?.hasError('maxlength')">{{ "register.Maximum length exceeded" | translate }}</mat-error>
                           </div>
                           <div class="col-12">
                             <label for="yourLastName" class="form-label">{{ "register.Last Name" | translate }}</label> 
                             <input formControlName="yourLastName" type="text" name="name" class="form-control" id="yourLastName" required>
                             <div class="invalid-feedback">{{ "register.Please, enter your last name!" | translate }}</div>
                             <mat-error *ngIf="registerForm.get('yourLastName')?.hasError('pattern')">{{ "register.Invalid input format" | translate }}</mat-error>
                             <mat-error *ngIf="registerForm.get('yourLastName')?.hasError('maxlength')">{{ "register.Maximum length exceeded" | translate }}</mat-error>
                          </div>
                           <div class="col-12">
                              <label for="yourPassword" class="form-label">{{ "register.Password" | translate }}</label> 
                              <input  
                                formControlName="yourPassword" 
                                type="password" 
                                name="password" 
                                class="form-control"
                                id="yourPassword" required>
                              <div id="yourPasswordFeedback" class="invalid-feedback">{{ "register.Please enter your password!" | translate }}</div>
                              <mat-error *ngIf="registerForm.get('yourPassword')?.hasError('minlength')">{{ "register.Minimum length not reached" | translate }}</mat-error>
                              <mat-error *ngIf="registerForm.get('yourPassword')?.hasError('maxlength')">{{ "register.Maximum length exceeded" | translate }}</mat-error>
                              <mat-error *ngIf="registerForm.get('yourPassword')?.hasError('pattern')">{{ "register.Password should have at least 8 characters: one lowercase, one uppercase, special characters and one digit" | translate }}</mat-error>
                           </div>
                           <div class="col-12">
                              <div class="form-check">
                                 <input formControlName="acceptedTermsAndConditions" class="form-check-input" name="terms" type="checkbox" value="" id="acceptTerms" required>
                                 <label class="form-check-label" for="acceptTerms">{{ "register.I agree and accept the terms and conditions" | translate }}</label>
                                 <div class="invalid-feedback">{{ "register.You must agree before submitting." | translate }}</div>
                              </div>
                           </div>
                           <div class="col-12">
                             <button class="btn btn-primary w-100" type="submit" (click)="onFormSubmit()">{{ "register.Create Account" | translate }}</button>
                           </div>                           
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</main>
