
    <div class="pagetitle">
       <h1 i18n>{{"Rol" | translate }}</h1>      
    </div>
    <section class="section">
       <form [formGroup]="registerForm">


        <div class="row">
            <div class="col-lg-12">
               <div class="card cardCustos">
                  <mat-form-field ngDefaultControl >
                        <h5 i18n>{{"Name" | translate }} <span class="obligatorio">*</span></h5>
                        <input formControlName="name" matInput type="text"  required  [readonly]="soloLecura" maxlength="30">
                        <mat-error *ngIf="registerForm.get('name')?.hasError('required')"  i18n>{{"Please enter a value!" | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('name')?.hasError('pattern')"  i18n>{{"Invalid input format" | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('name')?.hasError('maxLength')"  i18n>{{"Maximum length exceeded" | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('name')?.hasError('minLength')"  i18n>{{"Minimum length not reached" | translate }}</mat-error>

                  </mat-form-field>
                </div>
            </div>
         </div>
         <div class="row">
            <div class="col-lg-12">
               <div class="card cardCustos">
                  <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Description" | translate }}</h5>
                        <input formControlName="description" matInput type="text"  [readonly]="soloLecura" maxlength="150">
                        <mat-error *ngIf="registerForm.get('description')?.hasError('maxLength')"  i18n>{{"Maximum length exceeded" | translate }}</mat-error>
                   </mat-form-field> 
                </div>
            </div>
        </div>


       <div class="row">
          <div class="col-lg-6">
             <div class="card cardCustos">               
                   <h5 i18n>{{"Permissions in Organizations" | translate }}</h5>
                   <section class="example-section">
                    <mat-checkbox class="example-margin" formControlName="org_R"   > <i class="bi bi-eye"></i></mat-checkbox>
                    <mat-checkbox class="example-margin" formControlName="org_W"   [hidden]="isEntornoGetnet" > <i class="bi bi-pencil"></i></mat-checkbox>
                    <mat-checkbox class="example-margin" formControlName="org_D"   [hidden]="isEntornoGetnet" > <i class="bi bi-trash"></i></mat-checkbox>
                  </section>               
             </div>         
            <div class="card cardCustos" [hidden]="isEntornoGetnet">               
                  <h5 i18n>{{"Permissions in Divisions" | translate }}</h5>
                  <section class="example-section">
                     <mat-checkbox class="example-margin" formControlName="division_R"  > <i class="bi bi-eye"></i></mat-checkbox>
                     <mat-checkbox class="example-margin" formControlName="division_W"  > <i class="bi bi-pencil"></i></mat-checkbox>
                     <mat-checkbox class="example-margin" formControlName="division_D"   > <i class="bi bi-trash"></i></mat-checkbox>
                  </section>
            </div>
             <div class="card cardCustos">               
                    <h5 i18n>{{"Permissions in Merchants" | translate }}</h5>
                    <section class="example-section">
                     <mat-checkbox class="example-margin" formControlName="merchant_R"  > <i class="bi bi-eye"></i></mat-checkbox>
                     <mat-checkbox class="example-margin" formControlName="merchant_W"   [hidden]="isEntornoGetnet" > <i class="bi bi-pencil"></i></mat-checkbox>
                     <mat-checkbox class="example-margin" formControlName="merchant_D"   [hidden]="isEntornoGetnet" > <i class="bi bi-trash"></i></mat-checkbox>
                   </section>
             </div>
             <div class="card cardCustos">
                    <h5 i18n>{{"Permissions in Terminals" | translate }}</h5>
                    <section class="example-section">
                     <mat-checkbox class="example-margin" formControlName="term_R"  > <i class="bi bi-eye"></i></mat-checkbox>
                     <mat-checkbox class="example-margin" formControlName="term_W"   [hidden]="isEntornoGetnet" > <i class="bi bi-pencil"></i></mat-checkbox>
                     <mat-checkbox class="example-margin" formControlName="term_D"   [hidden]="isEntornoGetnet" > <i class="bi bi-trash"></i></mat-checkbox>
                   </section>
             </div>
             <div class="card cardCustos">
               <h5 i18n>{{"Permissions in Transactions" | translate }}</h5>
               <section class="example-section">
                <mat-checkbox class="example-margin" formControlName="tran_R"  > <i class="bi bi-eye"></i></mat-checkbox>
                <mat-checkbox class="example-margin" formControlName="tran_W"   [hidden]="isEntornoGetnet || isEntornoXiibero" > <i class="bi bi-pencil"></i></mat-checkbox>
                <mat-checkbox class="example-margin" formControlName="tran_D"   [hidden]="isEntornoGetnet || isEntornoXiibero" > <i class="bi bi-trash"></i></mat-checkbox>
              </section>
        </div>
          </div>
          <div class="col-lg-6">

            <div class="card cardCustos" [hidden]="isEntornoGetnet">               
               <h5 i18n>{{"Permissions in Regions" | translate }}</h5>
               <section class="example-section">
                  <mat-checkbox class="example-margin" formControlName="region_R"  > <i class="bi bi-eye"></i></mat-checkbox>
                  <mat-checkbox class="example-margin" formControlName="region_W"  > <i class="bi bi-pencil"></i></mat-checkbox>
                  <mat-checkbox class="example-margin" formControlName="region_D"  > <i class="bi bi-trash"></i></mat-checkbox>
               </section>
            </div>
            <div class="card cardCustos">               
                <h5 i18n>{{"Permissions in Users" | translate }}</h5>
                <section class="example-section">
                 <mat-checkbox class="example-margin" formControlName="user_R"  > <i class="bi bi-eye"></i></mat-checkbox>
                 <mat-checkbox class="example-margin" formControlName="user_W" > <i class="bi bi-pencil"></i></mat-checkbox>
                 <mat-checkbox class="example-margin" formControlName="user_D" > <i class="bi bi-trash"></i></mat-checkbox>
               </section>               
           </div>
           <div class="card cardCustos">               
                 <h5 i18n>{{"Permissions in Roles" | translate }}</h5>
                 <section class="example-section">
                  <mat-checkbox class="example-margin" formControlName="rol_R" > <i class="bi bi-eye"></i></mat-checkbox>
                  <mat-checkbox class="example-margin" formControlName="rol_W" > <i class="bi bi-pencil"></i></mat-checkbox>
                  <mat-checkbox class="example-margin" formControlName="rol_D" > <i class="bi bi-trash"></i></mat-checkbox>
                </section>
           </div>
           <div class="card cardCustos">               
                  <h5 i18n>{{"Permissions in Groups" | translate }}</h5>
                  <section class="example-section">
                  <mat-checkbox class="example-margin" formControlName="group_R" > <i class="bi bi-eye"></i></mat-checkbox>
                  <mat-checkbox class="example-margin" formControlName="group_W" > <i class="bi bi-pencil"></i></mat-checkbox>
                  <mat-checkbox class="example-margin" formControlName="group_D" > <i class="bi bi-trash"></i></mat-checkbox>
               </section>
            </div>
            <div class="card cardCustos">
               <h5 i18n>{{"Permissions in Notification" | translate }}</h5>
               <section class="example-section">
                <mat-checkbox class="example-margin" formControlName="alert_R" > <i class="bi bi-eye"></i></mat-checkbox>
                <mat-checkbox class="example-margin" formControlName="alert_W"  [hidden]="isEntornoGetnet || isEntornoXiibero"> <i class="bi bi-pencil"></i></mat-checkbox>
                <mat-checkbox class="example-margin" formControlName="alert_D"  [hidden]="isEntornoGetnet || isEntornoXiibero"> <i class="bi bi-trash"></i></mat-checkbox>
              </section>
        </div>
          </div>
       </div>


       <div class="row botonera">
            <div class="col-lg-12">
                    <button  class="btn btn-aceptar" (click)="onFormSubmit()" i18n  [disabled]="soloLecura">{{"Submit" | translate }}</button> 
                    <button  class="btn btn-cancelar" (click)="formExit()" i18n>{{"Cancel" | translate }}</button>
            </div>
        </div>


      
    </form>
    </section>

                
                
                
                
                
                

