import { Component, OnInit, ElementRef, Input, OnChanges } from '@angular/core';
import { Chart, TooltipItem } from 'chart.js';
import {  CHART_COLORS } from '../../util/chartsutils';

export interface chartPayload {
  labels: string[];
  values: number[];
  merchantIds?: string[];
}

@Component({
  selector: 'app-chart-top5-merchant',
  templateUrl: './chart-top5-merchant.component.html',
  styleUrls: ['./chart-top5-merchant.component.css']
})
export class ChartTop5MerchantComponent implements OnInit, OnChanges {
  @Input() chartTitle !: string;
  @Input() chartDataset!: chartPayload;

  chart: any;
  data : any;

  constructor() { }

  ngOnInit(): void {
    this.data = {
      labels: this.chartDataset.labels,
      datasets: [
        {
          label: this.chartTitle,
          data: this.chartDataset.values,
          ids: this.chartDataset.merchantIds,
          borderColor: CHART_COLORS.purple,
          backgroundColor: Object.values(CHART_COLORS),
        }
      ],
    };
    this.createChart();
  }

  ngOnChanges() {
    if (!(this.chart == undefined)) {
      this.chart.data.labels = this.chartDataset.labels;
      this.chart.data.datasets[0].data = this.chartDataset.values;
    }
    this.chart?.update();
  }

  createChart(){

    this.chart = new Chart("ChartTopMerchant", {
      type: 'bar',
      data: this.data,
      options: {
        responsive: true,
        indexAxis: 'y',
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each horizontal bar to be 2px wide
        elements: {
          bar: {
            borderWidth: 2,
          }
        },
        plugins: {
          legend: {
            display : false,
            position: 'right',
            align:'start'
          },
          title: {
            display: false,
            text: this.chartTitle
          },
          tooltip: {
            callbacks: {
              footer: (tooltipItems: TooltipItem<'bar'>[]) => {
                const dataset = tooltipItems[0].chart.data.datasets[tooltipItems[0].datasetIndex];
                const id = (dataset as any).ids[tooltipItems[0].dataIndex];
                return `${id ?? ''}`;
              }
            }
          }
        }
      },
    });
  }
}
