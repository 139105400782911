export class Users {
  id: string | undefined;
  firstName!: string | undefined;
  lastName: string | undefined;
  domain: string | undefined;
  email!: string ;
  username!: string;
  password: string | undefined;
  lastLogin: string | undefined;
  status: string | undefined;
  auth!: UserPermisos ;  
  profile!: UserProfile ;  
  token!: string | undefined;
  typeProfile!: string | undefined;
  acceptedTermsAndConditions!: boolean | false;
   
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
  
//  isUsing2FA: string | undefined;  
}



export class UserPermisos {
  domains!: string[]; 
  roles!: string[];
  groups!: string[];

  constructor() {    
  }
}


export class UserProfile {
  company!: string; 
  jobTitle!: string;
  country!: string;
  address!: string;
  phone!: string;
  locale!: string;
  zoneinfo!: string;

  constructor() {    
  }
}


