
<div class="pagetitle">
    <h1  i18n>{{"Merchant" | translate }}</h1>      
</div>
<section class="section">
     <form [formGroup]="registerForm">
 
         <div class="row">
            <div class="col-lg-4">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Organization" | translate }} <span class="obligatorio">*</span></h5>
                        <mat-select formControlName="orgMarcado" [(value)]="selectedOrg" (selectionChange)="organizationFilter($event)">
                            <mat-option *ngFor="let op of listaOrg" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('orgMarcado')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
           
            <div class="col-lg-4" *ngIf="verRegion">
                <div class="card cardCustos" >
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Region" | translate }} <span class="obligatorio">*</span></h5>
                        <mat-select formControlName="regionMarcado" [(value)]="selectedRegion" (selectionChange)="regionFilter($event)">
                            <mat-option *ngFor="let op of listaRegion" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('regionMarcado')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-4" *ngIf="verDivision">
                <div class="card cardCustos" >
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Division" | translate }} <span class="obligatorio">*</span></h5>
                        <mat-select formControlName="divisionMarcado" [(value)]="selectedDivision">
                            <mat-option *ngFor="let op of listaDivision" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('divisionMarcado')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>


         <div class="row">

            <div class="col-lg-4">
               <div class="card cardCustos">
                   <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Procesador" | translate }} </h5>
                        <mat-select formControlName="procesador" [(value)]="selectedProc"  (selectionChange)="selectProcess($event.value)">
                            <mat-option *ngFor="let op of listProcesadorFinal" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select> 
                      
                   </mat-form-field>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="card cardCustos"  *ngIf="verProcessCBS">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"MERCH.form.Acquirer_Merchant_Id" | translate }}  <span class="obligatorio">*</span></h5>
                        <input formControlName="merchantId" matInput type="text" matInput maxlength="40">
                        <mat-error *ngIf="registerForm.get('merchantId')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                       
                   </mat-form-field>
                 </div>
            </div>           
        </div>












        <div class="element-header">
            <span i18n>{{"MERCH.form.Merchant_Information" | translate }}</span>
        </div>
        <div class="row">             
           
            <div class="col-lg-6">
               <div class="card cardCustos">
                   <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Name" | translate }} <span class="obligatorio">*</span></h5>
                        <input formControlName="name" type="text" matInput required maxlength="40">
                        <mat-error *ngIf="registerForm.get('name')?.hasError('required')"  i18n>{{"Please enter a name!" | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('name')?.hasError('pattern')"  i18n>{{"Invalid input format" | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('name')?.hasError('maxLength')"  i18n>{{"Maximum length exceeded" | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div> 
            <div class="col-lg-3">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"MERCH.form.Franchise" | translate }}<span class="obligatorio">*</span></h5>
                        <input formControlName="franquicia" matInput type="text" maxlength="7">
                        <mat-error *ngIf="registerForm.get('franquicia')?.hasError('pattern')" i18n>{{"Invalid input format" | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('franquicia')?.hasError('maxlength')" i18n>{{"Maximum length exceeded" | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"MERCH.form.Local" | translate }}<span class="obligatorio">*</span></h5>
                        <input formControlName="local" matInput type="text" maxlength="8">
                        <mat-error *ngIf="registerForm.get('local')?.hasError('pattern')" i18n>{{"Invalid input format" | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('local')?.hasError('maxlength')" i18n>{{"Maximum length exceeded" | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
       </div>

        <div class="row">
            <div class="col-lg-4">
               <div class="card cardCustos">
                   <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"MERCH.form.Web" | translate }} <span class="obligatorio">*</span></h5>
                        <input formControlName="web" matInput type="text" matInput  maxlength="40">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-4">
               <div class="card cardCustos">
                   <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"MERCH.form.Phone" | translate }} <span class="obligatorio">*</span></h5>
                        <input formControlName="phone" matInput type="text" matInput  >
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="card cardCustos">

                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"MERCH.form.mcc" | translate }} <span class="obligatorio">*</span></h5>
                        <mat-select formControlName="merchantCategoryCode" [(value)]="selectedMCC"> 
                          <mat-form-field class="filterSelect">
                            <mat-label> </mat-label>
                            <input matInput placeholder="filter..." #filterplc>
                          </mat-form-field>
              
                          <mat-option [value]=""> </mat-option>
                          <div *ngFor="let op of listaMcc">
                            <mat-option  *ngIf="op.edited_description.toLowerCase().includes(filterplc.value.toLowerCase()) || op.mcc.includes(filterplc.value)" [value]="op" >
                               ({{op.mcc}}) - {{op.edited_description}}
                            </mat-option>
                          </div>         
                        </mat-select>
                      </mat-form-field>
                 
                 </div>
             </div>
           
        </div>
















        <div class="element-header">
            <span i18n>{{"MERCH.form.Address_Information" | translate }}</span>
        </div>

         <div class="row">
            <div class="col-lg-4">
               <div class="card cardCustos">

                <mat-form-field ngDefaultControl>
                    <h5 i18n>{{"Country" | translate }} <span class="obligatorio">*</span></h5>
                    <mat-select formControlName="country" [(value)]="selectedCountry">                            
                      <mat-form-field class="filterSelect">
                        <mat-label> </mat-label>
                        <input matInput placeholder="filter..." #filterCountry>
                      </mat-form-field>
          
                      <mat-option [value]=""> </mat-option>
                      <div *ngFor="let op of listaCountry">
                        <mat-option  *ngIf="op.name.toLowerCase().includes(filterCountry.value.toLowerCase())" [value]="op" >
                          {{op.name}}
                        </mat-option>
                      </div>         
                    </mat-select>
                  </mat-form-field>

                </div>
            </div>
            <div class="col-lg-4">
               <div class="card cardCustos">
                   <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"State" | translate }} <span class="obligatorio">*</span></h5>
                        <input formControlName="state" matInput type="text" matInput  maxlength="40">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-4">
               <div class="card cardCustos">
                   <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"City" | translate }} <span class="obligatorio">*</span></h5>
                        <input formControlName="city" matInput type="text" matInput maxlength="40" >
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
               <div class="card cardCustos">
                   <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Address" | translate }} <span class="obligatorio">*</span></h5>
                        <input formControlName="address" matInput type="text" matInput  >
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-4">
               <div class="card cardCustos">
                   <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"MERCH.form.cp" | translate }} <span class="obligatorio">*</span></h5>
                        <input formControlName="postalCode" matInput type="text" matInput  maxlength="10">
                    </mat-form-field>
                </div>
            </div>          
        </div>
      
















        <div class="element-header">
            <span i18n>{{"MERCH.form.Merchant_Contacts" | translate }}</span>
        </div>
        <div class="row">
                <div class="col-lg-4">
                    <div class="card cardCustos">
                        <div class="element-header">
                            <span i18n>{{"MERCH.form.Business_Contacts" | translate }}</span>
                        </div>
                        <mat-form-field ngDefaultControl>
                            <h5 i18n>{{"First Name" | translate }} <span class="obligatorio">*</span></h5>
                            <input formControlName="businessContact_firstName" matInput type="text" required  maxlength="40">
                            <mat-error *ngIf="registerForm.get('businessContact_firstName')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                   
                        </mat-form-field>
                        <mat-form-field ngDefaultControl>
                            <h5 i18n>{{"Last Name" | translate }} <span class="obligatorio">*</span></h5>
                            <input formControlName="businessContact_lastName" matInput type="text" required  maxlength="100">
                            <mat-error *ngIf="registerForm.get('businessContact_lastName')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                   
                        </mat-form-field>
                        <mat-form-field ngDefaultControl>
                            <h5 i18n>{{"Phone" | translate }} <span class="obligatorio">*</span></h5>
                            <input formControlName="businessContact_phone" matInput type="text" required  >
                            <mat-error *ngIf="registerForm.get('businessContact_phone')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                   
                        </mat-form-field>
                        <mat-form-field ngDefaultControl>
                            <h5 i18n>{{"Email" | translate }} <span class="obligatorio">*</span></h5>
                            <input formControlName="businessContact_email" matInput type="text" required  maxlength="40">
                            <mat-error *ngIf="registerForm.get('businessContact_email')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                   
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card cardCustos">
                        <div class="element-header">
                            <span i18n>{{"MERCH.form.Technical_Contacts" | translate }}</span>
                            <mat-checkbox formControlName="technicalContact_same" (change)="ocultarTechnicalContact($event)" ><span i18n>{{"Same as Business Contact" | translate }}</span> </mat-checkbox>
                 
                        </div>
                        <mat-form-field ngDefaultControl [hidden]="visibleTechnicalContact">
                            <h5 i18n>{{"First Name" | translate }} </h5>
                            <input formControlName="technicalContact_firstName" matInput type="text"   maxlength="40">
                        </mat-form-field>
                        <mat-form-field ngDefaultControl [hidden]="visibleTechnicalContact">
                            <h5 i18n>{{"Last Name" | translate }} </h5>
                            <input formControlName="technicalContact_lastName" matInput type="text" matInput  maxlength="100">
                        </mat-form-field>
                        <mat-form-field ngDefaultControl [hidden]="visibleTechnicalContact">
                            <h5 i18n>{{"Phone" | translate }} </h5>
                            <input formControlName="technicalContact_phone" matInput type="text" matInput  maxlength="40">
                        </mat-form-field>
                        <mat-form-field ngDefaultControl [hidden]="visibleTechnicalContact">
                            <h5 i18n>{{"Email" | translate }} </h5>
                            <input formControlName="technicalContact_email" matInput type="text" matInput  maxlength="40">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card cardCustos">
                        <div class="element-header">
                            <span i18n>{{"MERCH.form.Emergency_Contacts" | translate }}</span>
                            <mat-checkbox formControlName="emergencyContact_same" (change)="ocultarEmergencyContact($event)" ><span i18n>{{"Same as Business Contact" | translate }}</span> </mat-checkbox>
                 
                        </div>
                        <mat-form-field ngDefaultControl [hidden]="visibleEmergencyContact">
                            <h5 i18n>{{"First Name" | translate }} </h5>
                            <input formControlName="emergencyContact_firstName" matInput type="text" matInput  maxlength="40">
                        </mat-form-field>
                        <mat-form-field ngDefaultControl [hidden]="visibleEmergencyContact">
                            <h5 i18n>{{"Last Name" | translate }} </h5>
                            <input formControlName="emergencyContact_lastName" matInput type="text" matInput  maxlength="100">
                        </mat-form-field>
                        <mat-form-field ngDefaultControl [hidden]="visibleEmergencyContact">
                            <h5 i18n>{{"Phone" | translate }} </h5>
                            <input formControlName="emergencyContact_phone" matInput type="text" matInput  >
                        </mat-form-field>
                        <mat-form-field ngDefaultControl [hidden]="visibleEmergencyContact">
                            <h5 i18n>{{"Email" | translate }} </h5>
                            <input formControlName="emergencyContact_email" matInput type="text" matInput maxlength="40" >
                        </mat-form-field>
                    </div>
                </div>
        </div>

             
        
    </form>
</section>
   

<div class="row botonera">
    <div class="col-lg-12">
        <button  *ngIf="!desactivado" class="btn btn-aceptar" (click)="onFormSubmit()" i18n>{{"Submit" | translate }}</button> 
        <button  class="btn btn-cancelar" (click)="formExit()" i18n>{{"Cancel" | translate }}</button>
    </div>
</div>
                 
                 
                 
                 
                 
                 
 
 