import { AuthConfig } from 'angular-oauth2-oidc';
import { SERVER_URL_AUTH, VIWER_ENTORNO } from 'src/environments/environment';
import { ENTORNO } from '../util/constants';


// Mecanismo PKCE.
const authPKCEConfig: AuthConfig = {
  // Url of the Identity Provider
  //issuer: 'https://steyer-identity-server.azurewebsites.net/identity',
  //issuer: 'https://127.0.0.1:9000',
  //issuer: 'https://127.0.0.1:4200/auth',
//  issuer: 'https://angular.server:4200',
  issuer: SERVER_URL_AUTH.substring(0, SERVER_URL_AUTH.length-1),  // 'https://devauth.custosmobile.com' - 'https://auth.server:9000'
  //issuer: 'https://devauth.custosmobile.com',

  // URL of the SPA to redirect the user to after login
  //redirectUri: window.location.origin + '/dashboard',
  //redirectUri: 'https://oauth.pstmn.io/v1/callback',
  redirectUri: window.location.origin + '/welcome',//https://127.0.0.1:4200

//  logoutUrl: SERVER_URL_AUTH + '/redlogout',
  // URL of the SPA to redirect the user after silent refresh
  //silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  //silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,//window.location.origin + '/silent-refresh.html',//https://127.0.0.1:4200

  // The SPA's id. The SPA is registerd with this id at the auth-server
  //clientId: 'demo-resource-owner',
  //clientId: 'spa-app',
  clientId: 'spa-app',
  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'openid profile email authserver merchantsApi', // offline_access

  responseType: 'code',

  useSilentRefresh: false,
  //silentRefreshTimeout: 5000, // For faster testing
  timeoutFactor: 1, // For faster testing
  sessionChecksEnabled: true,
  showDebugInformation: true, // Also requires enabling "Verbose" level in devtools

  clearHashAfterLogin: true,
  oidc: true, // si false no muestra idtoken.
  
/*
  useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
  silentRefreshTimeout: 5000, // For faster testing
  timeoutFactor: 0.10, // For faster testing
  //timeoutFactor: 0.01,
  sessionChecksEnabled: true,
  showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
  clearHashAfterLogin: true, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  //nonceStateSeparator : 'semicolon' // Real semicolon gets mangled by Duende ID Server's URI encoding
*/



};

// Mecanismo PKCE.
const authXiiberoPKCEConfig: AuthConfig = {
  // Url of the Identity Provider
  //issuer: 'https://steyer-identity-server.azurewebsites.net/identity',
  //issuer: 'https://127.0.0.1:9000',
  //issuer: 'https://127.0.0.1:4200/auth',
//  issuer: 'https://angular.server:4200',
  issuer: SERVER_URL_AUTH.substring(0, SERVER_URL_AUTH.length-1),  // 'https://devauth.custosmobile.com' - 'https://auth.server:9000'
  //issuer: 'https://devauth.custosmobile.com',

  // URL of the SPA to redirect the user to after login
  //redirectUri: window.location.origin + '/dashboard',
  //redirectUri: 'https://oauth.pstmn.io/v1/callback',
  redirectUri: window.location.origin + '/welcome',//https://127.0.0.1:4200

  // URL of the SPA to redirect the user after silent refresh
  //silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  //silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,//window.location.origin + '/silent-refresh.html',//https://127.0.0.1:4200

  // The SPA's id. The SPA is registerd with this id at the auth-server
  //clientId: 'demo-resource-owner',
  //clientId: 'spa-app',
  //clientId: 'xiibero-spa',
  clientId: 'spa-app',
  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'openid profile email authserver merchantsApi adminXiibero', // offline_access

  responseType: 'code',

  useSilentRefresh: false,
  //silentRefreshTimeout: 5000, // For faster testing
  timeoutFactor: 1, // For faster testing
  sessionChecksEnabled: true,
  showDebugInformation: true, // Also requires enabling "Verbose" level in devtools

  clearHashAfterLogin: true,
  oidc: true, // si false no muestra idtoken.
  
/*
  useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
  silentRefreshTimeout: 5000, // For faster testing
  timeoutFactor: 0.10, // For faster testing
  //timeoutFactor: 0.01,
  sessionChecksEnabled: true,
  showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
  clearHashAfterLogin: true, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  //nonceStateSeparator : 'semicolon' // Real semicolon gets mangled by Duende ID Server's URI encoding
*/



};




// NON-PKCE no seguro para SPA pero funciona
 const authConfig: AuthConfig = {
    // Url of the Identity Provider
    //issuer: 'https://steyer-identity-server.azurewebsites.net/identity',
    issuer: 'http://auth.server:9000',
    //issuer: 'https://angular.server:9000',
    //issuer: 'https://127.0.0.1:9000',
  
    // URL of the SPA to redirect the user to after login
    //redirectUri: window.location.origin + '/dashboard',
    redirectUri: 'https://oauth.pstmn.io/v1/callback',
    //redirectUri: 'https://127.0.0.1:4200/dashboard',
    //redirectUri: window.location.origin + '/',
  
    // URL of the SPA to redirect the user after silent refresh
    //silentRefreshRedirectUri: window.location.origin + '/silent-refresh',
    silentRefreshRedirectUri: 'https://oauth.pstmn.io/v1/callback',
    //silentRefreshRedirectUri: window.location.origin +'/silent-refresh.html',//'https://127.0.0.1:4200/silent-refresh.html',//window.location.origin +'/silent-refresh.html',
  
    // The SPA's id. The SPA is registerd with this id at the auth-server
    //clientId: 'demo-resource-owner',
    clientId: 'admin-xibero',
  
    dummyClientSecret: 'secret',

    responseType: 'code',
  
    // set the scope for the permissions the client should request
    // The first three are defined by OIDC. The 4th is a usecase-specific one
    scope: 'openid profile email xibero ',   // profile email
  
    showDebugInformation: true,
  
    oidc: false,

  };

  // Use HashLocationStrategy for routing?
export const useHash = false;

// Set this to true, to use silent refresh; otherwise the example
// uses the refresh_token via an AJAX coll to get new tokens.
export const useSilentRefreshForCodeFlow = false;
  const authCustosCodeFlowConfig: AuthConfig = {
    issuer: 'https://devauth.custosmobile.com',//https://auth.server:9000
  
    // URL of the SPA to redirect the user to after login
    
   redirectUri:
      window.location.origin +
      (sessionStorage.getItem('useHashLocationStrategy') === 'false'
        ? '/#/dashboard'//'/#/index.html'
        : '/dashboard'),///index.html
    
    //redirectUri: 'https://oauth.pstmn.io/v1/callback',
   /*
    redirectUri: "https://oauth.pstmn.io/v1/callback",
    */
    // The SPA's id. The SPA is registerd with this id at the auth-server
    // clientId: 'server.code',
    clientId: 'spa-app',
  
    // Just needed if your auth server demands a secret. In general, this
    // is a sign that the auth server is not configured with SPAs in mind
    // and it might not enforce further best practices vital for security
    // such applications.
    // dummyClientSecret: 'secret',
  
    responseType: 'code',
  
    // set the scope for the permissions the client should request
    // The first four are defined by OIDC.
    // Important: Request offline_access to get a refresh token
    // The api scope is a usecase specific one
    scope: useSilentRefreshForCodeFlow
      ? 'openid profile email authserver merchantsApi'
      : 'openid profile email offline_access authserver merchantsApi',
  
    // ^^ Please note that offline_access is not needed for silent refresh
    // At least when using idsvr, this even prevents silent refresh
    // as idsvr ALWAYS prompts the user for consent when this scope is
    // requested
  
    // This is needed for silent refresh (refreshing tokens w/o a refresh_token)
    // **AND** for logging in with a popup
    silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,
  
    useSilentRefresh: useSilentRefreshForCodeFlow,
  
    showDebugInformation: true,
  
    sessionChecksEnabled: false,
  
    timeoutFactor: 0.01,
    // disablePKCI: true,

    clearHashAfterLogin: true,
  };
  
  
//export const authSpaConfig: AuthConfig = authPKCEConfig;


//OJOOO DEPENDIENDO SI SE COMPILA PARA XIIBERO O GETNET HAY QUE DESCOMENTAR UNA LINEA Y OTRA
export const authSpaConfig: AuthConfig = (VIWER_ENTORNO === ENTORNO.XIIBERO) ?  authXiiberoPKCEConfig  :  authPKCEConfig;
//export const authSpaConfig: AuthConfig = (VIWER_ENTORNO === ENTORNO.GETNET) ?  authPKCEConfig :  authXiiberoPKCEConfig;

