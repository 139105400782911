import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { EmailProperties, Properties } from 'src/app/models/emailProperties.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { OrganizationsService } from '../views/organizations/organizations.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-org-setting',
  templateUrl: './org-setting.component.html',
  styleUrls: ['./org-setting.component.css']
})
export class OrgSettingComponent implements OnInit {

  registerForm!: FormGroup;
  private oidcUserClaims: object;
  public translation: any;


  constructor(private authService: AuthService,
    private formBuilder: FormBuilder,
    private orgService: OrganizationsService,
    private translate: TranslateService,
  ) {

    this.oidcUserClaims = this.authService.getUserClaims;
  }




  async ngOnInit(): Promise<void> {

    this.registerForm = this.formBuilder.group({
      host: ["", Validators.compose([Validators.required, 
        Validators.maxLength(255),
        Validators.pattern(new RegExp("^[A-ZÀ-ÿa-z\\s\\-.]*$"))
      ])]      ,
      from: ["", Validators.compose([Validators.required, 
        Validators.maxLength(255),
        Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")
      ])]      ,
      userName: ["", Validators.compose([Validators.required, 
        Validators.email, 
        Validators.maxLength(255),
        Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")
      ])]      ,
      password: ["", Validators.compose([Validators.required,
        Validators.minLength(6),
        Validators.maxLength(255),
        Validators.pattern("^(?=[a-zA-Z0-9._-]{6,255}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")
      ])]      ,
     
      transportProtocol:[null, Validators.compose([Validators.required])],
      port:[null, Validators.compose([Validators.required])],
      auth:[false],
      starttlsEnable:[false],
      starttlsRequired:[false],
    });
    this.translate.get("CONFIGURATION").subscribe((translation: any) => {
      this.translation = translation;
    });

   await this.cargar();
  }



  
  mapQuery = new Map();
  rf: RequestFilter= new RequestFilter(null,0,50,"domainRoot");

  async cargar(){
    //recogemos la organizacion del usuario
    let cod = this.userProfileClaim('domain')

    //traemos toda la informacion de la organizacion
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domainRoot", cod);    
    let value = await this.orgService.find(this.rf,true);

    //sacamos las propiedades del email y las cargamos en el formulario
    /*
    this.registerForm = this.formBuilder.group({
      host: ["", Validators.compose([Validators.required, 
        Validators.maxLength(255),
        Validators.pattern(new RegExp("^[A-ZÀ-ÿa-z\\s\\-.]*$"))
      ])]      ,
      from: ["", Validators.compose([Validators.required, 
        Validators.maxLength(255),
        Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")
      ])]      ,
      userName: ["", Validators.compose([Validators.required, 
        Validators.email, 
        Validators.maxLength(255),
        Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")
      ])]      ,
      password: ["", Validators.compose([Validators.required,
        Validators.minLength(6),
        Validators.maxLength(255),
        Validators.pattern("^(?=[a-zA-Z0-9._-]{6,255}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")
      ])]      ,
     
      transportProtocol:[null, Validators.compose([Validators.required])],
      port:[null, Validators.compose([Validators.required])],
      auth:[false],
      starttlsEnable:[false],
      starttlsRequired:[false],
    });*/
  }

  public userProfileClaim(key:string) {
    return this.oidcUserClaims[key as keyof typeof this.oidcUserClaims] !== undefined ? this.oidcUserClaims[key as keyof typeof this.oidcUserClaims]:'';
  }


  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }


  onFormSubmit(): void {
    
    if (this.registerForm.invalid) {
      return;
    }    

    let properties: Properties = new Properties();
    let emailProperties : EmailProperties = new EmailProperties();

    const data = this.applyFormats(this.registerForm);

    emailProperties.host=data['host']
    emailProperties.userName=data['userName']
    emailProperties.password=data['password']
    emailProperties.from=data['from']
    emailProperties.transportProtocol=data['transportProtocol']
    emailProperties.port=data['port']
    emailProperties.auth=data['auth']
    emailProperties.starttlsEnable=data['starttlsEnable']
    emailProperties.starttlsRequired=data['starttlsRequired']


    properties.id= "";// id de la Organizacion
    properties.emailProperties= emailProperties
    

    this.orgService.setProperties(properties)

  }

  public onDelete() {
    this.registerForm.reset();
    Object.keys(this.registerForm).forEach((control: any) => {
      control.markAsPristine();
      control.markAsUntouched();
    });
  }

}
