import { Component ,ElementRef, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Chart, registerables } from 'chart.js';
import { AuthService } from './auth/auth.service';
import { Observable } from 'rxjs';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogActions, ConfirmDialogData } from './components/confirm-dialog/confirm-dialog.model';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { environment } from 'src/environments/environment';
import { AuthRestUrl } from './util/constants';
import {TranslateService} from "@ngx-translate/core";
import { ModalManagerService } from './services/modalManager.service';
import { AceptarDialogComponent } from './components/aceptar-dialog/aceptar-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  typeSelected: string;
  textShown: string;

  isAuthenticated$: Observable<boolean>;
  isDoneLoading$: Observable<boolean>;
  canActivateProtectedRoutes$: Observable<boolean>;
  private oidcUserClaims: any;

  //declare function closeModal(): any;
  
  title = 'merchantsportal';//'admindashboard';
  
  constructor(private elementRef: ElementRef,  
    public  _router: Router,
    public dialog: MatDialog,
    private spinnerService: NgxSpinnerService,
    private idle: Idle, 
    private keepalive: Keepalive,
    private authService: AuthService,
    private translate: TranslateService,
    private modalManager: ModalManagerService) {

    this.typeSelected = 'ball-spin';  
    this.textShown = this.translate.instant("loading");

    this.isAuthenticated$ = this.authService.isAuthenticated$;
    this.isDoneLoading$ = this.authService.isDoneLoading$;
    this.canActivateProtectedRoutes$ = this.authService.canActivateProtectedRoutes$;
    this.oidcUserClaims = this.authService?.getUserClaims;
    
    Chart.register(...registerables); 

    this.loadCSS(window.location.hostname);
    //idle.stop();
    
    this.configureIdleSessionTimeouts(idle, keepalive);
    
    let url = document.location.href;

    this.isAuthenticated$.subscribe((v)=>{
      // si el usuario está autenticado redirigimos al dashboard.

      //getUserAccessToken
      if(v) {
        // configuramos el idle del portal
        //this.configureIdleSessionTimeouts(idle, keepalive);
        console.debug("redirect to dashboard, is authenticated");
        this.modalManager.closeAllModals();
        this._router.navigateByUrl("/dashboard");
        // this.loadCSS(this.authService.domain);

      } else if(!url.includes('signup') && 
                !url.includes('trxreferenced') &&
              !url.includes('/welcome')) {
        console.debug("redirect to welcome, not authenticated anymore");
        idle.stop();
        this.fullLogout();
      }
    });

    translate.setDefaultLang("en");
    let locale = navigator.language.substring(0,2);    
    
    if (this.oidcUserClaims && this.oidcUserClaims['locale']) {
        locale = this.oidcUserClaims['locale'];
    } else if (localStorage.getItem('language')) {
        locale = localStorage.getItem('language') || locale;
    }
    translate.use ( locale);


    if (this.oidcUserClaims && this.oidcUserClaims['zoneinfo']) {
         localStorage.setItem("timezone", this.oidcUserClaims['zoneinfo']);
    }

    console.debug('constructor end');
 }

 public get showDebugInfo(): boolean {
	return environment.debugInfo; 
 }


 loadCSS(i:string) {
  let fileRef;
  fileRef = document.createElement('link');
  fileRef.setAttribute('rel', 'stylesheet');
  fileRef.setAttribute('type', 'text/css');

  if(i.toLowerCase().startsWith('xiibero')){
    fileRef.setAttribute('href', '../../assets/themes/xiibero/styles.css');
  }else if(i.toLowerCase().startsWith('getnet')){
    fileRef.setAttribute('href', '../../assets/themes/getnet/styles.css');
  }else if(i.toLowerCase().startsWith('unicaja')){
    fileRef.setAttribute('href', '../../assets/themes/unicaja/styles.css');
  }else if(i.toLowerCase().startsWith('ibercaja')){
    fileRef.setAttribute('href', '../../assets/themes/ibercaja/styles.css');
  }else if(i.toLowerCase().startsWith('ingenico')){
    fileRef.setAttribute('href', '../../assets/themes/ingenico/styles.css');
  }else if(i.toLowerCase().startsWith('euronet')){
    fileRef.setAttribute('href', '../../assets/themes/euronet/styles.css');
  }else {
    fileRef.setAttribute('href', '../../assets/css/styles.css');
  }


  if (typeof fileRef !== 'undefined') {
      document.getElementsByTagName('head')[0].appendChild(fileRef);
  }


//FAKE
//fileRef.setAttribute('href', '../../assets/themes/euronet/styles.css');


}








 idleState = 'Not started.';
 timedOut = false;
 lastPing ?: Date = undefined;

 private configureIdleSessionTimeouts(idle: Idle, keepalive: Keepalive) {
      // sets an idle timeout of 5 seconds, for testing purposes.
      idle.setIdle(60);//60
      // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
      //idle.setTimeout(15*60); // 15*60 - 15 minutos
      idle.setTimeout(15*60); // 15*60 - 15 minutos
      // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  
      idle.onIdleEnd.subscribe(() => { 
        this.idleState = 'No longer idle.'
        console.debug(this.idleState);
        this.reset();
      });
      
      idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';
        this.timedOut = true;

        
        let  emergente = document.getElementsByClassName('custom-modalbox') as HTMLCollectionOf<HTMLElement>;
        let  sombra = document.getElementsByClassName('cdk-overlay-backdrop') as HTMLCollectionOf<HTMLElement>;
           
        for (let i = 0; i < sombra.length; i ++) {
          sombra[i].style.display = 'none';
        }
        for (let i = 0; i < emergente.length; i ++) {
          emergente[i].style.display = 'none';
        }
        
        console.debug(this.idleState);
        //this.authService.logoutAndClear();
        this.fullLogout();
        
        this._router.navigate(['/welcome']);
        
        this.openSessionIdleDialog();
      });
      
      idle.onIdleStart.subscribe(() => {
          this.idleState = 'You\'ve gone idle!'
          console.debug(this.idleState);
      });
      
      idle.onTimeoutWarning.subscribe((countdown) => {
        this.idleState = 'You will time out in ' + countdown + ' seconds!'
        console.debug(this.idleState);
      });
      
      // sets the ping interval to 15 seconds
      keepalive.interval(15);
  
      keepalive.onPing.subscribe(() => this.lastPing = new Date());
  
      this.reset();
 }

 @ViewChild('logoutForm', { read: ElementRef })
 logoutForm!: ElementRef; 

 public fullLogout() { 
  console.debug("logout() method");
  this.authService.logoutAndClear();
  setTimeout(() => this.logoutForm.nativeElement.submit(), 10000);
 }
 
 private confirmDialogRef!: MatDialogRef<AceptarDialogComponent>;
 
 openSessionIdleDialog(): void {
  
  const dialogData = new ConfirmDialogData();
  dialogData.enableCancel = false;
  dialogData.titleI18n = this.translate.instant("Session.create_title");
  dialogData.textI18n = this.translate.instant("Session.create_desc");

  this.confirmDialogRef = this.dialog.open(AceptarDialogComponent, {
    width: '35%', panelClass: 'custom-modalbox',
    data: dialogData
  });
  this.modalManager.closeAllModals();

  this.confirmDialogRef.afterClosed().subscribe((result: ConfirmDialogActions) => {
    if (result === ConfirmDialogActions.CONFIRM) {
      console.debug("confirmed!");
      this.logoutForm.nativeElement.submit();
     // this.authService.logout(); 
      //this._router.navigate(["/welcome"]);
    }
  });
}

 private reset() {
  this.idle.watch();
  this.idleState = 'Started.';
  this.timedOut = false;
}
/*
  private isTokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return expiry * 1000 > Date.now();
  }
*/

  ngOnInit() {
    
    let s = document.createElement("script");
    //s.nonce = "a3a522aceed42a145ba15c1fe536a6519c8b0161eec810bb3a64aa996388e273";
    s.type = "text/javascript";
    s.src = "../assets/js/main.js";

    this.elementRef.nativeElement.appendChild(s);
    //closeModal();
  }

  
  public showSpinner(text: string, timed: number): void {
    this.textShown = text;
    this.spinnerService.show();

    if (!isNaN(timed))
    {
      setTimeout(() => {
        this.spinnerService.hide();
      }, timed); // 5 seconds
    }
  }

  public hideSpinner(): void {
    this.spinnerService.hide();
    this.textShown = '';
  }


  procesaPropagar(mensaje:string) {
    this.authService.jerarquiaSearchClicked.next(mensaje);
  }

  
  login() { 
    this.authService.login();
  } // https://auth.server:9000
  
  logout() { 
    console.log('Aquí está el error')
    this.authService.logout(); 
    this._router.navigate(["/welcome"]);
  }
  refresh() { this.authService.refresh(); }
  reload() { window.location.reload(); }
  clearStorage() { this.authService.clearStorage(); }

  logoutExternally() {
    console.log('Aquí está el error (logoutExternally)')
    window.open(this.authService.logoutUrl);
  }

  get hasValidToken() { return this.authService.hasValidToken(); }
  get accessToken() { return this.authService.accessToken; }
  get refreshToken() { return this.authService.refreshToken; }
  get identityClaims() { return this.authService.identityClaims; }
  get idToken() { return this.authService.idToken; }

  get logoutBaseUrl(){
    return AuthRestUrl + 'redlogout';
  }
  get redirect(){
    return window.location.origin + '/welcome';
  }
  

}
