<main id="main" class="main">



   <div class="pagetitle">
     <h1  i18n>{{"Merchants" | translate }}</h1>   
     <div>
      <button  *ngIf="commonService.checkItemRights('merchant','W','ALL')" mat-mini-fab (click)="onNew()">
       <i class="bi bi-plus-lg"></i>
      </button>

      <button  *ngIf="!commonService.checkItemRights('merchant','W','ALL')" mat-mini-fab (click)="refrescar()">
        <i class="bi bi-arrow-clockwise"></i>
       </button>    

    </div>
  </div>
 
 
   
   <div class="mat-elevation-z8 tablaPersonal">
    <table mat-table [dataSource]="dataSource"  matSort (matSortChange)="announceSortChange($event)"
    matTableExporter  #exporter="matTableExporter">
 
    
       

      <!-- merchantId Column -->
      <ng-container matColumnDef="merchantId">
        <th mat-header-cell *matHeaderCellDef class="tamano_columna_merchantId"><div mat-sort-header  i18n>{{"Merchant Id" | translate }}</div>  
          <div>
            <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                    <input matInput class="form-field" [formControl]="merchantIdFilter" placeholder="">
            </mat-form-field>
          </div>
        </th>       
        <td mat-cell *matCellDef="let element"> {{element.merchantId}} </td>
      </ng-container>

      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef><div mat-sort-header  i18n>{{"Name" | translate }}</div>  
          <div>
            <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                    <input matInput class="form-field" [formControl]="nameFilter" placeholder="">
            </mat-form-field>
          </div>
        </th>       
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

   <!-- organization Column -->
   <ng-container matColumnDef="organization_name">
    <th mat-header-cell *matHeaderCellDef class="tamano_columna_max"><div mat-sort-header  i18n>{{"Organization" | translate }}</div>  
      <div>
        <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <input matInput class="form-field" [formControl]="organizationFilter" placeholder="">
        </mat-form-field>
      </div>
    </th>       
    <td mat-cell *matCellDef="let element"  class="tamano_columna_max"> {{element.organization?.name}} </td>
  </ng-container>

<!-- organization xiibero Column -->
  <ng-container matColumnDef="organization_xiibero">
    <th mat-header-cell *matHeaderCellDef class="tamano_columna_max"><div mat-sort-header  i18n>{{"Organization" | translate }} </div>  
      <div>
        <mat-form-field>
          <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>         
          <mat-select  [(ngModel)]="organizationXiiberoSelect" (selectionChange)="xiiberoFilter($event,'Org')">            
             
            <mat-form-field class="filterSelect">
              <mat-label> </mat-label>
              <input matInput placeholder="filter..." #filterplc>
            </mat-form-field>

            <mat-option [value]=""> </mat-option>
            <div *ngFor="let op of organizationXiiberoList">
              <mat-option  *ngIf="op.name.includes(filterplc.value)" [value]="op" >
                {{op.name}}
              </mat-option>
            </div>         
          </mat-select>
        </mat-form-field>
      </div>
    </th>       
    <td mat-cell *matCellDef="let element"  class="tamano_columna_max"> {{element.hierarchy?.ORGANIZATION?.name}} </td>
  </ng-container>


  <!-- Region xiibero Column -->
  <ng-container matColumnDef="region_xiibero">
    <th mat-header-cell *matHeaderCellDef class="tamano_columna_max"><div mat-sort-header  i18n>{{"Region" | translate }} </div>  
      <div>
        <mat-form-field>
          <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>         
          <mat-select [(ngModel)]="regionXiiberoSelect"  (selectionChange)="xiiberoFilter($event,'Region')">            
             
            <mat-form-field class="filterSelect">
              <mat-label> </mat-label>
              <input matInput placeholder="filter..." #filterRegion>
            </mat-form-field>

            <mat-option [value]=""> </mat-option>
            <div *ngFor="let op of regionXiiberoList">
              <mat-option  *ngIf="op.name.toLowerCase().includes(filterRegion.value.toLowerCase())" [value]="op" >
                {{op.name}}
              </mat-option>
            </div>         
          </mat-select>
        </mat-form-field>
      </div>
    </th>       
    <td mat-cell *matCellDef="let element"  class="tamano_columna_max"> {{element.hierarchy?.REGION?.name}} </td>
  </ng-container>

  <!-- Division xiibero Column -->
  <ng-container matColumnDef="division_xiibero">
    <th mat-header-cell *matHeaderCellDef class="tamano_columna_max"><div mat-sort-header  i18n>{{"Division" | translate }} </div>  
      <div>
        <mat-form-field>
          <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>         
          <mat-select [(ngModel)]="divisionXiiberoSelect"  (selectionChange)="xiiberoFilter($event,'Division')">            
             
            <mat-form-field class="filterSelect">
              <mat-label> </mat-label>
              <input matInput placeholder="filter..." #filterDivision>
            </mat-form-field>

            <mat-option [value]=""> </mat-option>
            <div *ngFor="let op of divisionXiiberoList">
              <mat-option  *ngIf="op.name.toLowerCase().includes(filterDivision.value.toLowerCase())" [value]="op" >
                {{op.name}}
              </mat-option>
            </div>         
          </mat-select>
        </mat-form-field>
      </div>
    </th>       
    <td mat-cell *matCellDef="let element"  class="tamano_columna_max"> {{element.hierarchy?.DIVISION?.name}} </td>
  </ng-container>


 
       <!-- merchantEmail Column -->
       <ng-container matColumnDef="info_email">
         <th mat-header-cell *matHeaderCellDef><div mat-sort-header  i18n>{{"Email" | translate }}</div>  
           <div>
             <mat-form-field>
               <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                     <input matInput class="form-field" [formControl]="merchantEmailFilter" placeholder="">
             </mat-form-field>
           </div>
         </th>       
         <td mat-cell *matCellDef="let element"> {{element.info?.email}} </td>
       </ng-container>


  <!-- country Column -->
  <ng-container matColumnDef="info_address_country">
    <th mat-header-cell *matHeaderCellDef><div mat-sort-header  i18n>{{"Country" | translate }}</div>  
      <div>

        <div>
          <mat-form-field>
            <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>         
            <mat-select [(ngModel)]="countrySelect"  (selectionChange)="countryFilter($event)">            
               
              <mat-form-field class="filterSelect">
                <mat-label> </mat-label>
                <input matInput placeholder="filter..." #filterCountry>
              </mat-form-field>
  
              <mat-option [value]=""> </mat-option>
              <div *ngFor="let op of listaPaises">
                <mat-option  *ngIf="op.name.toLowerCase().includes(filterCountry.value.toLowerCase())" [value]="op.code" >
                  {{op.name}}
                </mat-option>
              </div>         
            </mat-select>
          </mat-form-field>
        </div>

        <!--<mat-form-field>
          <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <input matInput class="form-field" [formControl]="countryFilter" placeholder="">
        </mat-form-field>-->
      </div>
    </th>       
    <td mat-cell *matCellDef="let element"> {{element.info?.address?.country | countryPipe}}  </td>
  </ng-container>

    <!-- State Column -->
    <ng-container matColumnDef="info_address_state">
      <th mat-header-cell *matHeaderCellDef><div mat-sort-header  i18n>{{"State" | translate }}</div>  
        <div>
          <mat-form-field>
            <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                  <input matInput class="form-field" [formControl]="stateFilter" placeholder="">
          </mat-form-field>
        </div>
      </th>       
      <td mat-cell *matCellDef="let element"> {{element.info?.address?.state}} </td>
    </ng-container>
   
    <!-- city Column -->
    <ng-container matColumnDef="info_address_city">
      <th mat-header-cell *matHeaderCellDef><div mat-sort-header  i18n>{{"City" | translate }}</div>  
        <div>
          <mat-form-field>
            <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                  <input matInput class="form-field" [formControl]="cityFilter" placeholder="">
          </mat-form-field>
        </div>
      </th>       
      <td mat-cell *matCellDef="let element"> {{element.info?.address?.city}} </td>
    </ng-container>


         <!-- locationName Column -->
         <ng-container matColumnDef="locationName">
           <th mat-header-cell *matHeaderCellDef  class="tamano_columna_max"><div mat-sort-header  i18n>{{"Location" | translate }}</div>  
            <div>
               <mat-form-field>
                   <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                       <input matInput class="form-field" [formControl]="locationNameFilter" placeholder="">
               </mat-form-field>
             </div>
           </th>       
           <td mat-cell *matCellDef="let element"  class="tamano_columna_max"> {{element.locationName}} </td>
         </ng-container>
 
 
         <!-- categoryCode Column -->
         <ng-container matColumnDef="properties_categoryCode">
         <th mat-header-cell *matHeaderCellDef><div mat-sort-header  i18n>{{"MCC" | translate }}</div>  
            <div>
               <mat-form-field>
                  <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                     <input matInput class="form-field" [formControl]="categoryCodeFilter" placeholder="" >
               </mat-form-field>
            </div>
         </th>       
         <td mat-cell *matCellDef="let element" ><div  [matTooltip]="categoryCodeDesc(element.properties?.categoryCode)"> {{element.properties?.categoryCode}} </div></td>
         </ng-container>
        
 

         <ng-container matColumnDef="view">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="button">   
            <div>
              <button *ngIf="commonService.checkItemRights('merchant','W',element.domain)" matTooltip="{{'edit' | translate }}"  (click)="onEdit(element)" mat-icon-button color="primary" >
                <i class="bi bi-pencil"></i>
              </button>
      
              <button *ngIf="commonService.checkItemRights('merchant','D',element.domain)" matTooltip="{{'delete' | translate }}" (click)="onDelete(element)" mat-icon-button color="primary">
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </td>
        </ng-container>
       
 
 
       <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
       <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
     </table>

     <button id="export" class="icoDownload" mat-icon-button (click)="exportIt() "><i class="bi bi-file-earmark-arrow-down"></i> </button>
       

     <mat-paginator [length]="pageLength"
              [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions"
              (page)="pageEvent($event)"
              showFirstLastButtons 
              aria-label="Select page of periodic elements">
</mat-paginator>


   </div>
   </main>
