import { AfterViewInit, Component, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { DualListComponent } from 'src/app/components/dual-listbox/dual-list.component';
import { FormGroupsComponent } from '../form-groups/form-groups.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { TranslateService } from '@ngx-translate/core';
import { Users } from 'src/app/models/users.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { UsersService } from '../../users/users.service';
import { FormCreateActions } from 'src/app/util/constants';
import { UtilService } from 'src/app/services/util.service';
import { Groups } from 'src/app/models/groups.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { GroupsService } from '../groups.service';
import { ModalManagerService } from 'src/app/services/modalManager.service';
import { AuthService } from 'src/app/auth/auth.service';


@Component({
  selector: 'app-members-groups',
  templateUrl: './members-groups.component.html',
  styleUrls: ['./members-groups.component.css']
})
export class MembersGroupsComponent implements OnInit{
  
  formartDualList = { add: this.translate.instant("dualList.add"), remove: this.translate.instant("dualList.remove"), all: this.translate.instant("dualList.all"), none: this.translate.instant("dualList.none"),
            direction: DualListComponent.LTR, draggable: true, locale: undefined };
 

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

          
  mapQuery = new Map();
  rf: RequestFilter= new RequestFilter(null,0,1000,"username");

  groupsBBDD : Groups = new Groups();
  listaTotalUser:Users[] = [];
  listaResultSelect:Users[] = [];  
  keyDualList: any = 'username'; //atributo que devuelve
  displayDualList: any = ['username','email']; //atributo a mostrar en el listado
  
  isAdmin:boolean = false; 

  ngOnInit(): void {
    
    this.cargar();
    this.formartDualList = {
      add: this.translate.instant("dualList.add"),
      remove: this.translate.instant("dualList.remove"),
      all: this.translate.instant("dualList.all"),
      none: this.translate.instant("dualList.none"),
      direction: DualListComponent.LTR,
      draggable: true,
      locale: undefined
    };
    
  }



  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private spinnerService: NgxSpinnerService,
    private usersService: UsersService,
    private services: GroupsService,
    private authService: AuthService,
    private translate: TranslateService,
    private modalManager: ModalManagerService,
    public utilServer: UtilService,
    public dialogRef: MatDialogRef<FormGroupsComponent>,
    public dialog: MatDialog) { 
     
  }


  async cargar(){
    
    this.spinnerService.show();

    let perfil = this.authService.userAccessLevel()

    if(perfil === "ADMIN"){
        this.isAdmin=true     
        this.displayDualList = ['username','email','domain'];
    }

    if(this.data?.obj){
          let valor = await this.services.getById(this.data?.obj.id,false);     

          let domainGroup =""  
          if(valor){
              Object.assign(this.groupsBBDD,valor);
              console.log(this.groupsBBDD)
                this.listaResultSelect = this.groupsBBDD.members ? this.groupsBBDD.members : [];
                domainGroup=this.groupsBBDD.domain
          }
          if(perfil === "ADMIN"){// si es admin trae todos los usuarios
            this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", undefined);            
          }else{
            this.rf.filter = QueryFilterList.construirFilterDomainCompleto(this.mapQuery, "domain", domainGroup); 
          }

    }else{
            let cacheDomain = this.utilServer.cargarCacheDomain();
            if(cacheDomain && cacheDomain!="undefined"){
                this.rf.filter = QueryFilterList.construirFilterDomain(this.mapQuery, "domain", cacheDomain);        
            }else{
                this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", undefined);            
            } 
    }


      let value = await this.usersService.findService(this.rf);
      if(value){
        this.listaTotalUser= value.content;
      }
      
      this.spinnerService.hide();

    }


    async checkValue(event: any){

      if(event.currentTarget.checked){
       this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", undefined);            
      }else{
       this.rf.filter = QueryFilterList.construirFilterDomainCompleto(this.mapQuery, "domain", this.groupsBBDD.domain); 
      }

      let value = await this.usersService.findService(this.rf);
      if(value){
        this.listaTotalUser= value.content;
      }


    }


    onFormSubmit(): void {

      this.groupsBBDD.members=this.listaResultSelect
      
  
      const dialogData = new ConfirmDialogData(); 
      dialogData.titleI18n = this.translate.instant("GROUP.update_members_title");
      dialogData.textI18n = this.translate.instant("GROUP.update_members_desc");
  
  
      this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '25%', panelClass: 'custom-modalbox',
        data: dialogData
      });
      this.modalManager.registerModal(this.dialogRef);
  
      this.confirmDialogRef.afterClosed().subscribe((result: ConfirmDialogActions) => {
        if (result === ConfirmDialogActions.CONFIRM) {
          
            console.log('Form SAVE action');
            this.services.saveMembers(this.groupsBBDD)
            this.dialogRef.close(FormCreateActions.SAVED);
  
        } else if (result === ConfirmDialogActions.CANCEL) {
          //////////
        }
      });
  
    }
  
  
    formExit(): void {
      console.log('Form exit action');
      this.dialogRef.close(FormCreateActions.EXIT);
    }

}

