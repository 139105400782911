import { Component, OnInit, ElementRef, Input, ViewChild, AfterViewInit, OnChanges } from '@angular/core';


import {
  ApexNonAxisChartSeries,
  ChartComponent
} from "ng-apexcharts";
import { ChartOptions, ChartPayload } from 'src/app/shared/interfaces/chart.model';
import { CHART_COLORS } from 'src/app/util/chartsutils';
import { DEFAULT_LOCALE, LOCALES } from 'src/app/util/chartsLocales';

@Component({
  selector: 'app-chart-model',
  templateUrl: './chart-model.component.html',
  styleUrls: ['./chart-model.component.css']
})
export class ChartModelComponent implements OnInit, OnChanges {
  @Input() chartTitle !: string;
  @Input() chartDataset!: ChartPayload;

  public chartOptions!: Partial<ChartOptions>;
  private originalSeries: ApexNonAxisChartSeries = [];
  private excludedSeries: string[] = [];  


  ngAfterViewInit(): void {
  }

  @ViewChild('chartMDL', { static: true }) chart!: ChartComponent;

  ngOnInit(): void {
    this.createChart();
  }

  createChart(){
    this.chartOptions = {
      series: [],
      chart: {
        width: "100%",
        height: "auto",
        type: "pie",
        locales: LOCALES,
        defaultLocale : DEFAULT_LOCALE,
        events: {
          legendClick: (_, seriesIndex) => {
                this.toggleSeriesVisibility(seriesIndex!);
          }
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '55%'
          }
        }
      },
      colors: Object.values(CHART_COLORS),
      labels: [],
      fill: {
        type: 'gradient',
      },      
      legend: {
        position: 'bottom',
        fontSize: 'small',
        formatter: (seriesName) => {
          return this.excludedSeries.includes(seriesName) ?
            `<span style="opacity: 0.45">${seriesName}</span>` : seriesName;
        }
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
              locales: LOCALES,
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

  ngOnChanges() {
    if (!(this.chart == undefined) && !(this.chartOptions == undefined) && !(this.chartDataset == undefined)) {
        this.chartOptions.series = this.chartDataset.values;
        this.chartOptions.labels = this.chartDataset.labels;
        this.chart?.updateOptions(this.chartOptions);
        this.originalSeries = [...this.chartDataset.values];

    }
  }

  toggleSeriesVisibility(index: number): void {
    const isCurrentlyVisible = this.chartOptions.series![index] !== 0;
    this.chartOptions.series![index] = isCurrentlyVisible ? 0 : this.originalSeries[index];
      const seriesName = this.chartOptions.labels![index];
    if (isCurrentlyVisible) {
      if (!this.excludedSeries.includes(seriesName)) {
        this.excludedSeries.push(seriesName);
      }
    } else {
      this.excludedSeries = this.excludedSeries.filter(name => name !== seriesName);
    }
    this.chart?.updateOptions({
      series: this.chartOptions.series
    }, false, true);

}

}
