
import { Order } from 'src/app/models/order.model';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { Organization } from 'src/app/models/organization.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { CommonService } from 'src/app/services/common.service';
import { VIWER_ENTORNO, environment } from 'src/environments/environment';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Subscription,debounceTime, distinctUntilChanged } from 'rxjs';
import { FormNewOrdersComponent } from './form-new-orders/form-new-orders.component';
import { OrderService } from './order.service';
import { Merchants } from 'src/app/models/merchants.model';
import { OrganizationsService } from '../organizations/organizations.service';
import { MerchantService } from '../merchants/merchant.service';
import { UtilService } from 'src/app/services/util.service';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { AuthService } from 'src/app/auth/auth.service';

import * as XLSX from "xlsx";
import { ENTORNO } from 'src/app/util/constants';
import { DataExport_Order_Xiibero } from 'src/app/models/dataExport/dataExport_order_Xiibero.model';
import { ModalManagerService } from 'src/app/services/modalManager.service';
import { TranslateService } from '@ngx-translate/core';

enum Columns {
  organization_xiibero = 'hierarchy.ORGANIZATION.name',
  region_xiibero = 'hierarchy.REGION.name',
  division_xiibero = 'hierarchy.DIVISION.name',
  merchant_xiibero = 'hierarchy.MERCHANT.name',
  orderId = 'orderId', 
  createdAt = 'createdAt',
  status = 'status',
  terminals_manufacturer = 'terminals.manufacturer',
  terminals_model = 'terminals.model',
  terminals_numberOfTerminals = 'terminals.numberOfTerminals',
  //terminals_pendings = 'terminals.pendings',
}


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit, AfterViewInit {

  displayedColumns =  Object.keys(Columns);

  entorno = VIWER_ENTORNO
  dataSource = new MatTableDataSource<Order>();

  pageLength=0;
  pageIndex=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId=Columns.createdAt+",desc";//idColumna + "," + direccion


  
  organizationXiiberoSelect!: Organization;
  merchantXiiberoSelect!: Merchants;
  regionXiiberoSelect!: Merchants;
  divisionXiiberoSelect!: Merchants;
  organizationXiiberoList:Organization[] = [];
  merchantXiiberoList:Merchants[] = [];
  regionXiiberoList:Merchants[] = [];
  divisionXiiberoList:Merchants[] = [];


  orderIdFilter = new FormControl();
  statusFilter = new FormControl();
  manufacturerFilter = new FormControl();
  modelFilter = new FormControl();

  createdAtFilter = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private dialogRef!: MatDialogRef<FormNewOrdersComponent>;

  mapQuery = new Map();
  rf: RequestFilter= new RequestFilter(null,0,this.pageSize,this.sortId);


  jerarquiaSearchSbscription!: Subscription;

  constructor(private servicios : OrderService,
    public dialog: MatDialog,
    private orgService: OrganizationsService, 
    public authServer: AuthService,
    private utilService: UtilService,
    private translate : TranslateService,
    private modalManager: ModalManagerService,
    public commonService : CommonService) {    
  }


  async lanzarLlamada(){
    let value = await this.servicios.find(this.rf);
    console.log(JSON.stringify(value))
    if(value){
      console.log(JSON.stringify(value.content))
      this.dataSource = new MatTableDataSource<Order>(value.content);
      this.pageLength=value.totalElements;
    }
}



async cargar(){   
  //organizaciones
    let rfOrg: RequestFilter= new RequestFilter(null,0, 100,"name,desc");       
    let srOrg = await this.orgService.find(rfOrg,true);     
    if(srOrg){
      this.organizationXiiberoList= srOrg.content;       
    }

     //traemos las REGIONES
     let sr = await this.utilService.pedirRegiones(undefined,"R");     
     if(sr){
       this.regionXiiberoList= sr.content;
     }    

      //traemos las DIVISION
      sr = await this.utilService.pedirDivision(undefined,"R");   
       if(sr){
         this.divisionXiiberoList= sr.content;
       }

        //traemos las DIVISION
      sr = await this.utilService.pedirMerchant(undefined,"R");   
      if(sr){
        this.merchantXiiberoList= sr.content;
      }

  }



  
  cargarCache() {
  
    let value = this.utilService.cargarCacheDomain();
 
    if(value && value!="undefined"){
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", value);        
    }else{
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", undefined);            
    }
  
  
    this.lanzarLlamada();  
  }
  
  

  async ngOnInit(){

    
  this.jerarquiaSearchSbscription = this.authServer.getJerarquiaSearchClicked().subscribe(() => this.cargarCache());



    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    await this.cargar();

    
    this.orderIdFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns.orderId;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();       
    }); 
    this.statusFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns.status;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();       
    });  
    this.manufacturerFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns.terminals_manufacturer;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();       
    });  
    this.modelFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns.terminals_model;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();       
    });
    this.createdAtFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      const idColumn = Columns.createdAt;    
      console.log(idColumn+"->start="+value.start+"   end="+value.end);        
      this.rf.filter = QueryFilterList.construirRangoFechaFilter(this.mapQuery, idColumn, value.start,value.end);        
      this.lanzarLlamada(); 
    });


    this.cargarCache()

  }


  
  xiiberoFilter(ob:MatSelectChange, tipo:string) {
    
    const idColumn = "domain";
    let value:string | undefined

    this.organizationXiiberoSelect= new Organization;
    this.merchantXiiberoSelect= new Merchants;
    this.regionXiiberoSelect= new Merchants;
    this.divisionXiiberoSelect= new  Merchants;


    if(tipo=="Org"){
      let org:Organization
      org=ob.value;       
      this.organizationXiiberoSelect=org;
      if(org){
        value=org.domainRoot+"."
      }
    }else  if(tipo=="Region"){
      let org:Merchants
      org=ob.value;
      this.regionXiiberoSelect=org;
      if(org){
        value=org.domain+"."
      }
    }else  if(tipo=="Division"){
      let org:Merchants
      org=ob.value;
      this.divisionXiiberoSelect=org;
      if(org){
        value=org.domain+"."
      }
     }else  if(tipo=="Merch"){
      let org:Merchants
      org=ob.value;
      this.merchantXiiberoSelect=org;
      if(org){
        value=org.domain
      }
    }


    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.lanzarLlamada();  
  }

  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent(pag:PageEvent){
    console.log("pageEvent="+JSON.stringify(pag)) 
    this.pageSize = pag.pageSize;
    this.pageIndex = pag.pageIndex;
    this.rf = RequestFilter.construirPage(this.rf.filter , pag.pageIndex,pag.pageSize,this.sortId);
    this.lanzarLlamada();
  }

  announceSortChange(sortState: Sort) {
    console.log("ordenar="+JSON.stringify(sortState)) 
    let active = sortState.active.replace("_",".")
    let direccion="";
    if(sortState.direction){
      direccion=","+sortState.direction
    }
    this.sortId=active+direccion;
    this.rf = RequestFilter.construirPage(this.rf.filter , this.pageIndex,this.pageSize,this.sortId);
    this.lanzarLlamada();
  }

  refrescar() {
    this.lanzarLlamada();
  }


  onNew(){
    this.dialogRef = this.dialog.open(FormNewOrdersComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {//vacio al ser nuevo
      }
    });
    this.modalManager.registerModal(this.dialogRef);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');       
        this.lanzarLlamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
   }





   exportIt() {
      let newDate = new Date();
      let nombre = "orders-"+newDate.toISOString().slice(0, 10)+"-"+newDate.getTime();
      const fileName = nombre+".xlsx";

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.procesarDataExport(this.dataSource.data));
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      let Heading
      if(this.entorno==ENTORNO.XIIBERO){
        Heading = [[
          this.translate.instant("Organization"), 
          this.translate.instant("Region"), 
          this.translate.instant("Division"), 
          this.translate.instant("Merchant"), 
          this.translate.instant("OrderId"), 
          this.translate.instant("Created"), 
          this.translate.instant("Status"), 
          this.translate.instant("Manufacturer"), 
          this.translate.instant("Model"), 
          this.translate.instant("Num. Terminals/Pendings")
      ]];      }else{
        //GETNET
        Heading=[['']]
      }
      XLSX.utils.sheet_add_aoa(ws, Heading);
      XLSX.utils.book_append_sheet(wb, ws, "orders");

      XLSX.writeFile(wb, fileName);
  }


private procesarDataExport(data:Order[]): any[]  {
  
  if(this.entorno==ENTORNO.XIIBERO){
    
    let result : DataExport_Order_Xiibero[] = [] ;
    data.forEach(row => {
      let tempRow:DataExport_Order_Xiibero = new DataExport_Order_Xiibero(row);
      result.push(tempRow);
    });
    return result;
  }else{   
    //GETNET 
    return [];
  }
}






}
