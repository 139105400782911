import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, catchError, from, of, shareReplay } from 'rxjs';
import {  Properties } from 'src/app/models/emailProperties.model';
import { Organization } from 'src/app/models/organization.model';
import {  RequestFilter } from 'src/app/models/requestFilter.model';
import { RestService } from 'src/app/services/merchant/Rest.service';
import { ENTORNO } from 'src/app/util/constants';
import { VIWER_ENTORNO } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {

  private organizations$!: Observable<any>;

  entorno = VIWER_ENTORNO;

  constructor(private merchantRest : RestService) {
    this.initializeOrganizations();

  }
  
  async lookup(_body: RequestFilter, showLoading: boolean){
    let value = null;
    if(this.entorno==ENTORNO.XIIBERO){
      // Ignoramos el objeto de entrada
      
      value = await this.merchantRest.commonRestCallXiibero({},'organization/lookup',showLoading,true)   
      .catch(err => {
        return null
      })
    } else {
      // MERCHANT_PORTAL | GETNET
      value = await this.find(_body,showLoading);
    }
    return value;   
  }

  async find(_body: RequestFilter, showLoading: boolean){
    let value = null;
    if(this.entorno==ENTORNO.XIIBERO){
      value = await this.merchantRest.commonRestCallXiibero(_body,'organization/',showLoading,true)   
      .catch(err => {
        return null
      })
    }else{       
        value = await this.merchantRest.commonRestCallMerchant(_body,'organization/query',showLoading,true)   
        .catch(err => {
          return null
        })
      }
    return value;   
  }

  async findToWrite(_body: RequestFilter, showLoading: boolean){
    let value = null;
    if(this.entorno==ENTORNO.XIIBERO){
      value = await this.merchantRest.commonRestCallXiibero(_body,'organization/',showLoading,true)   
      .catch(err => {
        return null
      })
    }else{       
        value = await this.merchantRest.commonRestCallMerchant(_body,'organization/findToWrite',showLoading,true)   
        .catch(err => {
          return null
        })
      }
    return value;   
  }

  async findCreate(_body: RequestFilter, showLoading: boolean){
    let value = null;
    if(this.entorno==ENTORNO.XIIBERO){
      value = await this.merchantRest.commonRestCallXiibero(_body,'organization/',showLoading,true)   // ajustar para xiberro
      .catch(err => {
        return null
      })
      }else{       
        value = await this.merchantRest.commonRestCallMerchant(_body,'organization/create',showLoading,true)   
        .catch(err => {
          return null
        })
      }
    return value;   
  }

  private initializeOrganizations(): void {
    let rfOrg: RequestFilter= new RequestFilter(null,0, 200,"name,desc");   
      if (this.entorno === ENTORNO.XIIBERO) {
        /*this.organizations$ = from(
          this.merchantRest.commonRestCallXiibero(rfOrg, 'organization/', false, true))
          .pipe(
            catchError(err => {
              console.error('Error fetching organizations', err);
              return of([]);
            }),
            shareReplay(1)
          );*/

          this.organizations$ = from(
            this.lookup(rfOrg,true))
          .pipe(
            catchError(err => {
              console.error('Error fetching organizations', err);
              return of([]);
            }),
            shareReplay(1)
          );

      } else {
        this.organizations$ = from(
          this.merchantRest.commonRestCallMerchant(rfOrg, 'organization/query', false, true))
          .pipe(
            catchError(err => {
              console.error('Error fetching organizations', err);
              return of([]);
            }),
            shareReplay(1)
          );
      }
    
  }


  getFindResult() {
    console.log('se realizo operacion de organizations');
    return this.organizations$; 
  }  

  async getProperties(_body: RequestFilter){
    let value = await this.merchantRest.commonRestCallMerchant(_body,'organization/query',true,true)   
    .catch(err => {
      return null
    })
    return value?.content;   
  }
  
  async setProperties(properties: Properties) {
    let value = await this.merchantRest.commonRestCallMerchant(properties,'organization/properties/emailServer',true,true)   
    .catch(err => {
      return null
    })
    return value;  
  }







  async saveEditService(_body: Organization){
    let value = await this.merchantRest.commonRestCallXiibero(_body, 'organization/save',true,true)   
    .catch(err => {
      return null
    })

    return value;   
  }

  async updateEditService(_body: Organization) {
    let value = await this.merchantRest.commonRestCallXiibero(_body, 'organization/update',true,true)   
    .catch(err => {
      return null
    })

    return value;   
  }
  
  async deleteService(_id: string){
    let _body={"id":_id} 
    let value = await this.merchantRest.commonRestCallXiibero(_body, 'organization/delete',true,true)   
    .catch(err => {
      return null
    })
    return value;   
  }

  async lanzarLlamada( requestFilter: RequestFilter){
    let dataSource: any;
    let pageLength: number = 0;
    let value = await this.find(requestFilter, true);
    console.log(JSON.stringify(value));
    
    if(value){
      console.log(JSON.stringify(value.content))
      dataSource = new MatTableDataSource<Organization>(value.content);
      pageLength = value.totalElements;
    }
    return {
      dataSource: dataSource,
      pageLength: pageLength
    }
  }

}
