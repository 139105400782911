<main id="main" class="main">

  <div class="pagetitle">
    <h1 i18n>{{"Terminals" | translate }}</h1>

    <div class="botonesCabecera">
      <div class="selectButtom_navigation">
        <div class="selectButtom">
          <button id="icoVerTabla_terminal" class="botonIcoArriba botonMarcado" (click)="verTabla()">
            <i class="bi bi-list-ul"></i>
            <span i18n>{{"List" | translate }}</span>
          </button>
          <button id="icoVerGrafica_terminal" class="botonIcoArriba" (click)="verGrafica()">
            <i class="bi bi-bar-chart-line"></i>
            <span i18n>{{"Graph" | translate }}</span>
          </button>
        </div>
      </div>

      <button *ngIf="!isEntornoGetnet && commonService.checkItemRights('terminal','W','ALL')" class="btn btn-aceptar"
        (click)="onNewOrder()">
        <i class="bi bi-basket2">{{"Order" | translate }}</i>
      </button>

      <button *ngIf="!commonService.checkItemRights('terminal','W','ALL')" mat-mini-fab (click)="refrescar()">
        <i class="bi bi-arrow-clockwise"></i>
      </button>
    </div>
  </div>




  <div *ngIf="graficaTransaction">
    <div class="row mt-2">
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{"Device models" | translate }}</h5>
            <app-chart-model chartTitle="" [chartDataset]="graph1Data"></app-chart-model>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{"Terminals by currency" | translate }}</h5>
            <app-chart-tx-donut chartTitle="" [chartDataset]="graph2Data"></app-chart-tx-donut>
          </div>
        </div>
      </div>
    </div>

  </div>
  &nbsp;

  <div *ngIf="tablaTransaction" style="overflow: auto; margin-top: 15px;">


    <div class="mat-elevation-z8 tablaPersonal">
      <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows
        (matSortChange)="announceSortChange($event)" matTableExporter #exporter="matTableExporter">

        <!-- terminalId Column -->
        <ng-container matColumnDef="terminalId">
          <th mat-header-cell *matHeaderCellDef>
            <div mat-sort-header i18n>{{"Terminal Id" | translate }}</div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <input matInput class="form-field" [formControl]="terminalIdFilter" placeholder="" maxlength="40">
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.terminalId}} </td>
        </ng-container>

        <!-- serialnumber Column -->
        <ng-container matColumnDef="serialnumber">
          <th mat-header-cell *matHeaderCellDef>
            <div mat-sort-header i18n>{{"S/N" | translate }}</div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <input matInput class="form-field" [formControl]="serialnumberFilter" placeholder="" maxlength="40">
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.serialnumber}} </td>
        </ng-container>


        <!-- merchant_name Column -->
        <ng-container matColumnDef="merchant_name">
          <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
            <div mat-sort-header i18n>{{"Merchant" | translate }}</div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <input matInput class="form-field" [formControl]="merchantFilter" placeholder="">
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="tamano_columna_max"> {{element.merchant?.name}} </td>
        </ng-container>

        <!-- merchant_merchantId Column -->
        <ng-container matColumnDef="merchant_merchantId">
          <th mat-header-cell *matHeaderCellDef>
            <div mat-sort-header i18n>{{"Merchant Id" | translate }}</div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <input matInput class="form-field" [formControl]="merchantIdFilter" placeholder="">
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.merchant?.merchantId}} </td>
        </ng-container>



        <!-- serialnumber Column -->
        <ng-container matColumnDef="organization_name">
          <th mat-header-cell *matHeaderCellDef>
            <div mat-sort-header i18n>{{"Organization" | translate }}</div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <input matInput class="form-field" [formControl]="organizationFilter" placeholder="">
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.organization?.name}} </td>
        </ng-container>




        <!-- organization xiibero Column -->
        <ng-container matColumnDef="organization_xiibero">
          <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
            <div mat-sort-header i18n>{{"Organization" | translate }} </div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <mat-select (selectionChange)="xiiberoFilter($event,'Org')">

                  <mat-form-field class="filterSelect">
                    <mat-label> </mat-label>
                    <input matInput placeholder="filter..." #filterplc>
                  </mat-form-field>

                  <mat-option [value]=""> </mat-option>
                  <div *ngFor="let op of organizationXiiberoList">
                    <mat-option *ngIf="op.name.includes(filterplc.value)" [value]="op">
                      {{op.name}}
                    </mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="tamano_columna_max"> {{element.hierarchy?.ORGANIZATION?.name}}
          </td>
        </ng-container>


        <!-- Region xiibero Column -->
        <ng-container matColumnDef="region_xiibero">
          <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
            <div mat-sort-header i18n>{{"Region" | translate }} </div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <mat-select [(ngModel)]="regionXiiberoSelect" (selectionChange)="xiiberoFilter($event,'Region')">

                  <mat-form-field class="filterSelect">
                    <mat-label> </mat-label>
                    <input matInput placeholder="filter..." #filterRegion>
                  </mat-form-field>

                  <mat-option [value]=""> </mat-option>
                  <div *ngFor="let op of regionXiiberoList">
                    <mat-option *ngIf="op.name.toLowerCase().includes(filterRegion.value.toLowerCase())" [value]="op">
                      {{op.name}}
                    </mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="tamano_columna_max"> {{element.hierarchy?.REGION?.name}} </td>
        </ng-container>

        <!-- Division xiibero Column -->
        <ng-container matColumnDef="division_xiibero">
          <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
            <div mat-sort-header i18n>{{"Division" | translate }} </div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <mat-select [(ngModel)]="divisionXiiberoSelect" (selectionChange)="xiiberoFilter($event,'Division')">

                  <mat-form-field class="filterSelect">
                    <mat-label> </mat-label>
                    <input matInput placeholder="filter..." #filterDivision>
                  </mat-form-field>

                  <mat-option [value]=""> </mat-option>
                  <div *ngFor="let op of divisionXiiberoList">
                    <mat-option *ngIf="op.name.toLowerCase().includes(filterDivision.value.toLowerCase())" [value]="op">
                      {{op.name}}
                    </mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="tamano_columna_max"> {{element.hierarchy?.DIVISION?.name}} </td>
        </ng-container>


        <!-- merchant xiibero Column -->
        <ng-container matColumnDef="merchant_xiibero">
          <th mat-header-cell *matHeaderCellDef class="tamano_columna_max">
            <div mat-sort-header i18n>{{"Merchant" | translate }} </div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <mat-select (selectionChange)="merchantXiiberoFilter($event)">

                  <mat-form-field class="filterSelect">
                    <mat-label> </mat-label>
                    <input matInput placeholder="filter..." #filterplc>
                  </mat-form-field>

                  <mat-option [value]=""> </mat-option>
                  <div *ngFor="let op of merchantXiiberoList">
                    <mat-option *ngIf="op.name.includes(filterplc.value)" [value]="op">
                      {{op.name}}
                    </mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="tamano_columna_max"> {{element.hierarchy?.MERCHANT?.name}} </td>
        </ng-container>











        <!-- model Column -->
        <ng-container matColumnDef="model">
          <th mat-header-cell *matHeaderCellDef>
            <div mat-sort-header i18n>{{"Model" | translate }}</div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <input matInput class="form-field" [formControl]="modelFilter" placeholder="">
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.model}} </td>
        </ng-container>


        <!-- manufacturer Column -->
        <ng-container matColumnDef="manufacturer">
          <th mat-header-cell *matHeaderCellDef>
            <div mat-sort-header i18n>{{"Manufacturer" | translate }}</div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <input matInput class="form-field" [formControl]="manufacturerFilter" placeholder="">
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.manufacturer}} </td>
        </ng-container>


        <!-- status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            <div mat-sort-header i18n>{{"Status" | translate }}</div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <!--
                  <input matInput class="form-field" [formControl]="statusFilter" placeholder="">
                  -->
                <mat-select (selectionChange)="terminalStatusFilter($event)">
                  <mat-option *ngFor="let op of terminalStatusSelect" [value]="op">
                    {{utilService.translate(op)}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="DEFAULT {{element.status}}">
            <div> {{ element.status ? utilService.translate(element.status) : ('Undefined' | translate) }}
            </div>
          </td>
        </ng-container>

        <!-- terminalCurrencyCode Column -->
        <ng-container matColumnDef="properties_currency">
          <th mat-header-cell *matHeaderCellDef>
            <div mat-sort-header i18n>{{"Currency" | translate }}</div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <mat-label></mat-label>
                <mat-select (selectionChange)="terminalCurrencyCodeSelectFilter($event)">
                  <mat-option *ngFor="let op of terminalCurrencyCodeSelect" [value]="op">
                    {{op}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="centrado"> {{element.properties.currency}} </td>
        </ng-container>


        <!-- terminalCountryCode Column -->
        <ng-container matColumnDef="properties_country">
          <th mat-header-cell *matHeaderCellDef>
            <div mat-sort-header i18n>{{"Country" | translate }}</div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <mat-label></mat-label>
                <mat-select (selectionChange)="terminalCountryCodeFilter($event)">
                  <mat-option *ngFor="let op of terminalCountryCodeSelect" [value]="op">
                    {{op}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" class="centrado">
            <img class="card-icon" *ngIf="element.properties && element.properties.country"
              [src]="getPaisLogo(element.properties?.country)" [alt]=""
              title="{{getPaisLogName(element.properties?.country)}}">
          </td>
        </ng-container>

        <ng-container matColumnDef="creationDate">
          <th mat-header-cell *matHeaderCellDef>
            <div mat-sort-header i18n>{{"Created Date" | translate }}</div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <input matInput class="form-field" [formControl]="creationFilter" placeholder="">
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.createdAt | date:formatDatebyLocale()}} </td>
        </ng-container>

        <ng-container matColumnDef="updatedDate">
          <th mat-header-cell *matHeaderCellDef>
            <div mat-sort-header i18n>{{"Updated Date" | translate }}</div>
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <input matInput class="form-field" [formControl]="updatedFilter" placeholder="">
              </mat-form-field>
            </div>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.updatedAt | date:formatDatebyLocale()}} </td>
        </ng-container>

        <ng-container matColumnDef="button">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="button">
            <div>
              <button matTooltip="{{'Log files' | translate}}" (click)="downloadFiles(element)" *ngIf="element.hasFiles"
                mat-icon-button color="primary">
                <i class="bi bi-journal-arrow-down"></i>
              </button>

              <button matTooltip="{{'Transactions' | translate}}" (click)="viewTransaction(element)" mat-icon-button
                color="primary">
                <i class="bi bi-credit-card"></i>
              </button>

            </div>
          </td>
        </ng-container>





        <!--  Column Expand -->
        <ng-container matColumnDef="expand" style="padding: 0 !important;">
          <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
          <td mat-cell *matCellDef="let element" style="padding: 0 !important;">
            <button mat-icon-button matTooltip="{{'View Funtionalities' | translate}}"
              (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
              <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
            </button>
          </td>
        </ng-container>
        <!--Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="example-element-detail"
              [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
              <div class="element-header">
                <span i18n></span>
              </div>
              <div class="element-body row">
                <div class="col-sm-12">
                  <div class="cardTransaction">
                    <div><span i18n>{{"Functionalities" | translate }}</span></div>

                    <mat-checkbox *ngIf="isEntornoGetnet" class="example-margin" disabled
                      [checked]="element.functionalities.preauthorization" i18n>{{"preauthorization" | translate
                      }}</mat-checkbox>
                    <mat-checkbox *ngIf="isEntornoGetnet" class="example-margin" disabled
                      [checked]="element.functionalities.dcc" i18n>{{"dcc" | translate }}</mat-checkbox>
                    <mat-checkbox *ngIf="isEntornoGetnet" class="example-margin" disabled
                      [checked]="element.functionalities.keyentry" i18n>{{"keyentry" | translate }}</mat-checkbox>
                    <mat-checkbox *ngIf="isEntornoGetnet" class="example-margin" disabled
                      [checked]="element.functionalities.digitalSignature" i18n>{{"digitalSignature" | translate
                      }}</mat-checkbox>
                    <mat-checkbox *ngIf="isEntornoGetnet" class="example-margin" disabled
                      [checked]="element.functionalities.tip" i18n>{{"tip" | translate }}</mat-checkbox>
                    <mat-checkbox *ngIf="isEntornoGetnet" class="example-margin" disabled
                      [checked]="element.functionalities.splitPayment" i18n>{{"splitPayment" | translate
                      }}</mat-checkbox>
                    <mat-checkbox *ngIf="isEntornoGetnet" class="example-margin" disabled
                      [checked]="element.functionalities.copyReceipt" i18n>{{"copyReceipt" | translate }}</mat-checkbox>
                    <mat-checkbox *ngIf="isEntornoGetnet" class="example-margin" disabled
                      [checked]="element.functionalities.pinEntryPropietary" i18n>{{"pinEntryPropietary" | translate
                      }}</mat-checkbox>
                    <mat-checkbox *ngIf="isEntornoGetnet" class="example-margin" disabled
                      [checked]="element.functionalities.offlineDetail" i18n>{{"offlineDetail" | translate
                      }}</mat-checkbox>
                    <mat-checkbox *ngIf="isEntornoGetnet" class="example-margin" disabled
                      [checked]="element.functionalities.manualVoid" i18n>{{"manualVoid" | translate }}</mat-checkbox>
                    <mat-checkbox *ngIf="isEntornoGetnet" class="example-margin" disabled
                      [checked]="element.functionalities.automaticInit" i18n>{{"automaticInit" | translate
                      }}</mat-checkbox>
                    <mat-checkbox *ngIf="isEntornoGetnet" class="example-margin" disabled
                      [checked]="element.functionalities.digitalReceipt" i18n>{{"digitalReceipt" | translate
                      }}</mat-checkbox>
                    <mat-form-field *ngIf="isEntornoGetnet && element.functionalities.digitalReceipt">
                      <input matInput class="form-field" disabled
                        value="{{element.functionalities.digitalReceiptSendingMode}}"
                        placeholder="digitalReceiptSendingMode">
                    </mat-form-field>
                    <mat-form-field *ngIf="isEntornoGetnet"><input matInput class="form-field" disabled
                        value="{{element.functionalities.clessCVMLimit}}"
                        placeholder="{{'clessCVMLimit' | translate }}"></mat-form-field>

                  </div>
                </div>

              </div>
            </div>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>


      </table>

      <button id="export" class="icoDownload" mat-icon-button (click)="exportIt() "><i
          class="bi bi-file-earmark-arrow-down"></i> </button>

      <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent($event)" showFirstLastButtons aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>

  </div>

</main>