<header id="header" class="header fixed-top d-flex align-items-center align-content-start row col-12">

         <div class="d-flex align-items-center justify-content-between col-2"> 
            <i (click)="sidebarToggle()" class="bi bi-list toggle-sidebar-btn"></i>
            <a routerLink="/dashboard" class="logo d-flex align-items-center"> 
               <span class="d-none d-lg-block">{{titulo}}<b>Portal</b>     
                  <p class="app-version"><small>v{{version}}</small></p>
               </span>
               <img class="img" src="assets/img/Simbolo_Azul_Claro_RGB.svg" alt="">
            </a>
         </div>    
         <div class="header-hiearchy col-8">
               <app-bar-jerarquia  *ngIf="(isAuthenticated$ | async)"  (propagar)="procesaPropagar($event)"></app-bar-jerarquia>
         </div>
         <div class="header-nav col-2">
            <nav class="header-nav ms-auto">
               <ul class="d-flex align-items-center justify-content-center">
                     <li></li>
                     <li class="nav-item dropdown pe-3">
                     <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown"> 
                                 <span class="profilelogo "> </span><!--<img src="assets/img/profile-img.jpg" alt="Profile" class="rounded-circle"> -->
                                 <span class="d-none d-md-block dropdown-toggle ps-2">{{username}}</span> </a>
                     <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                        <li class="dropdown-header">
                           <h6>{{fullName}}</h6>
                           <span></span>
                           <!-- <span>Web Designer</span> -->
                        </li>
                        <li>
                           <hr class="dropdown-divider">
                        </li>
                        <li> <a class="dropdown-item d-flex align-items-center" routerLink="/user-profile"> <i class="bi bi-person"></i> <span i18n>{{"My Profile" | translate}}</span> </a></li>
                        <li>
                           <hr class="dropdown-divider">
                        </li>
                        <!-- <li> <a class="dropdown-item d-flex align-items-center" routerLink="/pages-faq"> <i class="bi bi-question-circle"></i> <span i18n>Need Help?</span> </a></li>-->
                        <li>
                           <hr class="dropdown-divider">
                        </li>
                        <li>
                           <form action="{{logoutBaseUrl}}" method="post" name="headLogoutForm" #headLogoutForm>
                              <input type="hidden" id="head_redirect" name="redirect" value="{{redirect}}"/>
   
                              <a class="dropdown-item d-flex align-items-center" role="button" routerLink="/" (click)="logout()"> <i class="bi bi-box-arrow-right"></i> <span i18n>{{"Sign Out" | translate}}</span> </a>
   
                              <!--<button type="submit"  class="btn btn-primary btn-sm active" (click)="logout()"><i class="bi bi-box-arrow-right"></i> <span i18n>Sign Out b</span></button>-->
                           </form>
                        </li>
                     </ul>
                  </li>
               </ul>
            </nav>
         </div>
   
 </header>



