import { Component, Inject, OnInit } from '@angular/core';
import { UsersService } from '../users.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { Groups } from 'src/app/models/groups.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import {  UserPermisos, Users } from 'src/app/models/users.model';
import { ENTORNO, FormCreateActions, TypeJerarquia } from 'src/app/util/constants';
import { RolsService } from '../../rol/rol.service';
import { MerchantService } from 'src/app/pages/views/merchants/merchant.service';
import { OrganizationsService } from 'src/app/pages/views/organizations/organizations.service';
import { Organization } from 'src/app/models/organization.model';
import { Merchants } from 'src/app/models/merchants.model';
import { GroupsService } from '../../groups/groups.service';
import { UtilService } from 'src/app/services/util.service';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/auth/auth.service';
import { VIWER_ENTORNO } from 'src/environments/environment';
import { ModalManagerService } from 'src/app/services/modalManager.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-users',
  templateUrl: './form-users.component.html',
  styleUrls: ['./form-users.component.css']
})
export class FormUsersComponent implements OnInit {

  registerForm!: FormGroup;
  desactivado:boolean = false;
  verRegion:boolean = false;  
  verDivision:boolean = false;  
  isAdmin:boolean = false; 
  verAdmin:boolean = true;

  listaTypeProfile:string[] = ["USERS","ADMIN"];
  listaOrg:Organization[] = [];
  listaRegion:Merchants[] = [];
  listaDivision:Merchants[] = [];
  listaMerchant:Merchants[] = [];
  listaGrupos:Groups[] = [];
  public groupErrorLiteral: string = ""  

  entorno = VIWER_ENTORNO;

  selectedOrg: Organization = new Organization();
  selectedRegion: Merchants = new Merchants();
  selectedDivision: Merchants = new Merchants();
  selectedGroup: Groups = new Groups();

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private  errorsLiterals: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private services: UsersService,
    private rolsService: RolsService,
    private spinnerService: NgxSpinnerService,
    private orgService: OrganizationsService,
    private commonService: UtilService,
    private authService: AuthService,
    private merchService: MerchantService,
    private groupsService: GroupsService,
    private modalManager: ModalManagerService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<FormUsersComponent>,
    public dialog: MatDialog) { 
      this.translateService.get(['REQUIRED_FIELD', 'MERCHANT_DOMAIN_NO_GROUP_FOUND']).subscribe((translation: any) => {
        this.errorsLiterals = translation;
        this.groupErrorLiteral = this.errorsLiterals.REQUIRED_FIELD;
      });
  }


  async cargar(){

      this.spinnerService.show();

     //organizaciones
      let rfOrg: RequestFilter= new RequestFilter(null,0, 100,"name,desc");       
      //let srOrg = await this.orgService.findCreate(rfOrg,true);
      let srOrg = await this.orgService.find(rfOrg,true);
      
      if(srOrg){
        this.listaOrg= srOrg.content;
      }

      
      let perfil = this.authService.userAccessLevel();
      if(perfil === "ADMIN"){
        this.isAdmin=true
      }
      
      this.spinnerService.hide();

    }


  async ngOnInit(): Promise<void> {
    
    this.registerForm = this.formBuilder.group({
      firstname: ["", Validators.compose([Validators.required, 
        Validators.maxLength(255),
        Validators.pattern(new RegExp("^[A-ZÀ-ÿa-z\\s\\-.]*$"))
      ])]      ,
      lastname: ["", Validators.compose([Validators.required, 
        Validators.maxLength(255),
        Validators.pattern(new RegExp("^[A-ZÀ-ÿa-z\\s\\-.]*$"))
      ])]      ,
      email: ["", Validators.compose([Validators.required, 
        Validators.email, 
        Validators.maxLength(255),
        Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")
      ])]      ,
      username: ["", Validators.compose([Validators.required,
        Validators.minLength(6),
        Validators.maxLength(255),
        Validators.pattern("^(?=[a-zA-Z0-9._-]{6,255}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")
      ])]      ,
     
      rolMarcado:[null],
      orgMarcado:[null],
      regionMarcado: [null],
      divisionMarcado: [null], 
      merchMarcado:[null],
      groupMarcado:[null],
    });

   await this.cargar();

  }




  onFormSubmit(): void {
    console.log(this.registerForm);
    
    if (this.registerForm.invalid) {
      return;
    }    
    const data = this.applyFormats(this.registerForm);

    let userNew : Users = new Users();
    let permisosNew : UserPermisos = new UserPermisos();

    userNew.firstName=data['firstname']  
    userNew.lastName=data['lastname']   
    userNew.email=data['email']   
    userNew.username=data['username']  
    let rol:string
    rol=data['rolMarcado']
    userNew.typeProfile=rol
    
    let domainRoot

if(rol==="ADMIN"){
    domainRoot= "ALL"
}else{
    let groupNew:Groups
    groupNew=data['groupMarcado']
    if(!groupNew){
      this.registerForm.controls['groupMarcado'].setErrors({ 'required': true }); 
      return ;
    }


    let orgNew: Organization
    orgNew = data['orgMarcado']
    if(!orgNew){
      this.registerForm.controls['orgMarcado'].setErrors({ 'required': true }); 
      return ;
    }
    domainRoot= orgNew.domainRoot
    
    let regionNew:Merchants
    regionNew=data['regionMarcado']
    if(regionNew){
      domainRoot=regionNew.domain
    }
    
    let divisionNew:Merchants
    divisionNew=data['divisionMarcado']
    if(divisionNew){
      domainRoot=divisionNew.domain
    }

    let merchantNew:Merchants
    merchantNew=data['merchMarcado']
    if(merchantNew){
      domainRoot=merchantNew.domain
    }


    let groupArray=[]
    groupArray[0]=groupNew?.id  
    permisosNew.groups=groupArray   
  }

    //domainArray[0]=domainRoot
    //permisosNew.domains=domainArray
    userNew.domain=domainRoot
    userNew.auth=permisosNew


    const dialogData = new ConfirmDialogData(); 
    
    dialogData.titleI18n = this.translateService.instant("USER.Invite_title");
    dialogData.textI18n = this.translateService.instant("USER.Invite_desc");


    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });
    this.modalManager.registerModal(this.dialogRef);

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        
          console.log('Form SAVE action');
          await this.services.invitationService(userNew)
          this.dialogRef.close(FormCreateActions.SAVED);

      //} else if (result === ConfirmDialogActions.CANCEL) {        
      }
    });

  }

  async rolFilter(ob:MatSelectChange) {
    let select:string
    select=ob.value; 
    
    if(select === "ADMIN"){
      this.verAdmin=false
      this.registerForm.controls['groupMarcado'].clearValidators();
      this.registerForm.controls['groupMarcado'].updateValueAndValidity();
      this.registerForm.controls['orgMarcado'].clearValidators();
      this.registerForm.controls['orgMarcado'].updateValueAndValidity();
    }else{      
      this.verAdmin=true
    }

  }


  async organizationFilter(ob:MatSelectChange) {
    this.groupErrorLiteral = this.errorsLiterals.REQUIRED_FIELD;
    this.registerForm.controls['groupMarcado'].markAsUntouched();
    let org:Organization
    org=ob.value; 
    this.selectedOrg = org;

    let mostrarRegion=false
    let mostrarDivision=false
    if(this.entorno==ENTORNO.XIIBERO){
    if(org?.template){

      let tieneRegion = this.commonService.checkJerarquia(org.template, TypeJerarquia.REGION)
      let tieneDivision = this.commonService.checkJerarquia(org.template, TypeJerarquia.DIVISION)
      

      if(tieneRegion){    mostrarRegion=true  }
      if(tieneDivision){  mostrarDivision=true   }


      if(tieneRegion){
        //traemos las REGIONES
              let sr = await this.commonService.pedirRegiones(org.domainRoot,"R");     
              if(sr){
                this.listaRegion= sr.content;
              }
      }else if(tieneDivision){
         //traemos las DIVISIONES
            let sr = await this.commonService.pedirDivision(org.domainRoot,"R");   
            if(sr){
              this.listaDivision= sr.content;
            }
      } else{
          //traemos las  MECHARNT
            let sr = await this.commonService.pedirMerchant(org.domainRoot,"R");   
            if(sr){
              this.listaMerchant= sr.content;
            }
      } 
    }// if(org.template){
  }else{
    let sr = await this.commonService.pedirMerchant(org.domainRoot,"R");   
    if(sr){
      this.listaMerchant= sr.content;
    }
  }



       //grupos
       let rfGru: RequestFilter= new RequestFilter(null,0, 100,"name,desc"); 
       let mapQuery = new Map();
       rfGru.filter = QueryFilterList.construirFilter(mapQuery, "status", "ACTIVE"); 
       rfGru.filter = QueryFilterList.construirFilter(mapQuery, "domain", this.selectedOrg.domainRoot);    

       let srGru = await this.groupsService.findService(rfGru,false);     
       if(srGru){
         this.listaGrupos= srGru.content;
       }




    this.verDivision=mostrarDivision
    this.verRegion=mostrarRegion

  }

 

  async regionFilter(ob:MatSelectChange) {
    let reg:Merchants
    reg=ob.value; 

    let mostrarDivision=false

    if(reg && this.selectedOrg.template){

      let tieneDivision = this.commonService.checkJerarquia(this.selectedOrg.template, TypeJerarquia.DIVISION)
      if(tieneDivision){  mostrarDivision=true   }


      if(tieneDivision){         
          //traemos las DIVISIONES
          let srDiv = await this.commonService.pedirDivision(reg.domain,"R");   
          if(srDiv){
            this.listaDivision= srDiv.content;
          }
      }else{
          //traemos las  MECHARNT
          let sr = await this.commonService.pedirMerchant(reg.domain,"R");   
          if(sr){
            this.listaMerchant= sr.content;
          }
      }
    }

    this.verDivision=mostrarDivision
  }


  async merchantFilter (ob:MatSelectChange) {
    let reg:Merchants
    reg=ob.value; 

    if(reg){
         //grupos
       let rfGru: RequestFilter= new RequestFilter(null,0, 100,"name,desc"); 
       let mapQuery = new Map();
       rfGru.filter = QueryFilterList.construirFilter(mapQuery, "status", "ACTIVE"); 
       rfGru.filter = QueryFilterList.construirFilter(mapQuery, "domain", reg.domain);    

       let srGru = await this.groupsService.findService(rfGru,false);     
       this.listaGrupos = srGru?.content;
       this.groupErrorLiteral = this.errorsLiterals.REQUIRED_FIELD;
       this.registerForm.controls['groupMarcado'].markAsUntouched();
       if (srGru && this.listaGrupos.length === 0) {
        this.groupErrorLiteral = this.errorsLiterals.MERCHANT_DOMAIN_NO_GROUP_FOUND;
        this.registerForm.controls['groupMarcado'].setErrors({ 'required': true });
        this.registerForm.controls['groupMarcado'].markAsTouched();
       }

    }

  }

  async divisionFilter(ob:MatSelectChange) {
    let reg:Merchants
    reg=ob.value; 

    if(reg){
         //traemos las  MECHARNT
         let sr = await this.commonService.pedirMerchant(reg.domain,"R");   
         if(sr){
           this.listaMerchant= sr.content;
         }      
    }

  }


  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }



  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

}




