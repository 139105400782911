import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { ConfirmDialogComponent } from "src/app/components/confirm-dialog/confirm-dialog.component";
import { ConfirmDialogActions, ConfirmDialogData } from "src/app/components/confirm-dialog/confirm-dialog.model";
import { Groups } from "src/app/models/groups.model";
import { QueryFilterList, RequestFilter } from "src/app/models/requestFilter.model";
import { CommonService } from "src/app/services/common.service";
import { environment } from "src/environments/environment";
import { FormGroupsComponent } from "./form-groups/form-groups.component";
import { GroupsService } from "./groups.service";
import { TranslateService } from '@ngx-translate/core';
import { Subscription, debounceTime, distinctUntilChanged } from "rxjs";
import { TabsGroupsComponent } from "./tabs-groups/tabs-groups.component";
import { AuthService } from "src/app/auth/auth.service";
import { OrganizationsService } from "../../views/organizations/organizations.service";
import { Organization } from "src/app/models/organization.model";
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { Merchants } from "src/app/models/merchants.model";
import { MerchantService } from "../../views/merchants/merchant.service";
import { UtilService } from "src/app/services/util.service";
import { ModalManagerService } from '../../../services/modalManager.service';
import { DateFormats } from "src/app/util/constants";
import { DashboardService } from "../../dashboard/dashboard.service";

enum Columns {
  name = 'name',
  organization = 'organization',
  merchant = 'merchant',
  description = 'description',
  roles='access.role',
  createdAt = 'createdAt',
  view = 'view'
}


@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit, AfterViewInit {

  displayedColumns =  Object.keys(Columns);
  
  dataSource = new MatTableDataSource<Groups>();
  isAdmin:boolean = false; 
  organizationList: Organization[] = [];
  merchantList:Merchants[] = [];
  organizationSelect!: Organization;
  merchantSelect!: Merchants;
  pageLength=0;
  pageIndex=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId=Columns.name+",desc";//idColumna + "," + direccion
  domainSelect: string = "";
  nameFilter = new FormControl();
  descFilter = new FormControl();
  rolFilter = new FormControl();
  dateCreateFilter = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });



  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private dialogRefOld!: MatDialogRef<FormGroupsComponent>;
  private dialogRef!: MatDialogRef<TabsGroupsComponent>;

  mapQuery = new Map();
  rf: RequestFilter= new RequestFilter(null,0,this.pageSize,this.sortId);



  constructor(public dialog: MatDialog, 
    public services:GroupsService,
    public commonService : CommonService,
    private translate: TranslateService,
    private authService: AuthService,
    private merchantService: MerchantService,
    private utilServer: UtilService,  
    private organizationService: OrganizationsService,
    private modalManager: ModalManagerService,
    private readonly dashboardService: DashboardService
  ) {     
  }


  jerarquiaSearchSbscription!: Subscription;
  lanzarLlamada(){

//mostramos solo las activas
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "status", "ACTIVE");   

    this.services.findServiceObservable(this.rf,true).subscribe((res) => {
      const value = res;
      console.log("result->"+JSON.stringify(value))
      if(value){
        console.log(JSON.stringify(value.content))
        this.dataSource = new MatTableDataSource<Groups>(value.content);
        this.pageLength=value.totalElements;
      }
    })
    
}



  ngOnInit(): void {

    this.jerarquiaSearchSbscription = this.authService.getJerarquiaSearchClicked().subscribe(() => this.cargarCache());


    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.organizationService.getFindResult().subscribe({
      next: (organizations) => {
        this.organizationList = organizations.content;
      },
      error: (err) => {
        console.error('Failed to load organizations in Pipe:', err);
      },
      complete: () => console.log('organizations loading completed.')
    });

    this.merchantService.getFindResult().subscribe({
      next: (merchants) => {
        this.merchantList = merchants.content;
      },
      error: (err) => {
        console.error('Failed to load merchants in Pipe:', err);
      },
      complete: () => console.log('merchants loading completed.')
    });

    let perfil = this.authService.userAccessLevel();
    if(perfil === "ADMIN"){
      this.isAdmin=true
    }

    this.nameFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns.name;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();    
    });

    this.descFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns.description;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();    
    });
    this.rolFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns.roles;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();    
    });
   
    this.dateCreateFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      const idColumn = Columns.createdAt;    
      console.log(idColumn+"->start="+value.start+"   end="+value.end);        
      this.rf.filter = QueryFilterList.construirRangoFechaFilter(this.mapQuery, idColumn, value.start,value.end);        
      this.lanzarLlamada(); 
    });
     this.cargarCache();
    }

  
    cargarCache() {
       
      let value = this.utilServer.cargarCacheDomain();
        
      if(value && value!="undefined"){
        this.domainSelect = value;
        this.rf.filter = QueryFilterList.construirFilterDomain(this.mapQuery, "domain", value);        
      }else{
        this.domainSelect = "ALL";
        this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", undefined);            
      }
        
      this.lanzarLlamada();  
    }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent(pag:PageEvent){
    console.log("pageEvent="+JSON.stringify(pag))
    this.pageSize = pag.pageSize;
    this.pageIndex = pag.pageIndex;
    this.rf = RequestFilter.construirPage(this.rf.filter , pag.pageIndex,pag.pageSize,this.sortId);
    this.lanzarLlamada();
  }
 
  announceSortChange(sortState: Sort) {
    console.log("ordenar="+JSON.stringify(sortState)) 
    let active = sortState.active.replace("_",".")
    let direccion="";
    if(sortState.direction){
      direccion=","+sortState.direction
    }
    this.sortId=active+direccion;
    this.rf = RequestFilter.construirPage(this.rf.filter , this.pageIndex,this.pageSize,this.sortId);
    this.lanzarLlamada();
  }


   onNew(){
    this.dialogRef = this.dialog.open(TabsGroupsComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {
        tab:0
        //obj:vacio al ser nuevo
      }
    });
    this.modalManager.registerModal(this.dialogRef);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.lanzarLlamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
   }

   onEdit(row: any, tabSelect:any) {

    this.dialogRef = this.dialog.open(TabsGroupsComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {
        tab:tabSelect,
        obj: row
      }
    });
    this.modalManager.registerModal(this.dialogRef);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.lanzarLlamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }



   onDelete(row: any) {

    console.log('Delete pressed: ', row);
 
      const dialogData = new ConfirmDialogData();
      this.translate
      .get([
        "Delete Group",        
        "Are you sure you want to delete it?"
      ])
      .subscribe(translation => {
          dialogData.titleI18n = translation["Delete Group"];
          dialogData.textI18n = translation["Are you sure you want to delete it?"];
          dialogData.textValue = row.name;
      
        this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '25%', panelClass: 'custom-modalbox',
          data: dialogData
        });
        this.modalManager.registerModal(this.dialogRef);
      });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        await this.services.deleteService(row.id);
        console.log('CONFIRM recived from dialog window');
        this.lanzarLlamada();
      } else if (result === ConfirmDialogActions.CANCEL) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }
  domainFilter(ob:MatSelectChange,tipo:string) {
    
    const idColumn = "domain";
    let value:string | undefined

    this.organizationSelect= new Organization;
    this.merchantSelect= new Merchants;


      let org:Organization
      org=ob.value;       
      this.organizationSelect = org;
      if(tipo=== "Org"){
        value=org?.domainRoot
      }else  if(tipo==="Merchant"){
        let org:Merchants
        org=ob.value;
        this.merchantSelect =org;
        if(org){
          value=org?.domain
        }
      }


    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.lanzarLlamada();  
  }

  public calculateTimeZone(date: any) {
    return this.dashboardService.calculateTimeZone(date);
  }

}




