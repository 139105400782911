import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { Organization } from 'src/app/models/organization.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { OrganizationsService } from 'src/app/pages/views/organizations/organizations.service';
import { FormCreateActions, TypeJerarquia } from 'src/app/util/constants';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { Merchants, MerchantsAddress, MerchantsInfo } from 'src/app/models/merchants.model';
import { UtilService } from 'src/app/services/util.service';
import { MerchantService } from '../../merchants/merchant.service';
import { ModalManagerService } from '../../../../services/modalManager.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-form-subsidiary',
  templateUrl: './form-subsidiary.component.html',
  styleUrls: ['./form-subsidiary.component.css']
})
export class FormSubsidiaryComponent implements OnInit {
  registerForm!: FormGroup;
  desactivado: boolean = false;
  verRegion:boolean = false;  
  verDivision:boolean = false;  

  listaOrg: Organization[] = [];
  listaRegion:Merchants[] = [];
  listaDivision:Merchants[] = [];
  listaMerchant:Merchants[] = [];

  selectedProc!: string
  selectedOrg: Organization = new Organization();
  selectedRegion: Merchants = new Merchants();
  selectedDivision: Merchants = new Merchants();
  selectedMerchant: Merchants = new Merchants();

  objBBDD: Merchants = new Merchants();


  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private services: MerchantService,
    private orgService: OrganizationsService,
    private commonService: UtilService,
    private translate: TranslateService,
    private modalManager: ModalManagerService,
    public dialogRef: MatDialogRef<FormSubsidiaryComponent>,
    public dialog: MatDialog) {

  }


  async cargar() {
    // Organizaciones que permiten la creación de SUBSIDIARY     
    let mapQuery = new Map();
    let filter = QueryFilterList.construirFilterComplet(mapQuery,"beq" ,"template.hasSubsidiary", "true");
    
    let rfOrg: RequestFilter = new RequestFilter(filter, 0, 100, "name,desc");
    let srOrg = await this.orgService.find(rfOrg, true);
    
    if (srOrg) {
      this.listaOrg = srOrg.content;  // Esto espera la respuesta antes de continuar
    }
    
    if (this.listaOrg.length == 1) {
      // Si solo hay 1 organización, seleccionarla por defecto
      this.selectedOrg = this.listaOrg[0];
    }
 }
 

  async ngOnInit(): Promise<void> {

    this.registerForm = this.formBuilder.group({
      name: ["", Validators.compose([Validators.required,
      Validators.minLength(4),
      Validators.maxLength(50),
      Validators.pattern("^(?=[a-zA-Z0-9\\s._-]{4,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")])],
      location: ["", Validators.compose([
        Validators.maxLength(255),
        Validators.pattern("^[0-9a-zA-ZÀ-ÿ\\s\\Q.@[]{}()+_-,=:!?^$|/\\E]*$")])],
      orgMarcado: [null, Validators.compose([Validators.required])],
      regionMarcado: [null],
      divisionMarcado: [null], 
      merchantMarcado: [null, Validators.compose([Validators.required])], 
      shortname: ["", Validators.compose([
        Validators.maxLength(5),
        Validators.pattern("^[a-zA-Z0-9]*$")
      ])], 
      country: [""],
      state: [""],
      city: [""],
      address: [""],
      postalCode: [""],
      phone: [""],
      email: ["", Validators.compose([
        Validators.email,
        Validators.maxLength(255),
        Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")
      ])]
    });


    await this.cargar();

    let nombre, email, phone, shortname;
    let country, state, city, address, postalCode;
    let orgBBDD = null; 
    let regionBBDD=null;
    let divisionBBDD=null;
    let merchantBBDD=null;
    if (this.data?.obj) {
      //si entra es EDICION
      this.desactivado = true;

      this.objBBDD = this.data.obj;


      if (this.objBBDD) {
        let hierarchy = this.objBBDD.hierarchy

        let dominioOrg = ""
        let dominioRegion=""
        let dominioDivision=""
        let dominioMerchant=""


        if(hierarchy){
          if(hierarchy.ORGANIZATION){
            dominioOrg = hierarchy.ORGANIZATION.domain
          }

          if(hierarchy.REGION){
            dominioRegion = hierarchy.REGION.domain

            let sr = await this.commonService.pedirRegiones(dominioOrg,"R");     
            if(sr){
              this.listaRegion= sr.content;
            }
          }

          if(hierarchy.DIVISION){
            dominioDivision = hierarchy.DIVISION.domain

            let dominioBuscar = dominioOrg;
            if(dominioRegion){dominioBuscar = dominioRegion}
            let sr = await this.commonService.pedirDivision(dominioBuscar,"R");   
            if(sr){
              this.listaDivision= sr.content;
            }
          }

          if(hierarchy.MERCHANT){
            dominioMerchant = hierarchy.MERCHANT.domain

            let dominioBuscar = dominioOrg;
            if(dominioRegion){dominioBuscar = dominioRegion}
            if(dominioDivision){dominioBuscar = dominioDivision}

             //traemos las  MECHARNT
             let sr = await this.commonService.pedirMerchant(dominioBuscar,"R");   
             if(sr){
               this.listaMerchant= sr.content;
             }
          }
        }
    
       
     

        this.listaOrg.forEach(element => {
          if (element.domainRoot == dominioOrg) {
            orgBBDD = element
          };
        });

        this.listaRegion.forEach(element => {
          if(element.domain==dominioRegion){
              regionBBDD=element  
              this.verRegion=true              
            };
        });

        this.listaDivision.forEach(element => {
          if(element.domain==dominioDivision){
              divisionBBDD=element   
              this.verDivision=true             
            };
        });

        this.listaMerchant.forEach(element => {
          if(element.domain==dominioMerchant){
              merchantBBDD=element                
            };
        });

      

        if (this.objBBDD.info) {
          email = this.objBBDD.info.email;
          phone = this.objBBDD.info.phone;

          if (this.objBBDD.info.address) {
            country = this.objBBDD.info.address.country
            state = this.objBBDD.info.address.state
            city = this.objBBDD.info.address.city
            address = this.objBBDD.info.address.addressLine1
            postalCode = this.objBBDD.info.address.postalOrZipCode
          }
        }
        nombre = this.objBBDD.name;
        shortname=this.objBBDD.merchantId;
      }

      this.registerForm = this.formBuilder.group({
        name: [nombre, Validators.compose([Validators.required])],
        country: [country],  
        shortname  : [{value: shortname, disabled: true}],
        state: [state],
        city: [city],
        address: [address],
        postalCode: [postalCode],
        email: [email],
        phone: [phone],
        regionMarcado: [regionBBDD],
        divisionMarcado: [divisionBBDD],
        merchantMarcado: [merchantBBDD, Validators.compose([Validators.required])], 
        orgMarcado: [orgBBDD, Validators.compose([Validators.required])],

      });

    }// if (this.objBBDD
  }//if (this.data && this.data.obj




  async organizationFilter(ob: MatSelectChange) {
    let org: Organization = ob.value; 
    this.selectedOrg = org;

    let mostrarRegion = false;
    let mostrarDivision = false;

    // Limpiar las selecciones de regiones, divisiones y comerciantes al cambiar de organización
    this.selectedRegion = new Merchants();
    this.selectedDivision = new Merchants();
    this.selectedMerchant = new Merchants();
    this.listaRegion = []; 
    this.listaDivision = []; 
    this.listaMerchant = []; 

    if (org?.template) {
        let tieneRegion = this.commonService.checkJerarquia(org.template, TypeJerarquia.REGION);
        let tieneDivision = this.commonService.checkJerarquia(org.template, TypeJerarquia.DIVISION);
        
        if (tieneRegion) {    
            mostrarRegion = true;
            // Traemos las REGIONES
            let sr = await this.commonService.pedirRegiones(org.domainRoot, "R");     
            if (sr) {
                this.listaRegion = sr.content;
            }
        } else if (tieneDivision) {
            mostrarDivision = true;
            // Traemos las DIVISIONES
            let sr = await this.commonService.pedirDivision(org.domainRoot, "R");   
            if (sr) {
                this.listaDivision = sr.content;
            }
        } else {
            // Traemos las MECHARNT
            let sr = await this.commonService.pedirMerchant(org.domainRoot, "R");   
            if (sr) {
                this.listaMerchant = sr.content;
            }
        } 
    }

    this.verDivision = mostrarDivision;
    this.verRegion = mostrarRegion;
}

 

  async regionFilter(ob:MatSelectChange) {
    let reg:Merchants
    reg=ob.value; 

    let mostrarDivision=false

    if(reg && this.selectedOrg.template){

      let tieneDivision = this.commonService.checkJerarquia(this.selectedOrg.template, TypeJerarquia.DIVISION)
      if(tieneDivision){  mostrarDivision=true   }


      if(tieneDivision){         
          //traemos las DIVISIONES
          let srDiv = await this.commonService.pedirDivision(reg.domain,"R");   
          if(srDiv){
            this.listaDivision= srDiv.content;
          }
      }else{
          //traemos las  MECHARNT
          let sr = await this.commonService.pedirMerchant(reg.domain,"R");   
          if(sr){
            this.listaMerchant= sr.content;
          }
      }
    }

    this.verDivision=mostrarDivision
  }


  async divisionFilter(ob:MatSelectChange) {
    let reg:Merchants
    reg=ob.value; 

    if(reg){
         //traemos las  MECHARNT
         let sr = await this.commonService.pedirMerchant(reg.domain,"R");   
         if(sr){
           this.listaMerchant= sr.content;
         }      
    }

  }


  onFormSubmit(): void {
    console.log(this.registerForm);

    if (this.registerForm.invalid) {
      return;
    }
    const data = this.applyFormats(this.registerForm);



    let objNew: Merchants = new Merchants();
    if (this.objBBDD.id) {
      objNew = this.objBBDD
    }

    objNew.name = data['name']
    objNew.merchantId=data['shortname'] 

    let orgNew: Organization
    orgNew = data['orgMarcado']
    let domainRoot= orgNew.domainRoot
    
    let isPulsadoRegion=false
    let isPulsadoDivision=false
    let isPulsadoMerchants=false

    let regionNew:Merchants
    regionNew=data['regionMarcado']
    if(regionNew){
      isPulsadoRegion=true
      domainRoot=regionNew.domain
    }
    

    let divisionNew:Merchants
    divisionNew=data['divisionMarcado']
    if(divisionNew){
      isPulsadoDivision=true
      domainRoot=divisionNew.domain
    }

    let merchantNew:Merchants
    merchantNew=data['merchantMarcado']
    if(merchantNew){
      isPulsadoMerchants=true
      domainRoot=merchantNew.domain
    }

    objNew.domain=domainRoot
    objNew.structure = this.commonService.obtenerStructure(isPulsadoRegion,isPulsadoDivision,isPulsadoMerchants,true)

    let tempCountry = data['country']
    let tempState = data['state']
    let tempCity = data['city']
    let tempAddress = data['address']
    let tempPostalCode = data['postalCode']
    let tempEmail = data['email']
    let tempphonP = data['phone']



    let properties: MerchantsInfo = new MerchantsInfo();
    if (tempEmail || tempphonP) {
      properties.email = tempEmail;
      properties.phone = tempphonP;
    }

    if (tempCountry || tempState || tempCity || tempAddress) {
      let address: MerchantsAddress = new MerchantsAddress();
      address.country = tempCountry;
      address.state = tempState;
      address.city = tempCity;
      address.addressLine1 = tempAddress;
      address.postalOrZipCode = tempPostalCode;
      properties.address = address;
    }

    objNew.info = properties;
    objNew.type = "SUBSIDIARY";

    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = this.translate.instant("SUBSIDIARY.create_title");
    dialogData.textI18n = this.translate.instant("SUBSIDIARY.create_desc");


    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });
    this.modalManager.registerModal(this.dialogRef);

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {

        console.log('Form SAVE action');
        let val:any;
        if (objNew.id) {
          val = await this.services.updateEditService(objNew)
        } else {
          val = await this.services.saveEditService(objNew)
        }

        if(val){
          this.dialogRef.close(FormCreateActions.SAVED);
        }
      } else if (result === ConfirmDialogActions.CANCEL) {
              //no hacemos nada
      }
    });

  }


  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }



  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body: any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }









}




