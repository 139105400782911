<div id="chartAPEX">
  <apx-chart #chartObj
  [series]="chartOptions?.series!"
  [chart]="chartOptions?.chart!"
  [dataLabels]="chartOptions?.dataLabels!"
  [title]="chartOptions?.title!"
  [colors]="chartOptions?.colors!"
  [xaxis]="chartOptions?.xaxis!"
  [yaxis]="chartOptions?.yaxis!"
  ></apx-chart>
</div>
