<aside id="sidebar" class="sidebar personalMenu {{isEntornoXiibero}}">
   <ul class="sidebar-nav" id="sidebar-nav">

      <li class="nav-item hovered_navItem" id="toc_li_Home" data-level="1" (click)="marcarMenu('toc_cb_Home')">
         <input id="toc_cb_Home" class="toc" type="checkbox">
         <label for="toc_cb_Home">
               <a class="nav-link nav-link-no-pulsable" routerLink="/dashboard">
                  <i class="bi bi-grid"></i> 
                  <span i18n>{{"Dashboard" | translate }}</span> 
               </a>
         </label>
      </li>

      <li class="nav-item" id="toc_li_Views" data-level="1" *ngIf="checkMenuAccessShown()">        
            <!-- <a class="nav-link collapsed" data-bs-target="#Views-nav" data-bs-toggle="collapse" href="#"> 
               <i class="bi bi-layout-text-window-reverse"></i><span i18n>{{"Views" | translate }}</span><i class="bi bi-chevron-down ms-auto"></i>
            </a> -->
         <div class="nav-link-no-pulsable">
            <i class="bi bi-layout-text-window-reverse">&nbsp;</i><span i18n>{{"Views" | translate }}</span>
         </div>
         <ul id="Views-nav" class="nav-content " data-bs-parent="#sidebar-nav">           
            <li id="toc_li_organizations" data-level="2"  (click)="marcarMenu('toc_cb_organizations')" *ngIf="checkMenuAccess('organization')">
               <input id="toc_cb_organizations" class="toc" type="checkbox">
               <label for="toc_cb_organizations">
                  <a routerLink="/organizations"> <i class="bi bi-building"></i><span i18n>{{"Organizations" | translate }}</span> </a>
               </label>
            </li>


            <li id="toc_li_region" data-level="2"  (click)="marcarMenu('toc_cb_region')" *ngIf="checkMenuAccess('region') && isJerarquiaRegion">
               <input id="toc_cb_region" class="toc" type="checkbox">
               <label for="toc_cb_region">
                  <a routerLink="/region"> <i class="bi bi-bank"></i><span i18n>{{"Regions" | translate }}</span> </a>
               </label>
            </li>

            <li id="toc_li_division" data-level="2"  (click)="marcarMenu('toc_cb_division')" *ngIf="checkMenuAccess('division')  &&  isJerarquiaDivision">
               <input id="toc_cb_division" class="toc" type="checkbox">
               <label for="toc_cb_division">
                  <a routerLink="/division"> <i class="bi bi-shop-window"></i><span i18n>{{"Divisions" | translate }}</span> </a>
               </label>
            </li>
            
            <li id="toc_li_merchants" data-level="2"  (click)="marcarMenu('toc_cb_merchants')" *ngIf="checkMenuAccess('merchant')">
               <input id="toc_cb_merchants" class="toc" type="checkbox">
               <label for="toc_cb_merchants">
                  <a routerLink="/merchants"> <i class="bi bi-shop"></i><span i18n>{{"Merchants" | translate }}</span> </a>
               </label>
            </li>


            <li id="toc_li_subsidiary" data-level="2"  (click)="marcarMenu('toc_cb_subsidiary')" *ngIf="checkMenuAccess('merchant')  && isJerarquiaSubsidiary ">
               <input id="toc_cb_subsidiary" class="toc" type="checkbox">
               <label for="toc_cb_subsidiary">
                  <a routerLink="/subsidiary"> <i class="bi bi-bag"></i><span i18n>{{"Subsidiaries" | translate }}</span> </a>
               </label>
            </li>


            <li id="toc_li_terminal" data-level="2"  (click)="marcarMenu('toc_cb_terminal')" *ngIf="checkMenuAccess('terminal')">
               <input id="toc_cb_terminal" class="toc" type="checkbox">
               <label for="toc_cb_terminal">
                  <a routerLink="/terminal"> <i class="bi bi-calculator"></i><span i18n>{{"Terminals" | translate }}</span> </a>
               </label>
            </li>













            <li id="toc_li_transaction" data-level="2"  (click)="marcarMenu('toc_cb_transaction')" *ngIf="checkMenuAccess('transaction')">
               <input id="toc_cb_transaction" class="toc" type="checkbox">
               <label for="toc_cb_transaction"  >
                  <a routerLink="/transaction"> <i class="bi bi-credit-card"></i><span i18n>{{"Transactions" | translate }}</span> </a>
               </label>
            </li>
            <li id="toc_li_alerts" data-level="2"  (click)="marcarMenu('toc_cb_alerts')" *ngIf="checkMenuAccess('notification')">
               <input id="toc_cb_alerts" class="toc" type="checkbox">
               <label for="toc_cb_alerts">
                  <a routerLink="/alerts"> <i class="bi bi-bell"></i><span i18n>{{"Alerts" | translate }}</span> </a>
               </label>
            </li>
            <li id="toc_li_orders" data-level="2"  (click)="marcarMenu('toc_cb_orders')" *ngIf="checkMenuAccess('terminal') && isEntornoXiibero">
               <input id="toc_cb_orders" class="toc" type="checkbox">
               <label for="toc_cb_orders">
                  <a routerLink="/orders"> <i class="bi bi-basket2"></i><span i18n>{{"Orders" | translate }}</span> </a>
               </label>
            </li>
         </ul>
      </li>

      <li class="nav-item hovered_navItem" *ngIf="checkUserAccessShown()">
         <a class="nav-link nav-link-no-pulsable collapsed" data-bs-target="#Admin-nav" data-bs-toggle="collapse" href="#"> 
            <i class="bi bi-shield-shaded"></i><span i18n>{{"Admin" | translate }}</span><i class="bi bi-chevron-down ms-auto"></i>
         </a>
         <ul id="Admin-nav" class="nav-content collapse" data-bs-parent="#sidebar-nav">
            <li *ngIf="checkUserAccess('user')" id="admin_user" (click)="marcarMenu('admin_user')" [ngClass]="{'selectedNavitem': selectedAdminItem === 'admin_user'}">
               <a routerLink="/users">
                  <i class="bi bi-person"></i> <span i18n>{{"Users" | translate }}</span>
               </a>
            </li>
            <li *ngIf="checkUserAccess('role')" id="admin_roles" (click)="marcarMenu('admin_roles')" [ngClass]="{'selectedNavitem': selectedAdminItem === 'admin_roles'}">
               <a routerLink="/rols">
                  <i class="bi bi-award"></i> <span i18n>{{"Roles" | translate }}</span>
               </a>
            </li>
            <li *ngIf="checkUserAccess('group')" id="admin_groups" (click)="marcarMenu('admin_groups')" [ngClass]="{'selectedNavitem': selectedAdminItem === 'admin_groups'}">
               <a routerLink="/groups">
                  <i class="bi bi-people"></i><span i18n>{{"Groups" | translate }}</span>
               </a>
            </li>
            <li *ngIf="checkMenuAccess('organization') && !isEntornoXiibero" id="admin_org" (click)="marcarMenu('admin_org')" [ngClass]="{'selectedNavitem': selectedAdminItem === 'admin_org'}">
               <a routerLink="/orgSetting">
                  <i class="bi bi-gear"></i><span i18n>{{"Settings" | translate }}</span>
               </a>
            </li>
         </ul>
      </li>

      <!-- components del framework - TODO: ELIMINAR      
       <li class="nav-item"> <a class="nav-link collapsed" routerLink="/pages-contact"> <i class="bi bi-envelope"></i> <span>Contact</span> </a></li>-->     

    </ul>
 </aside>
