import { Injectable } from '@angular/core';
import { Organization, OrganizationTemplate } from '../models/organization.model';
import { ENTORNO, TypeJerarquia } from '../util/constants';
import { QueryFilterList, RequestFilter } from '../models/requestFilter.model';
import { MerchantService } from '../pages/views/merchants/merchant.service';
import { Merchants } from '../models/merchants.model';
import { VIWER_ENTORNO } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationsService } from 'src/app/pages/views/organizations/organizations.service';
import { MatDialogRef } from '@angular/material/dialog';

export enum EHierarchyLevel {
  ORGANIZACION="ORGANIZACION",
  REGION="REGION",
  MERCHANT="MERCHANT",
  DIVISION="DIVISION",
}


@Injectable({
  providedIn: 'root',
})
export class UtilService {
  
  entorno = VIWER_ENTORNO;
  private registeredModals: MatDialogRef<any>[] = [];

  constructor(
      private merchantService: MerchantService,
      private translateService: TranslateService,
      private orgService: OrganizationsService) {}


  public checkJerarquia(templace:OrganizationTemplate | undefined, nivel:TypeJerarquia): boolean  {
    if (templace && nivel) {     
        let permiso = false;

        if(nivel == TypeJerarquia.REGION && templace.hasRegions) {     
            permiso = templace.hasRegions;
        }else if(nivel == TypeJerarquia.DIVISION && templace.hasDelegations) {     
            permiso = templace.hasDelegations;
        }else if(nivel == TypeJerarquia.SUBSIDIARY && templace.hasSubsidiary) {     
            permiso = templace.hasSubsidiary;
        }     
        return permiso;      
    } 
      
    return false;

  }

  async pedirToBackend(domain:string | undefined, type:string,pemiso:string){

    let mapQuery = new Map();
    let filter =  QueryFilterList.construirFilter(mapQuery, "type", type);
    if(domain){
      filter =  QueryFilterList.construirFilter(mapQuery, "domain", domain+".");
    }
    
    let rfRegi: RequestFilter= new RequestFilter(filter,0, 100,"name,desc");
  
    if(pemiso==="R"){
      return await this.merchantService.findRead(rfRegi); 
    }else{
      return await this.merchantService.findWrite(rfRegi); 
    }
  }


  async pedirDivision(domain:string | undefined, permiso:string){
      return this.pedirToBackend(domain,EHierarchyLevel.DIVISION,permiso);   
  }


  async pedirRegiones(domain:string | undefined, permiso:string){
        return this.pedirToBackend(domain,EHierarchyLevel.REGION,permiso); 
  }

  async lookup(domain: string | undefined, type:EHierarchyLevel, permiso:string) {
    let value = null;

    if(this.entorno==ENTORNO.XIIBERO){
      // Ignoramos el objeto de entrada
      
      value = await this.merchantService.lookup(type, true).then(sr=>{
        if(sr && sr.content){
          sr.content = sr?.content?.filter((e: { domain: { startsWith: (arg0: string | undefined) => any; }; }) => e.domain.startsWith(domain));
        }
        return sr;
      })
      .catch(err => {
        return null
      })

    } else {
      // MERCHANT_PORTAL | GETNET
      value = await this.pedirToBackend(domain,type,permiso);
    }
    return value;   
  }


async pedirMerchant(domain:string | undefined, permiso:string){

    let mapQuery = new Map();
    let filter = QueryFilterList.construirFilter(mapQuery, "", "");
    if(domain){
      filter =  QueryFilterList.construirFilter(mapQuery, "domain", domain+".");
    }
    if(this.entorno==ENTORNO.XIIBERO){
      filter = QueryFilterList.construirFilter(mapQuery, "type", "MERCHANT"); 
    }

    
    let rfRegi: RequestFilter= new RequestFilter(filter,0, 100,"name,desc");       
    if(permiso==="R"){
      return await this.merchantService.findRead(rfRegi); 
    }else{
      return await this.merchantService.findWrite(rfRegi); 
    }
}



public ObtenerJerarquia(dominio:string, domainDetail:string):Map<string,string>{
  let mapa=new Map();
  if(dominio && domainDetail){
        let domainList = dominio.split(".")
        let domainDetailList = domainDetail.split(".")
        let domainDetailListFinal:string[]=[]

        domainDetailList.forEach((val)=>{
          if(val!="*"){
            domainDetailListFinal.push(val)
          }
        });

      
        let i=0;
        if(domainDetailListFinal.length>0){
          while(i<domainList.length){

            let dominioEntero=""
            let j=0
            while(j<=i){        
              if(j>0){
                dominioEntero=dominioEntero.concat(".")
              }
              dominioEntero=dominioEntero.concat(domainList[j])
              j++
            }
            mapa.set(domainDetailListFinal[i],dominioEntero);
            i++
          }
        }
  }
  return mapa
}





cargarCacheDomainOnlyOrg() {
      
  let precargaOrg = localStorage.getItem('ORGANIZATION'); 
  
  let value:string | undefined
 if(precargaOrg && precargaOrg!="undefined"){
    let obj:Organization
    obj= JSON.parse(precargaOrg); 
    if(obj){
      value=obj.domainRoot
    }
  }
 return value;
}

cargarCacheDomain() {
      
  let precargaOrg = localStorage.getItem('ORGANIZATION'); 
  let precargaReg = localStorage.getItem('REGION');  
  let precargaDiv= localStorage.getItem('DIVISION');
  let precargaMec = localStorage.getItem('MERCHANT');
  
  let value:string | undefined
  if(precargaMec && precargaMec!="undefined" ){
    let obj:Merchants
    obj= JSON.parse(precargaMec); 
    if(obj){
      value=obj.domain//+";"
    }
  }else if(precargaDiv && precargaDiv!="undefined"){
    let obj:Merchants
    obj= JSON.parse(precargaDiv); 
    if(obj){
      value=obj.domain//+"."
    }
  }else if(precargaReg && precargaReg!="undefined"){
    let obj:Merchants
    obj= JSON.parse(precargaReg); 
    if(obj){
      value=obj.domain//+"."
    }
  }else if(precargaOrg && precargaOrg!="undefined"){
    let obj:Organization
    obj= JSON.parse(precargaOrg); 
    if(obj){
      value=obj.domainRoot//+"."
    }
  }
 return value;
}



obtenerStructure(isPulsadoRegion:boolean,isPulsadoDivision:boolean,isPulsadoMerchant:boolean,isPulsadoSubsidiary:boolean) {
    let structure="O";
    if(isPulsadoRegion){
      structure = structure.concat(".R");
  }else{
    structure = structure.concat(".*");
  }
  if(isPulsadoDivision){
    structure = structure.concat(".D");
  }else{
    structure = structure.concat(".*");
  }
  if(isPulsadoMerchant){
    structure = structure.concat(".M");
  }else{
    structure = structure.concat(".*");
  }
  if(isPulsadoSubsidiary){
    structure = structure.concat(".S");
  }else{
    structure = structure.concat(".*");
  }

 return structure;
}




async cargarCacheOrganization() {
      
  let precargaOrg = localStorage.getItem('ORGANIZATION'); 
  if (!precargaOrg) {
    //Check the user has more than 1 organization selectable:
    let rfOrg: RequestFilter = new RequestFilter(null, 0, 2, "");
    let srOrg = await this.orgService.find(rfOrg,true);
    if (srOrg?.content && srOrg.content.length==1) {      
        // Si solo hay 1 ponemos por defecto valor
        precargaOrg = srOrg.content[0];        
        localStorage.setItem('ORGANIZATION', JSON.stringify(srOrg.content[0]));      
    } 

  }

 return precargaOrg;
}

public translate(text : string) : string {
  if(text && ''!==text){
    return this.translateService.instant(text);
  }
  return text;
}
public registerDialogModal(dialog: MatDialogRef<any>) : void {
  this.registeredModals.push(dialog);
}

public closeAllModals() {
  this.registeredModals.forEach(dialog => {
    dialog.close();
  });
  this.registeredModals = [];
}
}