
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup,  } from '@angular/forms';
import {MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import {MatSort, Sort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { QueryAggregationList, QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { Transaction } from 'src/app/models/transaction.model';
import { VIWER_ENTORNO, environment } from 'src/environments/environment';
import { TransactionsService } from './transactions.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {MatLegacyRadioModule as MatRadioModule, MatLegacyRadioChange as MatRadioChange} from '@angular/material/legacy-radio';
import { INumericRange } from 'src/app/components/numeric-range/form/model/numeric-range-field.model';
import { ChartPayload } from 'src/app/shared/interfaces/chart.model';
import { Ticks } from 'chart.js';
import * as XLSX from "xlsx";
import { Ticket } from 'src/app/models/ticket.model';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { DateFormats, ENTORNO, FormCreateActions } from 'src/app/util/constants';
import { DataExport_Transaction } from 'src/app/models/dataExport/dataExport_transaction.model';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ReferencedDialogComponent } from 'src/app/pages/views/transaction/referenced-dialog/referenced-dialog.component';
import { ConfirmDialogActions } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { ModalManagerService } from 'src/app/services/modalManager.service';
import { currencyList } from 'src/app/util/currency';
import { DashboardService } from '../../dashboard/dashboard.service';

enum Columns_xiibero {  
  expand = 'expand',
  logo = 'logo',
  type = 'type',
  date = 'date',
  trx_amount = 'trx.amount', 
  trx_currency = 'trx.currency', 
  status = 'status',
  card_cardType = 'card.cardType',
  card_entry = 'card.entry',
  card_cardInfoObfuscated = 'card.cardInfoObfuscated',
  terminal_terminalId = 'terminal.terminalId', 
  trx_authcode = 'trx.authcode',
  merchant_name = 'merchant.name', 
  merchant_merchantId = 'merchant.merchantId', 
  button='button',
  buttonCards='buttonCards'
}
enum Columns_getnet {
  expand = 'expand',
  logo = 'logo',
  type = 'type',
  date = 'date',
  trx_amount = 'trx.amount', 
  trx_currency = 'trx.currency', 
  status = 'status',
  card_cardType = 'card.cardType',
  card_entry = 'card.entry',
  card_cardInfoObfuscated = 'card.cardInfoObfuscated',
  trx_transactionId = 'trx.transactionId',
  trx_authcode = 'trx.authcode',
  terminal_terminalId = 'terminal.terminalId', 
  merchant_name = 'merchant.name', 
  merchant_merchantId = 'merchant.merchantId', 
  button='button',
  buttonCards='buttonCards'
}
let Columns: any

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TransactionComponent implements OnInit, AfterViewInit {

  entorno = VIWER_ENTORNO;
  displayedColumns: any;
  
  listaMonedas:any=currencyList

  expandedElement: Transaction | null = new Transaction;
  dataSource = new MatTableDataSource<Transaction>();
  
  isEntornoXiibero: boolean = false;
  isEntornoGetnet: boolean = false;

  graficaTransaction: boolean = false;
  tablaTransaction: boolean = true;
  isJustTransactions: boolean = false;
  
  verBotones: boolean = false;
  
  timeZoneOffset;
  timezones;
  allTimeZones:string[];
  customTimezoneEnabled: boolean = false;
  terminalCurrencyCodeSelect: string[] = ['', 'EUR'];

  statusFilterList: string[]= [];

///filtros
serialFilter = new FormControl();
modelFilter = new FormControl();
refFilter = new FormControl();

  pageLength=0;
  pageIndex=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId=Columns_xiibero.date+",desc";//idColumna + "," + direccion

  localDateFilter = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  amountFilter= new FormControl(//{ minimum: 10,maximum: 20},  [ //Validators.required, //optional  Validators.min(10), //optional// Validators.max(100), //optional]
  )

  resultCodeSelect: string[] = ['',  'SUCCESS', 'DECLINED', 'ERROR', 'CANCELLED'];
  resultEntrySelect: string[] = ['',  'CTLESS', 'ICC', 'KEYED', 'MOBILE_CTLESS','MOBILE_MSD', 'MSD', 'SWIPED','QR'];
  cardBrandSelect: string[] = ['', 'VISA', 'MASTERCARD', 'AMEX', 'DISCOVER', 'DISCOVER_ZIP', 'DINERS',
  'CARTE_BLANCHE', 'JCB', 'ENROUTE', 'JAL', 'MAESTRO',  'DELTA', 'VISA_ELECTRON', 'DANKORT', 'CARTES_BANCAIERES',  'CARTA_SI', 'UATP', 'MAESTRO_INT', 'HIPERCARD', 
  'AURA', 'ELO', 'CHINA_UNION_PAY', 'SERVIRED', 'VISA_PAY', 'MAESTRO_UK', 'UNION_PAY_DEBIT', 'UNION_PAY_CREDIT', 'UNION_PAY_QUASYCREDIT',  'MULTIBANCO', 'UNKNOWN'];
  transactionTypeSelect: string[] = ['',  'PAYMENT', 'REFUND', 'CREDIT', 'REVERSAL',  'COMMUNICATION_TEST',
  'PREAUTH', 'CONFIRM', 'TOPUP',  'MANUAL_VOID', 'DUPLICATE', 'VOID', 'TOTALS', 'SETTLEMENT', 'TRANSACTIONS_DETAIL',  'OTHERS'];
  

  authorizationIdFilter = new FormControl();
  transactionIdFilter = new FormControl();
  terminalIdFilter = new FormControl();
  merchantIdFilter = new FormControl();
  merchantFilter = new FormControl();
  panFilter = new FormControl();
  currencyFilter= new FormControl();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('selectZones') selectZones!:HTMLSelectElement; 

  mapQuery = new Map();
  rf: RequestFilter= new RequestFilter(null,0,this.pageSize,this.sortId);

  graph1Data : ChartPayload = {
    labels :   [],
    values : []
  }
  graph2Data : ChartPayload = {
    labels :   [],
    values : []
  }
  graph3Data : ChartPayload = {
    labels :   [],
    values : []
  }
  graph4Data : ChartPayload = {
    labels :   [],
    values : []
  }
  graph5Data : ChartPayload = {
    labels :   [],
    values : []
  }
    
  jerarquiaSearchSbscription!: Subscription;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any | null,
  public dialog: MatDialog,
  public authServer: AuthService,
  public utilService: UtilService,
  public dialogRef: MatDialogRef<TransactionComponent>,
  public dialogInput: MatDialogRef<ReferencedDialogComponent>,    
  public radioButton: MatRadioModule,
  private transactionsService : TransactionsService,
  private translate : TranslateService,
  private modalManager: ModalManagerService,
  private readonly dashboardService: DashboardService
) {  

    

    if(this.entorno==ENTORNO.XIIBERO){
      this.isEntornoXiibero= true;
      Columns=Columns_xiibero
    }else{
      this.isEntornoGetnet = true;
      Columns=Columns_getnet
    }
    this.displayedColumns  =  Object.keys(Columns);

    this.allTimeZones=moment.tz.names();
    this.timeZoneOffset= this.calculateZoneOffset();    
    let userTimeZone = this.userTimeZone();
    let selectedTimeZone = this.calculateZoneOffset(false);
    this.timezones = [{name : "UTC", value: "UTC", checked : this.timeZoneOffset === "UTC"}];    
    this.timezones.push({name : userTimeZone, value: userTimeZone, checked : selectedTimeZone === userTimeZone});
    this.customTimezoneEnabled = selectedTimeZone !== userTimeZone && this.timeZoneOffset !== "UTC";
    this.timezones.push({name: translate.instant("Custom"), value : this.timeZoneOffset === "UTC"? userTimeZone:this.timeZoneOffset, checked : this.customTimezoneEnabled});  
    
    
    this.isJustTransactions = false;
    if(data?.obj?.serialnumber){
          //solo mostramos las transacciones del Terminal concreto que viene
          this.verBotones=true

          const idColumn_serialnumber = "terminal.serialnumber";
          let value_serialnumber = data.obj.serialnumber
          const idColumn_model = "terminal.model";
          let value_model = data.obj.model
                   

          this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn_serialnumber, value_serialnumber);
          this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn_model, value_model);           
          
          this.lanzarLlamada(); 
    }
    if(data?.split?.terminal){
      //solo mostramos las transacciones del Terminal concreto que viene
      this.isJustTransactions = true;
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "terminal.serialnumber", data.split.terminal.serialnumber);        
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "trx.splitPaymentId", data.split.trx.splitPaymentId);        
      this.lanzarLlamada();   
    }

  }


  verGrafica(){
      this.tablaTransaction=false
      this.graficaTransaction=true

      document.getElementById('icoVerGrafica_transaction')?.classList.add("botonMarcado");
      document.getElementById('icoVerTabla_transaction')?.classList.remove("botonMarcado");
      
      this.lanzarLlamada();
  }
  verTabla(){
      this.tablaTransaction=true
      this.graficaTransaction=false
      
      document.getElementById('icoVerTabla_transaction')?.classList.add("botonMarcado");
      document.getElementById('icoVerGrafica_transaction')?.classList.remove("botonMarcado");
  }

  cargarCache() {

    let value = this.utilService.cargarCacheDomain();

    if(value && value!="undefined"){
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", value);        
    }else{
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", undefined);            
    }
  
  
    this.lanzarLlamada();  
  }


  getNombreMoneda(codigo:string){

    return this.listaMonedas[codigo].majorSingle
  }


  exportIt() {
    //personalizamos el exportar
    let newDate = new Date();
    let nombre = "transaction-"+newDate.toISOString().slice(0, 10)+"-"+newDate.getTime();
    const fileName = nombre+".xlsx";

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.procesarDataExport(this.dataSource.data));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    let Heading = [[
      this.translate.instant("Merchant"), 
      this.translate.instant("Merchant Id"), 
      this.translate.instant("Terminal Id"), 
      this.translate.instant("Date"), 
      this.translate.instant("Amount"), 
      this.translate.instant("Currency"), 
      this.translate.instant("Type"), 
      this.translate.instant("Status"), 
      this.translate.instant("Card"), 
      this.translate.instant("Pan"), 
      this.translate.instant("TransactionId"), 
      this.translate.instant("AuthCode")
  ]];
      XLSX.utils.sheet_add_aoa(ws, Heading);
    XLSX.utils.book_append_sheet(wb, ws, "Transaction");

    XLSX.writeFile(wb, fileName);
  }

  private procesarDataExport(data:Transaction[]): any[]  {
    let result : DataExport_Transaction[] = [] ;
    data.forEach(row => {
      let tempRow:DataExport_Transaction = new DataExport_Transaction(row);
      result.push(tempRow);
    });
    return result;
  }

  private updateCurrencies() {
    let filter: RequestFilter = new RequestFilter(null,0, 500, 'model,desc');   
    filter.filter = this.rf.filter;
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.currency", "count");

    this.transactionsService.sendAggrService(filter, this.graph2Data).then(
        value => {
          this.graph2Data = {...value};
          value.labels.forEach(element => {
            const savedCurrency = this.terminalCurrencyCodeSelect.find(currency => currency === element);
            !savedCurrency && this.terminalCurrencyCodeSelect.push(element); 
          });
          
        });
  }


  public calculateZoneOffset(returnOffset : boolean = true) : string {
    if (this.isEntornoGetnet) {
      return this.calculateZoneOffsetGetnet();
    } else {
      //get the timezone from the 
      //Default value browser defined
      let timezone = moment.tz.guess();
      if (localStorage.getItem("transactionsTimeZone")) {   //Transactions section defined time zone
        timezone = localStorage.getItem("transactionsTimeZone") || timezone;
      } else if (localStorage.getItem("timezone")){  //User defined time zone
        timezone = this.userTimeZone();
      }
      if (returnOffset) {
        return this.calculateZoneOffsetFromText(timezone)
      } else {
        return timezone;
      }
    }    
  }

  public calculateZoneOffsetFromText(zone:string) : string {
    if (zone === "UTC") {
      return zone;
    } else {
      return moment.tz(zone).format('Z');
    }
  }

  public userTimeZone() : string {
    let timezone = moment.tz.guess();
    if (localStorage.getItem("timezone")){  //User defined time zone
      timezone = localStorage.getItem("timezone") || timezone;
    }
    return timezone;
  }

  private calculateZoneOffsetGetnet() : string {
    let zoneOffset = '+0000'
    let offset = (new Date().getTimezoneOffset())/60;

    if(offset > 0){ // positive timezone 
      zoneOffset = '+'+Math.abs(offset).toString().padStart(4,'0');
    } else if(offset < 0) { // negative timezone 
      zoneOffset = '-'+Math.abs(offset).toString().padStart(4,'0');
    }

    return zoneOffset;
  }


  updateTransactionsByType() {
    // Transaction by type
    let filter = {...this.rf};
      filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "type", "count");
      this.transactionsService.sendAggrService(filter, this.graph1Data).then(
        value => this.graph1Data = {...value});
  }

  updateTransactionsByBrand() {
    // Transaction by type
    let filter = {...this.rf};
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "card.cardType", "count");
    this.transactionsService.sendAggrService(filter, this.graph2Data).then(
      value => this.graph2Data = {...value});
  }

  updateTransactionsByTech() {
    let filter = {...this.rf};
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "card.entry", "count");
    this.transactionsService.sendAggrService(filter, this.graph3Data).then(
      value => this.graph3Data = {...value});
  }

  
  updateTransactionsByCurrency() {
    let filter = {...this.rf};
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.currency", "count");
    this.transactionsService.sendAggrService(filter, this.graph4Data).then(
      value => this.graph4Data = {...value});
  }

  updateTransactionsByStatus() {
    let filter = {...this.rf};
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "status", "count");
    this.transactionsService.sendAggrService(filter, this.graph5Data).then(
      value => this.graph5Data = {...value});
  }
  
  async lanzarLlamada(){
    let value = await this.transactionsService.sendService(this.rf);
    console.log(JSON.stringify(value))
    if(value){
      console.log(JSON.stringify(value.content))
      this.dataSource = new MatTableDataSource<Transaction>(value.content);
      this.pageLength=value.totalElements;
    }
    this.updateCurrencies();
    this.updateTransactionsByType();
    this.updateTransactionsByBrand();
    this.updateTransactionsByTech();
    this.updateTransactionsByCurrency();
    this.updateTransactionsByStatus();  
  }

  public translateUnknownValue(value: string): string {
    return value === 'UNKNOWN' ? '' : value;
  }

  public getCardLogo(card: string) {
    if(card){
      return card === 'UNKNOWN' ? '' :  'assets/img/card_icons/' + card.toLowerCase() + '.png';
    }
    return ''
  }

  public getTransaction_typeLogo(card: string) {
    if(card){	
      return card === 'UNKNOWN' ? '' :  'assets/img/transaction_type/' + card.toUpperCase() + '.svg';
    }
    return ''
  }
  

  public getCardEntryLogo(card: string) {    
    if(card){
      return card === 'UNKNOWN' ? '' :  'assets/img/card_entry/' + card.toUpperCase() + '.svg';
    }
    return ''
  }

  ngOnInit(): void {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      
  
  this.jerarquiaSearchSbscription = this.authServer.getJerarquiaSearchClicked().subscribe(() => this.cargarCache());


      this.authorizationIdFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
        const idColumn = Columns.trx_authcode;
          console.log(idColumn+"="+value);     
        this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
        this.lanzarLlamada();      
      });
      this.transactionIdFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
        const idColumn = Columns.trx_transactionId;
        console.log(idColumn+"="+value);     
        this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
        this.lanzarLlamada();   
      });       
      this.merchantFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
        const idColumn = Columns.merchant_name;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();   
      });   
      this.merchantIdFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
        const idColumn = Columns.merchant_merchantId;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();   
      });   
      this.terminalIdFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
        const idColumn = Columns.terminal_terminalId;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();   
      });   
      this.panFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
        const idColumn = Columns.card_cardInfoObfuscated;
        console.log(idColumn+"="+value);     
        this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
        this.lanzarLlamada();   
      });   
      this.localDateFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {        
        const idColumn = Columns.date;    
        console.log(idColumn+"->start="+value.start+"   end="+value.end);        
        this.rf.filter = QueryFilterList.construirRangoFechaFilter(this.mapQuery, idColumn, value.start,value.end);        
        this.lanzarLlamada();   
      });
      this.refFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {        
        const idColumn = "trx.ref";
        console.log(idColumn+"="+value);     
        this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
        this.lanzarLlamada();    
      });

      this.serialFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {      
        const idColumn = "terminal.serialnumber";
        console.log(idColumn+"="+value);     
        this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
        this.lanzarLlamada(); 
      }); 
      this.modelFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {      
        const idColumn = "terminal.model";
        console.log(idColumn+"="+value);     
        this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
        this.lanzarLlamada();    
     
      }); 

      
  this.cargarCache()


  }




  resultEntrySelectFilter(ob:MatSelectChange) {
    let value=ob.value; 
    const idColumn = "card.entry";
    console.log(idColumn+"="+value);  
    if(value && value==""){
      value=null
    }    
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.lanzarLlamada();
  }

  resultCodeSelectFilter(ob:MatSelectChange) {
    let value=ob.value; 
    const idColumn = Columns.status;
    console.log(idColumn+"="+value);  
    if(value && value==""){
      value=null
    }    
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.lanzarLlamada();
  }
  cardBrandSelectFilter(ob:MatSelectChange) {
    let value=ob.value; 
    const idColumn = Columns.card_cardType;
    console.log(idColumn+"="+value);    
    if(value && value==""){
      value=null
    }  
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.lanzarLlamada();
  }

  public terminalCurrencyCodeSelectFilter(ob:MatSelectChange) {
    let value=ob.value; 
    const idColumn = Columns.trx_currency;
    console.log(idColumn+"="+value);  
    if(value && value==""){
      value=null
    }
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.lanzarLlamada();  
  }

  transactionTypeSelectFilter(ob:MatSelectChange) {
    let value=ob.value; 
    const idColumn = Columns.type;
    console.log(idColumn+"="+value);  
    if(value && value==""){
      value=null
    }
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.lanzarLlamada();
  }

	onAmountFilterChange(value: INumericRange): void {
    //el evento no va bien, funciona mejor el obBlur
		console.log('Changed value: ', value);
    
	}
  onAmountFilterBlur(): void {
      let value:INumericRange
      value=this.amountFilter.value
      const idColumn = Columns.trx_amount;    
      console.log(idColumn+"->start="+value.minimum+"   end="+value.maximum);        
      this.rf.filter = QueryFilterList.construirRangoFilter(this.mapQuery, idColumn, value.minimum,value.maximum);        
      this.lanzarLlamada();   
	}
	

  cleanUnnecessaryWhiteSpaces(cadena: string){
    if(cadena){
      cadena=cadena.replaceAll(' ', '');
    }
    return cadena;
}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent(pag:PageEvent){
    console.log("pageEvent="+JSON.stringify(pag))
    this.pageSize = pag.pageSize;
    this.pageIndex = pag.pageIndex;
    this.rf = RequestFilter.construirPage(this.rf.filter , pag.pageIndex,pag.pageSize,this.sortId);
    this.lanzarLlamada();
  }
  announceSortChange(sortState: Sort) {
    console.log("ordenar="+JSON.stringify(sortState)) 
    let active = sortState.active.replace("_",".")
    let direccion="";
    if(sortState.direction){
      direccion=","+sortState.direction
    }
    this.sortId=active+direccion;
    this.rf = RequestFilter.construirPage(this.rf.filter , this.pageIndex,this.pageSize,this.sortId);
   this.lanzarLlamada();
  }





  async viewTicket(trx:Transaction){

      let ti:Ticket = new Ticket();
      ti.id=trx.id
      ti.type="RECEIPT"

      let valueBlob = await this.transactionsService.getTiket(ti);

      if(valueBlob){

      /* DESCARGA EL PDF
       let link = document.createElement('a');
        link.download = 'hello.pdf';
        link.href = URL.createObjectURL(valueBlob);
        link.click();
        URL.revokeObjectURL(link.href);*/

        //ABRE UNA PESTAÑA CON EL PDF
        var fileURL = URL.createObjectURL(valueBlob);
        window.open(fileURL);
      }

    }


    refrescar() {
      this.lanzarLlamada();
    }

    configure() {

    }
    
  viewTransaction(row: any) {

    this.dialogRef = this.dialog.open(TransactionComponent, {
      width: '95%', height:'80%' ,panelClass: 'custom-modalbox',
      data: {
        split: row
      }
    });
    this.modalManager.registerModal(this.dialogRef);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }


  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }

  async viewReferenced(trx:Transaction, type:string){
     
    this.dialogInput = this.dialog.open(ReferencedDialogComponent, {
      width: '500px', panelClass: 'custom-modalbox',
      data: {
        trx : trx,
        type: type
      }
    });
    this.modalManager.registerModal(this.dialogRef);

    this.dialogInput.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        //await this.transactionsService.deleteService(trx.id);
        console.log('CONFIRM recived from dialog window');
        this.lanzarLlamada();

      } else{
        console.log('CANCEL recived from dialog window');
      }
    });

  }

  haveTransactions(trx:Transaction) : Boolean {
    let ret =  !this.isJustTransactions && 
                trx.trx?.splitPaymentId !==null &&
                trx.trx?.splitPaymentId !==undefined;    
    return ret;
  }

  haveTicket(trx:Transaction) : Boolean {
    let ret =  trx.receiptInfo?.hasReceipt!==undefined && 
               trx.receiptInfo?.hasReceipt!==undefined &&
               trx.receiptInfo?.hasReceipt;    
    return ret;
  }

  canBeRefunded(trx:Transaction) : Boolean {    
    let ret =  trx.status=='SUCCESS' && 
           ( trx.type=== "PAYMENT" || trx.type === "CONFIRM");    

    //In case of the amount refunded is higher or equals to the transaction amount, the refund can't be done
    if ( trx.trx?.amount!==undefined && trx.trx?.amount!==null && 
      trx.trx?.amountRefunded!==undefined && trx.trx?.amountRefunded!==null && 
      trx.trx?.amount <= trx.trx?.amountRefunded){
        ret = false;
      }
    return ret;
  }

  canBeConfirmed(trx:Transaction) : Boolean {    
    let ret =  trx.status=='SUCCESS' && 
           ( trx.type=== "PREAUTH");            
    return ret;
  }

  timeZoneChange(event:MatRadioChange){
      if (event.source.id != this.translate.instant("Custom")) {
          localStorage.setItem("transactionsTimeZone", event.value);
          this.timeZoneOffset= this.calculateZoneOffset();
          this.customTimezoneEnabled = false;
      } else {
        this.customTimezoneEnabled = true;
        event.source.checked = true;
        this.selectZones
      }
  }

  customTimeZoneChange(zone:string) {
    localStorage.setItem("transactionsTimeZone", zone);
    this.timeZoneOffset= this.calculateZoneOffset();
  }

  public formatDatebyLocale() {
    return DateFormats[this.dashboardService.setFormat(navigator.language)] + ', HH:mm:ss'
  }

  public translateCardBranches(cardBranch: string) {
    return cardBranch.replace("_", " ");
  }

  public manageStatus(status: string): string {
    this.resultCodeSelect.find(statusElement => statusElement === status)
      ?? this.resultCodeSelect.push(status);
    return this.utilService.translate(status);
  }

}
