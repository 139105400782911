<main id="main" class="main">


  
    <div class="pagetitle">
      <h1  i18n>{{"Divisions" | translate}}</h1>   
      <div>
       <button  *ngIf="commonService.checkItemRights('merchant','W','ALL')" mat-mini-fab (click)="onNew()">
        <i class="bi bi-plus-lg"></i>
       </button>
 
       <button  *ngIf="!commonService.checkItemRights('merchant','W','ALL')" mat-mini-fab (click)="refrescar()">
         <i class="bi bi-arrow-clockwise"></i>
        </button>    
 
     </div>
   </div>
  
  
    
    <div class="mat-elevation-z8 tablaPersonal">
     <table mat-table [dataSource]="dataSource"  matSort (matSortChange)="announceSortChange($event)"
     matTableExporter  #exporter="matTableExporter">
  
        
        
 
       <!-- name Column -->
       <ng-container matColumnDef="name">
         <th mat-header-cell *matHeaderCellDef><div mat-sort-header  i18n>{{"Name" | translate}}</div>  
           <div>
             <mat-form-field>
                 <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                     <input matInput class="form-field" [formControl]="nameFilter" placeholder="">
             </mat-form-field>
           </div>
         </th>       
         <td mat-cell *matCellDef="let element"> {{element.name}} </td>
       </ng-container>
 
 
 
 <!-- organization xiibero Column -->
   <ng-container matColumnDef="organization_xiibero">
     <th mat-header-cell *matHeaderCellDef class="tamano_columna_max"><div mat-sort-header  i18n>{{"Organization" | translate}} </div>  
       <div>
         <mat-form-field>
           <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>         
           <mat-select (selectionChange)="organizationXiiberoFilter($event)">            
              
             <mat-form-field class="filterSelect">
               <mat-label> </mat-label>
               <input matInput placeholder="filter..." #filterplc>
             </mat-form-field>
 
             <mat-option [value]=""> </mat-option>
             <div *ngFor="let op of organizationXiiberoList">
               <mat-option  *ngIf="op.name.includes(filterplc.value)" [value]="op" >
                 {{op.name}}
               </mat-option>
             </div>         
           </mat-select>
         </mat-form-field>
       </div>
     </th>       
     <td mat-cell *matCellDef="let element"  class="tamano_columna_max"> {{element.hierarchy?.ORGANIZATION?.name}} </td>
   </ng-container>
 
 
 
  
        <!-- merchantEmail Column -->
        <ng-container matColumnDef="info_email">
          <th mat-header-cell *matHeaderCellDef><div mat-sort-header  i18n>{{"Email" | translate}}</div>  
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                      <input matInput class="form-field" [formControl]="merchantEmailFilter" placeholder="">
              </mat-form-field>
            </div>
          </th>       
          <td mat-cell *matCellDef="let element"> {{element.info?.email}} </td>
        </ng-container>
 
 
         
  
 
          <ng-container matColumnDef="view">
           <th mat-header-cell *matHeaderCellDef></th>
           <td mat-cell *matCellDef="let element" class="button">   
             <div>
               <button *ngIf="commonService.checkItemRights('merchant','W',element.domain)" matTooltip="{{'edit' | translate}}"  (click)="onEdit(element)" mat-icon-button color="primary" >
                 <i class="bi bi-pencil"></i>
               </button>
       
               <button *ngIf="commonService.checkItemRights('merchant','D',element.domain)" matTooltip="{{'delete' | translate}}" (click)="onDelete(element)" mat-icon-button color="primary">
                 <i class="bi bi-trash"></i>
               </button>
             </div>
           </td>
         </ng-container>
        
  
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
 
      <button id="export" class="icoDownload" mat-icon-button (click)="exportIt() "><i class="bi bi-file-earmark-arrow-down"></i> </button>
     
 
      <mat-paginator [length]="pageLength"
               [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions"
               (page)="pageEvent($event)"
               showFirstLastButtons 
               aria-label="Select page of periodic elements">
 </mat-paginator>
 
 
    </div>
    </main>