import { Component, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Organization } from 'src/app/models/organization.model';
import { UsersService } from 'src/app/pages/admin/users/users.service';
import { CommonService } from 'src/app/services/common.service';
import { UtilService } from 'src/app/services/util.service';
import { ENTORNO, TypeJerarquia } from 'src/app/util/constants';
import { VIWER_ENTORNO } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  entorno = VIWER_ENTORNO;
  isEntornoXiibero: boolean = false;
  isJerarquiaRegion: boolean = false;
  isJerarquiaDivision: boolean = false;
  isJerarquiaSubsidiary: boolean = false;
  
  jerarquiaSearchSbscription!: Subscription;

  public selectedAdminItem: string = '';

  constructor(
    private authServer: AuthService,
    public utilServer: UtilService,
    public commonService : CommonService,
    private authService: AuthService
  ) { 

    if(this.entorno==ENTORNO.XIIBERO){
      this.isEntornoXiibero= true;     
      this.isJerarquiaRegion = true;
      this.isJerarquiaDivision = true;
      this.isJerarquiaSubsidiary = true;
    }
  }


  async ngOnInit(): Promise<void>  {
    this.jerarquiaSearchSbscription = this.authServer.getJerarquiaSearchClicked().subscribe(() => this.cargarCache());    
    //await this.cargarCache();
  }



  async cargarCache() {     
    let precargaOrg = await this.utilServer.cargarCacheOrganization();
    if(precargaOrg && precargaOrg !== "undefined") {     
      let obj: Organization     
        obj = JSON.parse(precargaOrg) 
        if( obj?.template){  
            this.isJerarquiaRegion =  this.commonService.checkJerarquia(obj.template, TypeJerarquia.REGION);
            this.isJerarquiaDivision  = this.commonService.checkJerarquia(obj.template, TypeJerarquia.DIVISION);
            this.isJerarquiaSubsidiary  =  this.commonService.checkJerarquia(obj.template, TypeJerarquia.SUBSIDIARY);
        }
    }
  }

  marcarMenu(id_Li:any){

    const testElements = document.getElementsByClassName('toc');
    const testDivs = Array.prototype.filter.call(testElements,
      (testElement) => testElement.checked = false,
    );

    const slectToc = document.getElementById(id_Li) as HTMLInputElement;    
    if(slectToc){
      slectToc.checked = true;
    }
    this.selectedAdminItem = id_Li
  }

  public checkMenuAccessShown(): boolean  {
    if (this.authServer.hasValidAccessToken() && this.authServer.hasValidIdToken()) {
      try {
        let tk = this.authServer.getUserAccessToken();
        let access = tk!.auth!.access!;
        
      return access.find((accessEntry: any) =>
        Object.values(accessEntry.permissions).some((permissionLevels: any) =>
            Object.values(permissionLevels).some((permission: any) => typeof permission === 'string' && permission.length > 0)
        )
    );
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  }


  public checkMenuAccess(item:string): boolean  {
    if (this.authServer.hasValidAccessToken() && this.authServer.hasValidIdToken()) {
      try {
        let conPermiso=false;

        let tk = this.authServer.getUserAccessToken();
        let lstPermisos:any[]
        lstPermisos = tk!.auth!.access;

        if(lstPermisos){
            conPermiso=this.checkPermiso(lstPermisos,item,'merchantsApi') || this.checkPermiso(lstPermisos,item,'adminXiibero');
        }

        return conPermiso;
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  }



  public checkPermiso(lstPermisos:any[],item:string,key:string): boolean  {
      try {
        let conPermiso=false
        lstPermisos.forEach((element) =>{
            //element.domain
            let permission: string | undefined
            let permissions= element!.permissions![key]
            if(permissions){
              permission  = permissions[item]          
            }
            
            if(((permission != undefined) && (permission.length > 0))){
                conPermiso=true               
            }

        });

        return conPermiso;
      } catch(err) {
        return false;
      }   
  }



  public checkUserAccessShown(): boolean  {
    if (this.authServer.hasValidAccessToken() && this.authServer.hasValidIdToken()) {
      try {
        let tk = this.authServer.getUserAccessToken();
        let hasAuthserverPermission = tk!.auth!.access!.some((accessEntry: Record<string, any>) => {
          const authserverPermissions = accessEntry['permissions']['authserver'];
          return authserverPermissions && (
              (authserverPermissions['admin'] && authserverPermissions['admin'].length > 0) ||
              (authserverPermissions['role'] && authserverPermissions['role'].length > 0) ||
              (authserverPermissions['user'] && authserverPermissions['user'].length > 0) ||
              (authserverPermissions['group'] && authserverPermissions['group'].length > 0)
          );
      });
      
      return hasAuthserverPermission;
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  }

  public checkUserAccess(item:string): boolean  {
    if(item === 'role'){
      let perfil = this.authService.userAccessLevel();
      return perfil === "ADMIN";
    }

    if (this.authServer.hasValidAccessToken() && this.authServer.hasValidIdToken()) {
      try {
        let tk = this.authServer.getUserAccessToken();
        let accessEntries = tk!.auth!.access! as Array<Record<string, any>>;
            return accessEntries.some((accessEntry: Record<string, any>) => {
                const authserverPermissions = accessEntry['permissions']['authserver'];
                const permission = authserverPermissions[item];
                return permission !== undefined && permission.length > 0;
            });
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  }

}
