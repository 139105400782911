<main id="main" class="main">

    <div class="pagetitle">
      <h1  i18n>{{"Alerts" | translate}}</h1>   
      <button  mat-mini-fab (click)="refrescar()">
        <i class="bi bi-arrow-clockwise"></i>
       </button>    
   </div>
  
  
    
    <div class="mat-elevation-z8 tablaPersonal">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">  
  
        
 
       <!-- name Column -->
       <ng-container matColumnDef="description">
         <th mat-header-cell *matHeaderCellDef><div mat-sort-header  i18n>{{"Description" | translate}}</div>  
           <div>
             <mat-form-field>
                 <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
                     <input matInput class="form-field" [formControl]="descriptionFilter" placeholder="">
             </mat-form-field>
           </div>
         </th>       
         <td mat-cell *matCellDef="let element"> {{element.description}} </td>
       </ng-container>
 
 
    <!-- serialnumber Column -->
    <ng-container matColumnDef="organization_name">
     <th mat-header-cell *matHeaderCellDef><div mat-sort-header  i18n>{{"Organization" | translate}}</div>  
       <div>
         <mat-form-field>
                 <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
                 <input matInput class="form-field" [formControl]="organizationFilter" placeholder="">
         </mat-form-field>
       </div>
     </th>       
     <td mat-cell *matCellDef="let element"> {{element.organization?.name}} </td>
   </ng-container>
 
 
 
  
        <!-- merchantEmail Column -->
        <ng-container matColumnDef="terminal_terminalId">
          <th mat-header-cell *matHeaderCellDef><div mat-sort-header  i18n>{{"Terminal" | translate}}</div>  
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
                      <input matInput class="form-field" [formControl]="terminalFilter" placeholder="">
              </mat-form-field>
            </div>
          </th>       
          <td mat-cell *matCellDef="let element"> {{element.terminal?.serialnumber}} </td>
        </ng-container>
  
    

  <!-- localDate Column -->        
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef > <div mat-sort-header i18n>{{"Date" | translate}}</div> 
      <div>
        <mat-form-field> 
          <mat-label></mat-label>
          <mat-date-range-input [formGroup]="localDateFilter" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="{{'Start Date' | translate}}">
            <input matEndDate formControlName="end" placeholder="{{'End Date' | translate}}">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.date | date:formatDatebyLocale()}}</td>
  </ng-container>


        
  
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    
 
      <mat-paginator [length]="pageLength"
               [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions"
               (page)="pageEvent($event)"
               showFirstLastButtons 
               aria-label="Select page of periodic elements">
 </mat-paginator>
 
 
    </div>
    </main>