import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import {MatSort, Sort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { Users } from 'src/app/models/users.model';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { FormUsersComponent } from './form-users/form-users.component';
import { UsersService } from './users.service';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationsService } from '../../views/organizations/organizations.service';
import { MerchantService } from '../../views/merchants/merchant.service';
import { Merchants } from 'src/app/models/merchants.model';
import { Organization } from 'src/app/models/organization.model';
import { ModalManagerService } from 'src/app/services/modalManager.service';
import { DateFormats } from 'src/app/util/constants';
import { DashboardService } from '../../dashboard/dashboard.service';

enum Columns {
  firstName = 'firstName',
  lastName = 'lastName',
  username = 'username',
  organization = 'organization',
  merchant = 'merchant',
  email = 'email',
  createdAt = 'createdAt',
  lastLogin = 'lastLogin',
  status = 'status',
  view = 'view'
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, AfterViewInit {

  displayedColumns =  Object.keys(Columns);
  dataSource = new MatTableDataSource<Users>();
  domainSelect: string = "";
  pageLength=0;
  pageIndex=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId=Columns.firstName+",desc";//idColumna + "," + direccion
  isAdmin:boolean = false; 
  organizationList: Organization[] = [];
  merchantList:Merchants[] = [];
  organizationSelect!: Organization;
  merchantSelect!: Merchants;
  statusSelect: string[] = ['',  'ACTIVE', 'PREACTIVE'];
  lastLoginFilter = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  dateCreateFilter = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  firstNameFilter = new FormControl();
  lastNameFilter = new FormControl();
  userNameFilter = new FormControl();
  emailFilter = new FormControl();
  translations: any;


  jerarquiaSearchSbscription!: Subscription;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
    private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private dialogRef!: MatDialogRef<FormUsersComponent>;

  mapQuery = new Map();
  rf: RequestFilter= new RequestFilter(null,0,this.pageSize,this.sortId);



  constructor(public dialog: MatDialog, 
    public services:UsersService, 
    public utilServer: UtilService,
    public commonService : CommonService,
    private translate: TranslateService,
    private authService: AuthService,
    private merchantService: MerchantService,
    private organizationService: OrganizationsService,
    private modalManager: ModalManagerService,
    private readonly dashboardService: DashboardService
  ) {     
  }


  async lanzarLlamada() {
    try {
        let value = await this.services.findService(this.rf);
        if (value) {
            this.dataSource = new MatTableDataSource<Users>(value.content);
            this.pageLength = value.totalElements;
            console.log('DataSource updated with:', value.content);
        }
    } catch (error) {
        console.error('Error fetching users:', error);
    }
}




  ngOnInit(): void {


    this.merchantService.getFindResult().subscribe({
      next: (merchants) => {
        this.merchantList = merchants.content;
      },
      error: (err) => {
        console.error('Failed to load merchants in Pipe:', err);
      },
      complete: () => console.log('merchants loading completed.')
    });

    this.organizationService.getFindResult().subscribe({
      next: (organizations) => {
        this.organizationList = organizations.content;
      },
      error: (err) => {
        console.error('Failed to load organizations in Pipe:', err);
      },
      complete: () => console.log('organizations loading completed.')
    });

    this.jerarquiaSearchSbscription = this.authService.getJerarquiaSearchClicked().subscribe(() => this.cargarCache());


    let perfil = this.authService.userAccessLevel();
    if(perfil === "ADMIN"){
      this.isAdmin=true
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    
    this.firstNameFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns.firstName;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();     
    });   
    this.lastNameFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns.lastName;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();      
    });
   
    this.userNameFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns.username;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();      
    });

      
    this.emailFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      const idColumn = Columns.email;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();  
    });   
    this.lastLoginFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      const idColumn = Columns.lastLogin;    
      console.log(idColumn+"->start="+value.start+"   end="+value.end);        
      this.rf.filter = QueryFilterList.construirRangoFechaFilter(this.mapQuery, idColumn, value.start,value.end);        
      this.lanzarLlamada(); 
    });
    this.dateCreateFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      const idColumn = Columns.createdAt;    
      console.log(idColumn+"->start="+value.start+"   end="+value.end);        
      this.rf.filter = QueryFilterList.construirRangoFechaFilter(this.mapQuery, idColumn, value.start,value.end);        
      this.lanzarLlamada(); 
    });
    
    this.translate
      .get([
        "Resend user invitation",
        "Are you sure you want to resend it?",
        "USERS"
      ])
      .subscribe(translation => {
        this.translations = translation.USERS.STATUS;
        this.statusSelect = ['', ...Object.keys(translation.USERS.STATUS)];
      });
    
    this.cargarCache();

  }


  cargarCache() {

    let value = this.utilServer.cargarCacheDomain();
    if(value && value!="undefined"){
      this.domainSelect = value;
      this.rf.filter = QueryFilterList.construirFilterDomain(this.mapQuery, "domain", value);        
    }else{
      this.domainSelect = "ALL";
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", undefined);            
    }
      
    this.lanzarLlamada();  
  }
  
  statusSelectFilter(ob:MatSelectChange) {
    let value = ob.value
    const idColumn = Columns.status;
    console.log(idColumn+"="+value);  
    if(value && value==""){
      value=null
    }   
    this.rf.filter = QueryFilterList.construirFilterComplet(this.mapQuery, "eq",idColumn, value);        
    this.lanzarLlamada(); 
  }



  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent(pag:PageEvent){
    console.log("pageEvent="+JSON.stringify(pag))
    this.pageSize = pag.pageSize;
    this.pageIndex = pag.pageIndex;
    this.rf = RequestFilter.construirPage(this.rf.filter , pag.pageIndex,pag.pageSize,this.sortId);
    this.lanzarLlamada();
  }
 
  announceSortChange(sortState: Sort) {
    console.log("ordenar=" + JSON.stringify(sortState));
    
    let active = sortState.active.replace("_", ".");
    let direccion = "";
    if (sortState.direction) {
      direccion = "," + sortState.direction;
    }
  
    // Establecer un criterio secundario si la ordenación es por nombre
    if (active === Columns.firstName) {
      // Ordenar por apellido como criterio secundario
      this.sortId = `${active}${direccion},${Columns.lastName},asc,${Columns.username},asc`;
    } else {
      this.sortId = active + direccion;
    }
  
    // Actualizar el RequestFilter
    this.rf = RequestFilter.construirPage(this.rf.filter, this.pageIndex, this.pageSize, this.sortId);
    this.lanzarLlamada();
  }



  onNew(){
    this.dialogRef = this.dialog.open(FormUsersComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {/*vacio al ser nuevo*/  }
    });
    this.modalManager.registerModal(this.dialogRef);

    this.dialogRef.afterClosed().subscribe(async (result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        await this.lanzarLlamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
   }



   onResetInvitacion(row: any) {

    console.log('On reset invitation: ', row);
 
      const dialogData = new ConfirmDialogData();

      this.translate
      .get([
        "Resend user invitation",
        "Are you sure you want to resend it?",
        "USERS"
      ])
      .subscribe(translation => {
        dialogData.titleI18n = translation["Resend user invitation"];
        dialogData.textI18n = translation["Are you sure you want to resend it?"];
        dialogData.textValue = row.name;
    
        this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '25%', panelClass: 'custom-modalbox',
          data: dialogData
        });
    });
    this.modalManager.registerModal(this.dialogRef);

    this.confirmDialogRef.afterClosed().subscribe((result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        console.log('CONFIRM recived from dialog window');
        this.services.invitationService(row)
        this.lanzarLlamada();
      } else if (result === ConfirmDialogActions.CANCEL) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }


  onActivateQuarantinedUser(row: any) {

    console.log('On unlock user: ', row);
 
      const dialogData = new ConfirmDialogData();      
    dialogData.titleI18n = this.translate.instant("USER.ActivateQuarantine_title");
    dialogData.textI18n = this.translate.instant("USER.ActivateQuarantine_desc");

      dialogData.textValue = row.name;
   
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });
    this.modalManager.registerModal(this.dialogRef);

    this.confirmDialogRef.afterClosed().subscribe((result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        console.log('CONFIRM received from dialog window');
        this.services.reActivateQuarantinedService(row)
        this.delayReload();
      } else if (result === ConfirmDialogActions.CANCEL) {
        console.log('CANCEL received from dialog window');
      }
    });
  }

  private delayReload() {
    setTimeout(() => this.lanzarLlamada(), 1000); // se retrasa el refresco de los datos 1 segundo
  }




   onResetPassword(row: any) {

    console.log('On reset pressed: ', row);
 
      const dialogData = new ConfirmDialogData();
      this.translate
      .get([
        "Reset user password",
        "Are you sure you want to reset it?"
      ])
      .subscribe(translation => {
        dialogData.titleI18n = translation["Reset user password"];
        dialogData.textI18n = translation["Are you sure you want to reset it?"];
        dialogData.textValue = row.name;
    
        this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '25%', panelClass: 'custom-modalbox',
          data: dialogData
        });
        this.modalManager.registerModal(this.dialogRef);

      });

    this.confirmDialogRef.afterClosed().subscribe((result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        console.log('CONFIRM recived from dialog window');
        this.services.resetPasswordService(row.username);
        this.lanzarLlamada(); //probar async
      } else if (result === ConfirmDialogActions.CANCEL) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }


   onDelete(row: any) {

    console.log('Delete pressed: ', row);
 
      const dialogData = new ConfirmDialogData();
      this.translate
      .get([
        "Delete user",
        "Are you sure you want to delete it?"
      ])
      .subscribe(translation => {
        dialogData.titleI18n = translation["Delete user"];
        dialogData.textI18n = translation["Are you sure you want to delete it?"];
        dialogData.textValue = row.name;
    
        this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '25%', panelClass: 'custom-modalbox',
          data: dialogData
        });
        this.modalManager.registerModal(this.dialogRef);

      });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        console.log('CONFIRM recived from dialog window');
        await this.services.deleteService(row.id);
        this.lanzarLlamada();
      } else if (result === ConfirmDialogActions.CANCEL) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }




  domainFilter(ob:MatSelectChange, tipo:string) {
    
    const idColumn = "domain";
    let value:string | undefined

    this.organizationSelect= new Organization;
    this.merchantSelect= new Merchants;


    if(tipo==="Org"){
      let org:Organization
      org=ob.value;       
      this.organizationSelect = org;
      if(org){
        value=org.domainRoot
      }
    }else  if(tipo==="Merchant"){
      let org:Merchants
      org=ob.value;
      this.merchantSelect =org;
      if(org){
        value=org.domain
      }
    }


    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.lanzarLlamada();  
  }

  public calculateTimeZone(date: any) {
    return this.dashboardService.calculateTimeZone(date);
  }

}
