
<div class="pagetitle">
    <h1  i18n>{{"Order" | translate}}</h1>      
 </div>
 <section class="section">
    <form [formGroup]="registerForm">

        <div class="row">
            <div class="col-lg-4">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Organization" | translate }} <span class="obligatorio">*</span></h5>
                        <mat-select formControlName="orgMarcado" [(value)]="selectedOrg" (selectionChange)="organizationFilter($event)">
                            <mat-option *ngFor="let op of listaOrg" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('orgMarcado')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-4" *ngIf="verRegion">
                <div class="card cardCustos" >
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Region" | translate }} </h5>
                        <mat-select formControlName="regionMarcado" [(value)]="selectedRegion" (selectionChange)="regionFilter($event)">
                            <mat-option *ngFor="let op of listaRegion" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('regionMarcado')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
    
            <div class="col-lg-4" *ngIf="verDivision">
                <div class="card cardCustos" >
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Division" | translate }} </h5>
                        <mat-select formControlName="divisionMarcado" [(value)]="selectedDivision"  (selectionChange)="divisionFilter($event)">
                            <mat-option *ngFor="let op of listaDivision" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('divisionMarcado')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-lg-4">
               <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Merchant" | translate}} <span class="obligatorio">*</span></h5>                   
                        <mat-select formControlName="merchMarcado" [(value)]="selectedMerch">
                            <mat-option *ngFor="let op of listaMerchant" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <!--Only if the organization selected already have templates-->
            <div class="col-lg-4" [hidden]="!listaTMSProfiles || listaTMSProfiles.length===0">
                <div class="card cardCustos">
                     <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"TMS Template" | translate}}</h5>                   
                         <mat-select formControlName="tmsProfileMarcado" [(value)]="selectedTMSProfile">
                             <mat-option *ngFor="let op of listaTMSProfiles" [value]="op">
                                 {{op.name}}
                             </mat-option>
                         </mat-select>
                     </mat-form-field>
                 </div>
             </div>

        </div>

        

     <div class="row">
         <div class="col-lg-4">
            <div class="card cardCustos">
                <mat-form-field ngDefaultControl>
                    <h5 i18n>{{"Manufacturer" | translate}} <span class="obligatorio">*</span></h5>
                    <mat-select formControlName="manufacture" [(value)]="selectedManufacture"  (selectionChange)="fabricanteFilter($event)">
                        <mat-option *ngFor="let op of listFabricantes" [value]="op">
                            {{op.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
             </div>
        </div>
        <div class="col-lg-4">
            <div class="card cardCustos">
                <mat-form-field ngDefaultControl>
                    <h5 i18n>{{"Model" | translate}} <span class="obligatorio">*</span></h5>
                    <mat-select formControlName="model" [(value)]="selectedModel">
                        <mat-option *ngFor="let op of listModelos" [value]="op">
                            {{op.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
         </div>
         <div class="col-lg-4">
            <div class="card cardCustos">
                <mat-form-field ngDefaultControl>
                    <h5 i18n>{{"Number Of Terminals" | translate}} <span class="obligatorio">*</span></h5>
                    <input formControlName="numTer" matInput type="text"  matInput required >
                    <mat-error *ngIf="registerForm.get('numTer')?.hasError('required')"  i18n>{{"REQUIRED_FIELD" | translate}}</mat-error>
                    <mat-error *ngIf="registerForm.get('numTer')?.hasError('pattern')"  i18n>{{"Invalid input format" | translate }}</mat-error>
                    <mat-error *ngIf="registerForm.get('numTer')?.hasError('maxLength')"  i18n>{{"Maximum length exceeded" | translate }}</mat-error>
                </mat-form-field>
            </div>
         </div>
    </div>
      


    <div class="row botonera">
         <div class="col-lg-12">
                 <button  class="btn btn-aceptar" (click)="onFormSubmit()" i18n>{{"Submit" | translate}}</button> 
                 <button  class="btn btn-cancelar" (click)="formExit()" i18n>{{"Cancel" | translate}}</button>
         </div>
     </div>


   
 </form>
 </section>

             
             
             
             
             
             

