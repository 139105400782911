
        <form [formGroup]="registerForm">
 
 
         <div class="row">
             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Name" | translate}} <span class="obligatorio">*</span></h5>
                         <input formControlName="name" matInput type="text" [readonly]="desactivado" required >
                         <mat-error *ngIf="registerForm.get('name')?.hasError('required')"  i18n>{{"Please enter a value!" | translate }}</mat-error>
                         <mat-error *ngIf="registerForm.get('name')?.hasError('pattern')"  i18n>{{"Invalid input format" | translate }}</mat-error>
                         <mat-error *ngIf="registerForm.get('name')?.hasError('maxLength')"  i18n>{{"Maximum length exceeded" | translate }}</mat-error>
                     </mat-form-field>
                 </div>
             </div>
             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Rol" | translate}} <span class="obligatorio">*</span></h5>                   
                        <mat-select formControlName="rolMarcado">
                            <mat-option *ngFor="let op of listaRol" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('rolMarcado')?.hasError('required')"  i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>

                    </mat-form-field>
                 </div>
             </div>
         </div>
         <div class="row">
            <div class="col-lg-12">
               <div class="card cardCustos">
                        <h5 i18n>{{"Description" | translate}}</h5>
                        <input formControlName="description" matInput type="text" style="border-bottom: 1px solid #999;">
                        <mat-error *ngIf="registerForm.get('description')?.hasError('pattern')"  i18n>{{"Invalid input format" | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('description')?.hasError('maxLength')"  i18n>{{"Maximum length exceeded" | translate }}</mat-error>
                </div>
            </div>
        </div>
 
        <div class="row" >
            <div class="col-lg-4">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Organization" | translate }} <span class="obligatorio">*</span></h5>
                        <mat-select   formControlName="orgMarcado" [(value)]="selectedOrg" (selectionChange)="organizationFilter($event)">
                            <mat-option *ngFor="let op of listaOrg" [value]="op" [disabled]="soloLectura">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('orgMarcado')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-lg-4" *ngIf="verRegion">
                <div class="card cardCustos" >
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Region" | translate }} </h5>
                        <mat-select formControlName="regionMarcado" [(value)]="selectedRegion" (selectionChange)="regionFilter($event)">
                            <mat-option *ngFor="let op of listaRegion" [value]="op"  [disabled]="soloLectura">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('regionMarcado')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
    
            <div class="col-lg-4" *ngIf="verDivision">
                <div class="card cardCustos" >
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Division" | translate }} </h5>
                        <mat-select formControlName="divisionMarcado" [(value)]="selectedDivision"  (selectionChange)="divisionFilter($event)">
                            <mat-option *ngFor="let op of listaDivision" [value]="op"  [disabled]="soloLectura">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('divisionMarcado')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="col-lg-4">
               <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Merchant" | translate}} </h5>                   
                        <mat-select formControlName="merchantMarcado" [(value)]="selectedMerch">
                            <mat-option *ngFor="let op of listaMerchant" [value]="op" [disabled]="soloLectura">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
          

        </div>

        

 
        <div class="row botonera">
             <div class="col-lg-12">
                     <button  class="btn btn-aceptar" (click)="onFormSubmit()" i18n>{{"Submit" | translate}}</button> 
                     <button  class="btn btn-cancelar" (click)="formExit()" i18n>{{"Cancel" | translate}}</button>
             </div>
         </div>
 
 
       
     </form>
 
                 
                 
                 
                 
                 
                 
 
 