import { Component, OnInit, ElementRef, Input, AfterViewInit, ViewChild, OnChanges } from '@angular/core';
import { NAMED_COLORS, CHART_COLORS } from '../../util/chartsutils';
import { TranslateService } from '@ngx-translate/core';

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip
} from "ng-apexcharts";
import { DateFormats } from 'src/app/util/constants';
import { formatDate } from '@angular/common';
import { DashboardService } from 'src/app/pages/dashboard/dashboard.service';
import { DEFAULT_LOCALE, LOCALES } from 'src/app/util/chartsLocales';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'app-chart-compare-values',
  templateUrl: './chart-compare-values.component.html',
  styleUrls: ['./chart-compare-values.component.css']
})
export class ChartCompareValuesComponent implements OnInit, OnChanges, AfterViewInit  {
  @Input() chartTitle !: string;
  @Input() labels!: string[];
  @Input() values!: number[];
  @Input() values2!: number[];
  @Input() lineChart?: boolean;
  @Input() dates?: string[];
  @Input() prevDates: any[] = [];
  @Input() actualDates: any[] = [];

  public chartOptions!: Partial<ChartOptions> ;

  private partitionsLiterals: any;
  private localeFormat: keyof typeof DateFormats = 'en';

  constructor(
    private translate: TranslateService,
    private readonly dashboardService: DashboardService
  ) { }

  ngAfterViewInit(): void {
  }

  @ViewChild('chartCV', { static: true }) chart!: ChartComponent;

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.init();
  }

  private init() {
    this.localeFormat = this.dashboardService.setFormat(navigator.language);
   
    this.chartOptions = {
      series: [
        { data: [] },
        { data: [] }
      ],
      chart: {
        type: "bar",
        locales: LOCALES,
      },
      xaxis: {
        categories: []
      },
      tooltip: {
        x: {
          format: DateFormats[this.localeFormat]
        }
      }
    };

    this.translate
      .get([
        'Previous',
        'Current',
        'operations',
        'TABLES_CONFIG',
        "FROM",
        "TO"
      ])      
      .subscribe(translation => {
        this.partitionsLiterals = translation;
        if (this.prevDates.length > 0 && this.actualDates.length > 0) {
          this.createChart(translation);
          this.printTable();
        }
      });
  }

  private _setPeriodLabel ( datesArray: string[] ): string {
    const fromDate = formatDate(
      datesArray[0], DateFormats[this.localeFormat], this.translate.currentLang);
    const toDate = formatDate(
      datesArray[datesArray?.length - 1], DateFormats[this.localeFormat], this.translate.currentLang);
    const text = (this.partitionsLiterals.FROM + fromDate + '$' +
      this.partitionsLiterals.TO + toDate);

    return text.replace('$', '<br>');
    
  }

  private printTable() {
    if (!(this.chart == undefined) && !(this.chartOptions == undefined) && !(this.labels == undefined)) {
      this.chartOptions.series![0].data.length = 0;
      this.chartOptions.series![1].data.length = 0;
      this.chartOptions.xaxis!.categories!.length = 0;

      if (this.labels.length > 0) {
        if ((this.labels.length == this.values.length)&&(this.labels.length == this.values.length)) 
        {
          this.chartOptions.series![0].data = this.values2;
          this.chartOptions.series![1].data = this.values;
          this.chartOptions.xaxis!.categories = this.translateLabelLiterals(this.labels);
        }
        this.chart?.updateOptions(this.chartOptions);
      }
    }
  }

  createChart(translation : any){ 
    this.chartOptions = {
      series: [
        {
          name: this._setPeriodLabel(this.prevDates),
          data: []
        },
        {
          name: this._setPeriodLabel(this.actualDates),
          data: []
        }
      ],
      chart: {
        type: "bar",
        locales: LOCALES,
        defaultLocale : DEFAULT_LOCALE,
        zoom: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: []
      },
      yaxis: {
        title: {
          text: translation.operations
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val +" " + translation.operations;
          }
        },
        x: {
          format: DateFormats[this.localeFormat]
        }
      },
      legend: {
        position: 'bottom',
        labels: {
        
          
        }
      }
    };    
  }

  translateLabelLiterals(labelArray: string[]) {
    const tranlatedLabelsArray: string[] = [];
    labelArray.forEach((literal: string) => {
      tranlatedLabelsArray.push(
        this.partitionsLiterals.TABLES_CONFIG.TRANSACTIONS_PARTITIONS[literal] ?? literal
      )});
    return tranlatedLabelsArray;
  };

}
