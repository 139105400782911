import { Component, OnInit, Input, ViewChild, AfterViewInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexYAxis,
  ApexTooltip,
  ApexFill,
  ApexLegend
} from "ng-apexcharts";
import { CurrencyPipe } from '@angular/common';
import ApexCharts from 'apexcharts';
import { DateFormats } from 'src/app/util/constants';
import { DashboardService } from 'src/app/pages/dashboard/dashboard.service';
import { DEFAULT_LOCALE, LOCALES } from 'src/app/util/chartsLocales';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
};

@Component({
  selector: 'app-chart-tx-daily',
  templateUrl: './chart-tx-number.component.html',
  styleUrls: ['./chart-tx-number.component.css'],
  providers: [CurrencyPipe]

})
export class ChartTxDailyNumberComponent implements OnInit, OnChanges, AfterViewInit  {
  @Input() chartTitle: string = '';
  @Input() dates: Date[] = [];
  @Input() values: Map<string, number[]> = new Map();
  @Input() values2: Map<string, number[]> = new Map();
  @Input() currency: string = '';
  @Input() isMultiCurrency: boolean = false;
  public chartOptions?: Partial<ChartOptions>;

  tablesTranlations: {xTitle: string, yTitle : string} = {
    xTitle: '',
    yTitle: ''
  };

  private initialized: boolean = false;
  private localeFormat: keyof typeof DateFormats = 'en';

  constructor(
    private translate: TranslateService,
    private readonly dashboardService: DashboardService
  ) {  
    this.initialized = true;
  }

  ngAfterViewInit(): void {
  }

  @ViewChild('chartDN', { static: true }) chart?: ChartComponent;

  ngOnInit(): void {
    
    
  }

  ngOnChanges() {
    this.initialized && this.init();
  }

  data = [
    [0,0]
  ];

  private init() {
    this.localeFormat = this.dashboardService.setFormat(navigator.language);
    this.translate.get('TABLES_CONFIG.TRANSACTIONS_DAILY').subscribe(tablesTranslation => {
      this.tablesTranlations = {
        xTitle: tablesTranslation.XAXIS_TITLE,
        yTitle: tablesTranslation.YAXIS_TITLE
      }
      this._createChart();
      this.printTableContent();
    });
  }
  
  private _createChart(){

    this.chartOptions = {      
      series: [
        {
          name: this.tablesTranlations.xTitle,
          data: [],
        },
        {
          name: this.tablesTranlations.yTitle,
          data: [],
        },
      ],
      chart: {
        defaultLocale : DEFAULT_LOCALE,
        type: "area",
        stacked: false,        
        locales: LOCALES,
        zoom: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      stroke: {
        curve: 'smooth',
      },
      title: {
        text: this.chartTitle
      },
      xaxis: {
        type: "datetime",
        tickAmount: this.dates?.length || 2,
        title: {
          text: this.tablesTranlations?.xTitle
        }
      },
      yaxis : {
        title: {
          text: this.tablesTranlations.yTitle
        },
        tickAmount: this._setTickAmount(),
      },
      tooltip: {
        enabled: true,
        x: {
          format: DateFormats[this.localeFormat]
        },
        y: {
          formatter: undefined,
          title: {
            formatter: () => `${this.tablesTranlations.yTitle}`
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100]
        }
      },
    };

  }
  private _setTickAmount() {
    let tickAmount: number = 2;
    const combinedValues = [...this.values, ...this.values2];
    for (let currency of combinedValues) {
      if ( tickAmount < currency[1].length && currency[1].find(value => value !== 0)) {
        tickAmount = currency[1].length;
      }
    }
    return tickAmount;
  }

  private printTableContent() {
    if (!(this.chart == undefined) && !(this.chartOptions == undefined) && !(this.dates == undefined)) {
      this.chartOptions.series = [];
      this.chartOptions.yaxis = {
        title: {
          text: this.tablesTranlations.yTitle
        },
        tickAmount: this._setTickAmount(),
      };
      if (this.dates.length > 0 && this.values) {
        for (let entry of this.values.entries()) {
          if(this.isMultiCurrency || (!this.isMultiCurrency && entry[0] === this.currency)) {
            var v : number[][] = new Array<Array<number>>();
            for (let i=0; i<this.dates.length; i++) {
              var item : number[] = new Array<number>();
              item.push(new Date(this.dates[i]).getTime());
              item.push(entry[1][i]);
              v.push(item);
            }
            this.chartOptions.series.push({ name : entry[0], data : v});
          }
        }
        if ((this.values2 != undefined) && (this.values2!.size > 0)) {
          for (let entry of this.values2.entries()) {
            var v : number[][] = new Array<Array<number>>();
            for (let i=0; i<this.dates.length; i++) {
              var item : number[] = new Array<number>();
              item.push(new Date(this.dates[i]).getTime());
              item.push(entry[1][i]);
              v.push(item);
            }
            this.chartOptions.series.push({ name : entry[0], data : v});
          }
        }
        this.chart?.updateSeries(this.chartOptions.series!);        
      }
    }
  }
}
