<main id="main" class="main">

    <div class="pagetitle">
      <h1  i18n>{{"Orders" | translate}}</h1>   

      <div>
        <button  *ngIf="commonService.checkItemRights('terminal','W','ALL')" mat-mini-fab (click)="onNew()">
         <i class="bi bi-basket2"></i>
        </button>
      </div>
   </div>
  
  
    
    <div class="mat-elevation-z8 tablaPersonal">
     
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
            matTableExporter  #exporter="matTableExporter">
        <!-- name Column -->
        <ng-container matColumnDef="orderId">
          <th mat-header-cell *matHeaderCellDef><div mat-sort-header  i18n>{{"OrderId" | translate}}</div>  
            <div>
              <mat-form-field>
                      
                      <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                      <input matInput class="form-field" [formControl]="orderIdFilter" placeholder="">
              </mat-form-field>
            </div>
          </th>       
          <td mat-cell *matCellDef="let element"> {{element.orderId}} </td>
        </ng-container>
       



<!-- organization xiibero Column -->
<ng-container matColumnDef="organization_xiibero">
  <th mat-header-cell *matHeaderCellDef class="tamano_columna_max"><div mat-sort-header  i18n>{{"Organization" | translate}} </div>  
    <div>
      <mat-form-field>
        <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>         
        <mat-select [(ngModel)]="organizationXiiberoSelect" (selectionChange)="xiiberoFilter($event,'Org')">            
          
          <mat-form-field class="filterSelect">
            <mat-label> </mat-label>
            <input matInput placeholder="filter..." #filterplc>
          </mat-form-field>

          <mat-option [value]=""> </mat-option>
          <div *ngFor="let op of organizationXiiberoList">
            <mat-option  *ngIf="op.name.includes(filterplc.value)" [value]="op" >
              {{op.name}}
            </mat-option>
          </div>         
        </mat-select>
      </mat-form-field>
    </div>
  </th>       
  <td mat-cell *matCellDef="let element"  class="tamano_columna_max"> {{element.hierarchy?.ORGANIZATION?.name}} </td>
</ng-container>


  <!-- Region xiibero Column -->
  <ng-container matColumnDef="region_xiibero">
    <th mat-header-cell *matHeaderCellDef class="tamano_columna_max"><div mat-sort-header  i18n>{{"Region" | translate }} </div>  
      <div>
        <mat-form-field>
          <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>         
          <mat-select [(ngModel)]="regionXiiberoSelect" (selectionChange)="xiiberoFilter($event,'Region')">            
             
            <mat-form-field class="filterSelect">
              <mat-label> </mat-label>
              <input matInput placeholder="filter..." #filterRegion>
            </mat-form-field>

            <mat-option [value]=""> </mat-option>
            <div *ngFor="let op of regionXiiberoList">
              <mat-option  *ngIf="op.name.toLowerCase().includes(filterRegion.value.toLowerCase())" [value]="op" >
                {{op.name}}
              </mat-option>
            </div>         
          </mat-select>
        </mat-form-field>
      </div>
    </th>       
    <td mat-cell *matCellDef="let element"  class="tamano_columna_max"> {{element.hierarchy?.REGION?.name}} </td>
  </ng-container>

  <!-- Division xiibero Column -->
  <ng-container matColumnDef="division_xiibero">
    <th mat-header-cell *matHeaderCellDef class="tamano_columna_max"><div mat-sort-header  i18n>{{"Division" | translate }} </div>  
      <div>
        <mat-form-field>
          <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>         
          <mat-select [(ngModel)]="divisionXiiberoSelect"  (selectionChange)="xiiberoFilter($event,'Division')">            
             
            <mat-form-field class="filterSelect">
              <mat-label> </mat-label>
              <input matInput placeholder="filter..." #filterRegion>
            </mat-form-field>

            <mat-option [value]=""> </mat-option>
            <div *ngFor="let op of divisionXiiberoList">
              <mat-option  *ngIf="op.name.toLowerCase().includes(filterRegion.value.toLowerCase())" [value]="op" >
                {{op.name}}
              </mat-option>
            </div>         
          </mat-select>
        </mat-form-field>
      </div>
    </th>       
    <td mat-cell *matCellDef="let element"  class="tamano_columna_max"> {{element.hierarchy?.DIVISION?.name}} </td>
  </ng-container>



<!-- merchant xiibero Column -->
<ng-container matColumnDef="merchant_xiibero">
<th mat-header-cell *matHeaderCellDef class="tamano_columna_max"><div mat-sort-header  i18n>{{"Merchant" | translate}} </div>  
<div>
<mat-form-field>
<mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>         
<mat-select [(ngModel)]="merchantXiiberoSelect" (selectionChange)="xiiberoFilter($event,'Merch')">            
  
  <mat-form-field class="filterSelect">
    <mat-label> </mat-label>
    <input matInput placeholder="filter..." #filterplc>
  </mat-form-field>

  <mat-option [value]=""> </mat-option>
  <div *ngFor="let op of merchantXiiberoList">
    <mat-option  *ngIf="op.name.includes(filterplc.value)" [value]="op" >
      {{op.name}}
    </mat-option>
  </div>         
</mat-select>
</mat-form-field>
</div>
</th>       
<td mat-cell *matCellDef="let element"  class="tamano_columna_max"> {{element.hierarchy?.MERCHANT?.name}} </td>
</ng-container>



   <!-- createdAt Column -->        
   <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef > <div mat-sort-header i18n>{{"Created" | translate}}</div> 
      <div>
        <mat-form-field> 
            <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
          <mat-label></mat-label>
          <mat-date-range-input [formGroup]="createdAtFilter" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="{{'Start Date' | translate}}">
            <input matEndDate formControlName="end" placeholder="{{'End Date' | translate}}">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.createdAt | date:'medium'}}</td>
  </ng-container>

            <!-- status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef><div mat-sort-header  i18n>{{"Status" | translate}}</div>  
                  <div>
                    <mat-form-field>
                            <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                            <input matInput class="form-field" [formControl]="statusFilter" placeholder="">
                    </mat-form-field>
                  </div>
                </th>       
                <td mat-cell *matCellDef="let element" class="DEFAULT {{element.status}}"> <div>{{element.status}}</div></td>
              </ng-container>


        <!-- terminals_manufacturer Column -->
        <ng-container matColumnDef="terminals_manufacturer">
          <th mat-header-cell *matHeaderCellDef><div mat-sort-header  i18n>{{"Manufacturer" | translate}}</div>  
            <div >
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i></mat-icon>
                <input matInput class="form-field" [formControl]="manufacturerFilter" placeholder="">
              </mat-form-field>
            </div>
          </th>       
          
          
            <td mat-cell *matCellDef="let element"> {{element.terminals?.manufacturer}} </td>
          </ng-container>


        <!-- model Column -->
        <ng-container matColumnDef="terminals_model">
            <th mat-header-cell *matHeaderCellDef><div mat-sort-header  i18n>{{"Model" | translate}}</div>  
              <div>
                <mat-form-field>
                        <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                        <input matInput class="form-field" [formControl]="modelFilter" placeholder="">
                </mat-form-field>
              </div>
            </th>       
            <td mat-cell *matCellDef="let element"> {{element.terminals?.model}} </td>
          </ng-container>

        <!-- numberOfTerminals Column -->
        <ng-container matColumnDef="terminals_numberOfTerminals">
            <th mat-header-cell *matHeaderCellDef><div mat-sort-header  i18n>{{"Num. Terminals/Pendings" | translate}}</div>  
            </th>       
            <td mat-cell *matCellDef="let element"> {{element.terminals?.numberOfTerminals}} /  {{element.terminals?.pendings}}</td>
          </ng-container>

       
  
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <button id="export" class="icoDownload" mat-icon-button (click)="exportIt() "><i class="bi bi-file-earmark-arrow-down"></i> </button>
      <!--<button id="export" mat-raised-button color="primary" (click)="exporter.exportTable('csv',  {fileName:'Orders'})"><i class="bi bi-filetype-csv"></i></button>
      <button id="export" mat-raised-button color="primary" (click)="exporter.exportTable('json', {fileName:'Orders'})">Export To json</button>
      <button id="export" mat-raised-button color="primary" (click)="exporter.exportTable('txt',  {fileName:'Orders'})">Export To txt</button>-->
    
          <mat-paginator [length]="pageLength"
              [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions"
              (page)="pageEvent($event)"
              showFirstLastButtons 
              aria-label="Select page of periodic elements">
          </mat-paginator>
    </div>
    </main>