
    <div class="pagetitle">
        <h1  i18n>{{"Organization" | translate }}</h1>      
     </div>
     <section class="section">
        <form [formGroup]="registerForm">
 
 
         <div class="row">
             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Name" | translate }} <span class="obligatorio">*</span></h5>
                         <input formControlName="name" matInput type="text" matInput required >
                         <mat-error *ngIf="registerForm.get('name')?.hasError('required')"  i18n>{{"Please enter a name!" | translate }}</mat-error>
                         <mat-error *ngIf="registerForm.get('name')?.hasError('pattern')"  i18n>{{"Invalid input format" | translate }}</mat-error>
                         <mat-error *ngIf="registerForm.get('name')?.hasError('maxLength')"  i18n>{{"Maximum length exceeded" | translate }}</mat-error>
                     </mat-form-field>
                 </div>
             </div>
             <div class="col-lg-6">
               <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Domain" | translate }} <span class="obligatorio">*</span></h5>
                        <input formControlName="domainRoot" matInput type="text" matInput required >
                        <mat-error *ngIf="registerForm.get('domainRoot')?.hasError('required')"  i18n>{{"Please enter a domain!" | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('domainRoot')?.hasError('pattern')"  i18n>{{"Invalid input format" | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('domainRoot')?.hasError('maxlength')" i18n>
                            {{"Maximum length exceeded" | translate}} </mat-error>
                         </mat-form-field>
                </div>
            </div>
        </div>      
     
        <div class="row">           
            <div class="col-lg-6">
               <div class="card cardCustos">
                   <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Route type" | translate }} <span class="obligatorio">*</span></h5>
                        <mat-select formControlName="enrutado" [(value)]="selectedEnrutado">
                            <mat-option *ngFor="let op of listEnrutado" [value]="op">
                                {{op}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
       </div>

       <div class="row" *ngIf="verProcessor">
        <div class="col-lg-12">
           <div class="card cardCustos">
            <h5 i18n>{{"Processor" | translate }} <span class="obligatorio">*</span></h5>
                       
            <mat-accordion  multi>

            <mat-expansion-panel *ngFor="let item of listprocessor;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <span style="width: 300px;">{{item.name}}</span>
                        <mat-slide-toggle (change)="activeProcesor(item, $event)" [checked]="item.active" ></mat-slide-toggle>
                        <span *ngIf="item.active"  class="colorTrue" i18n>{{"Active" | translate }}</span>
                        <span *ngIf="!item.active" class="colorFalse" i18n>{{"Inactive" | translate }}</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <code>
                           

                            <ng-container formArrayName="arreglo" *ngIf="arregloFormGroups['controls'].length > 0">

                                <div  *ngFor="let fila of arregloFormGroups['controls']; index as i" [formGroupName]="i">
                                    <div class="row mb-2 col-12" *ngIf="getshortNameArregloFormGroups(i)===item.shortName">
                                        
                                        <div class="col-5">
                                            <input type="text" class="form-control" formControlName="llave" placeholder="{{'key' | translate }}">
                                        </div>
                                        
                                        <div class="col-6">
                                            <input type="text" class="form-control" formControlName="valor" placeholder="{{'value' | translate }}">
                                        </div>
                                    
                                        <div class="col-1">
                                            <button class="btn btn-danger" (click)="borrarGrupo(i)">{{"delete" | translate }}</button>
                                        </div>
                                    </div>
                                </div>
                            
                            </ng-container>

                            <button  class="btn btn-aceptar" (click)="onAddLista(item)" i18n>{{"Add" | translate }}</button> 
                            
                    
                </code>
            </mat-expansion-panel>
      </mat-accordion>
</div></div></div>



       <div class="row">
            <div class="col-lg-12">
                <div class="card cardCustos">               
                        <h5 i18n>{{"Activate Hierarchy" | translate }}</h5>
                        <section class="example-section">
                        <mat-checkbox class="example-margin" formControlName="org_Reg"  > {{"Region" | translate }} </mat-checkbox>
                        <mat-checkbox class="example-margin" formControlName="org_Div"  > {{"Division" | translate }}</mat-checkbox>
                        <mat-checkbox class="example-margin" formControlName="org_Sub"  > {{"Subsidiary" | translate }}</mat-checkbox>
                        </section>               
                </div>           
            </div>
      </div>
 
       

 
        <div class="row botonera">
             <div class="col-lg-12">
                     <button  class="btn btn-aceptar" (click)="onFormSubmit()" i18n>{{"Submit" | translate }}</button> 
                     <button  class="btn btn-cancelar" (click)="formExit()" i18n>{{"Cancel" | translate }}</button>
             </div>
         </div>
 
 
       
     </form>
     </section>
 
                 
                 
                 
                 
                 
                 
 
 