import { Hierarchy } from "./hierarchy.model";

export class Merchants {
  id!: string;
  name!: string;
  domain!: string;
  structure!: string;
  type!: string;
  locationName!: string;
  merchantId!: string;
  properties: MerchantsProperties | undefined;  
  info: MerchantsInfo | undefined;
  processors: MerchantsProcessors[] | undefined;
  hierarchy: Hierarchy | undefined;
}

export class MerchantsProperties {
  categoryCode:string | undefined;
}

export class MerchantsInfo {
  email:string | undefined;
  phone:string | undefined;
  websiteUrl:string | undefined;
  local:string | undefined;
  franquicia:string | undefined;
  address: MerchantsAddress | undefined;
  businessContact: MerchantsContact | undefined;
  technicalContact: MerchantsContact | undefined;
  emergencyContact: MerchantsContact | undefined;
}

export class MerchantsProcessors {
  short:string | undefined;
  keyId:string | undefined;
  key:string | undefined;
  merchantId: string | undefined;
  templateId: string | undefined;
}


export class MerchantsAddress {
  addressLine1:string | undefined;
  addressLine2:string | undefined;
  city:string | undefined;
  postalOrZipCode:string | undefined;
  extendedZip:string | undefined;
  country:string | undefined;
  state:string | undefined;
  timeZone:string | undefined;
}


export class MerchantsContact {
  firstName:string | undefined;
  lastName:string | undefined;
  phone:string | undefined;
  email: string | undefined;
  sameAsBusinessContact: boolean | undefined;
}

