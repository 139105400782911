import { Inject, Injectable } from '@angular/core';
import { map } from 'rxjs';
import { AUTH_URL } from 'src/app/auth/app.oauth-tokens';
import { Groups } from 'src/app/models/groups.model';
import { RequestFilter } from 'src/app/models/requestFilter.model';
import { Rol } from 'src/app/models/rol.model';
import { CommonService } from 'src/app/services/common.service';
import { RestService } from 'src/app/services/merchant/Rest.service';
import { AuthRestUrl } from 'src/app/util/constants';


@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  private url: any;

  constructor(private merchantRest : RestService, public commonService: CommonService) {
    }
  
    async findService(_body: RequestFilter, showLoading: boolean){
      //AuthRestUrl+'user/'
      let value = await this.merchantRest.commonRestCallAuth(_body, 'group/', showLoading,true)
      .catch(err => {      
        return null
      })
      return value;   
    }

    public findServiceObservable(_body:RequestFilter, showLoading: boolean) {
      return this.merchantRest.commonRestCallAuthObservable(_body, 'group/', showLoading, true)
      .pipe(
        map(res => res));
    }

    async getById(_id: string, showLoading: boolean){
      let _body={"id":_id} 
      let value = await this.merchantRest.commonRestCallAuth(_body, 'group/getById', showLoading,true)
      .catch(err => {      
        return null
      })
      return value;   
    }
  

    
    async saveMembers(_body: Groups){
      let value = await this.merchantRest.commonRestCallAuth(_body, 'group/saveMembers',true,true)   
      .catch(err => {
        return null
      })
  
      return value;   
    }

    async saveEditService(_body: Groups){
      let value = await this.merchantRest.commonRestCallAuth(_body, 'group/save',true,true)   
      .catch(err => {
        return null
      })
  
      return value;   
    }
  
    async deleteService(_id: string){
      let _body={"id":_id} 
      let value = await this.merchantRest.commonRestCallAuth(_body, 'group/delete',true,true)   
      .catch(err => {
        return null
      })
      return value;   
    }

 
}