import {
  Component,
  OnInit,
  ElementRef,
  Input,
  AfterViewInit,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
} from 'ng-apexcharts';
import { DateFormats } from 'src/app/util/constants';
import { formatDate } from '@angular/common';
import { DashboardService } from 'src/app/pages/dashboard/dashboard.service';
import { DEFAULT_LOCALE, LOCALES } from 'src/app/util/chartsLocales';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'app-chart-compare-tipes-by-day',
  templateUrl: './chart-compare-tipes-by-day.component.html',
  styleUrls: ['./chart-compare-tipes-by-day.component.css'],
})
export class ChartCompareTipesByDayComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() chartTitle!: string;
  @Input() aggCmpTypeByDay: {
    payments: {},
    refunds: {}
  } = {
    payments: {},
    refunds: {}
  }

  private initialized: boolean = false;

  public chartOptions!: Partial<ChartOptions>;

  private localeFormat: keyof typeof DateFormats = 'en';

  constructor(
    private translate: TranslateService,
    private readonly dashboardService: DashboardService
  ) {}

  ngAfterViewInit(): void {
    this.init();
  }

  @ViewChild('chartCV', { static: true }) chart!: ChartComponent;

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.init();
  }

  private init() {
    this.localeFormat = this.dashboardService.setFormat(navigator.language);

    this.chartOptions = {
      series: [{ data: [] }, { data: [] }, { data: [] }, { data: [] }],
      chart: {
        type: 'line',
        locales: LOCALES,
        defaultLocale : DEFAULT_LOCALE,
        zoom: {
          enabled: false
        }
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        min: 0,
      },
      legend: {
        markers: {
          width: 12,
          height: 12,
          radius: 0
        }
      },
      tooltip: {
        enabled: true,
        x: {
          format: DateFormats[this.localeFormat]
        }
      }
    };
    !this.initialized && this.translate
      .get(['operations', 'Payments', 'PREV_PAYMENTS', 'Refunds', 'PREV_REFUNDS'])
      .subscribe((translation) => {
        this.initialized = true;
        this.createChart(translation);
      });
    this.initialized && this.printTable();
    ;
  }

  private _manageOperationValues(transactionData: any) {
    
    const prevValuesArray: any[] = [];
    const actualValuesArray: any[] = [];
    transactionData?.actualValues?.forEach(
      (bucket: any) => {
      const newDate = formatDate(bucket.key, DateFormats[this.localeFormat], this.translate.currentLang)
      
      const dateAlreadyOnArray = this.chartOptions.xaxis?.categories.find(
        (date:any) => formatDate(date, DateFormats[this.localeFormat], this.translate.currentLang)
        === newDate);
      !dateAlreadyOnArray && this.chartOptions.xaxis?.categories.push(newDate);
      actualValuesArray.push(bucket.number);
    });
    transactionData?.prevValues?.forEach(
      (bucket: any) => {
        prevValuesArray.push(bucket.number);
    });
    if (actualValuesArray.length < prevValuesArray.length) {
      prevValuesArray.shift();
    }
    return {
      prevValuesArray,
      actualValuesArray,
    };
  }

  private printTable() {    
    const payments = this._manageOperationValues(this.aggCmpTypeByDay.payments);
    const refunds = this._manageOperationValues(this.aggCmpTypeByDay.refunds);

    this.chartOptions.series![0].data = payments.prevValuesArray;
    this.chartOptions.series![1].data = payments.actualValuesArray;
    this.chartOptions.series![2].data = refunds.prevValuesArray;
    this.chartOptions.series![3].data = refunds.actualValuesArray;
    const calculatedTicAmount = this._calculateTickAmount([
      payments.prevValuesArray,
      payments.actualValuesArray,
      refunds.prevValuesArray,
      refunds.actualValuesArray]);
    this.chartOptions.yaxis = {
      tickAmount: calculatedTicAmount <= 7 ? calculatedTicAmount : 7,
      min: 0
    }
    this.chartOptions.dataLabels = {enabled: false};
    this.chart?.updateOptions(this.chartOptions);
  }

  private _calculateTickAmount(dataArray: any[]) {
    const longestArray = Math.max(...dataArray.map(serie => serie.length));
    const biggestValue = dataArray.flatMap(innerDataArray => innerDataArray)
      .find(data => data > 7);
    return biggestValue ? Math.max(Math.min(longestArray || 1, 7), Math.min(biggestValue || 1, 7)) : 1;
  }

  createChart(translation: any) {
    this.chartOptions = {
      series: [
        {
          name: translation.PREV_PAYMENTS,
          data: [],
        },
        {
          name: translation.Payments,
          data: [],
        },
        {
          name: translation.PREV_REFUNDS,
          data: [],
        },
        {
          name: translation.Refunds,
          data: [],
        },
      ],
      chart: {
        type: 'line',
        locales: LOCALES,
        defaultLocale : DEFAULT_LOCALE,

      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: [],
        tickAmount: 6
      },
      yaxis: {
        min: 0,
        title: {
          text: translation.operations,
        },
        tickAmount: 2
      },
      legend: {
        markers: {
          width: 12,
          height: 12,
          radius: 0
        }
      },
      // fill: {
      //   type: "gradient",
      //    gradient: {
      //     shadeIntensity: 1,
      //     opacityFrom: 0.7,
      //     opacityTo: 0.9,
      //     stops: [0, 100]
      //   }},
      tooltip: {
        enabled: true,
        x: {
          format: DateFormats[this.localeFormat]
        },
        y: {
          formatter: function (val) {
            return val + ' ' + translation.operations;
          },
        }
      }
    };
  }
}
