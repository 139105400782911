import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { ConfirmDialogComponent } from "src/app/components/confirm-dialog/confirm-dialog.component";
import { ConfirmDialogActions, ConfirmDialogData } from "src/app/components/confirm-dialog/confirm-dialog.model";
import { QueryFilterList, RequestFilter } from "src/app/models/requestFilter.model";
import { Rol } from "src/app/models/rol.model";
import { CommonService } from "src/app/services/common.service";
import { environment } from "src/environments/environment";
import { FormRolComponent } from "./form-rol/form-rol.component";
import { RolsService } from "./rol.service";
import { TranslateService } from '@ngx-translate/core';
import { Subscription, debounceTime, distinctUntilChanged } from "rxjs";
import { NotifierService } from "angular-notifier";
import { UtilService } from "src/app/services/util.service";
import { AuthService } from "src/app/auth/auth.service";
import { ModalManagerService } from "src/app/services/modalManager.service";
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { DateFormats } from "src/app/util/constants";
import { DashboardService } from "../../dashboard/dashboard.service";

enum Columns {
  name = 'name',
  description = 'description',
  createdAt = 'createdAt',
  isGlobal = 'isGlobal',
  status = 'status',
  view = 'view',
}

enum Columns_User {
  name = 'name',
  description = 'description',
  createdAt = 'createdAt',
  status = 'status',
  view = 'view',
}

@Component({
  selector: 'app-rol',
  templateUrl: './rol.component.html',
  styleUrls: ['./rol.component.css']
})
export class RolComponent implements OnInit, AfterViewInit {

  displayedColumns =  Object.keys(Columns);  

  dataSource = new MatTableDataSource<Rol>();
  jerarquiaSearchSbscription!: Subscription;
  pageLength=0;
  pageIndex=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId=Columns.name+",desc";//idColumna + "," + direccion
  domainSelect: string = "";
  isAdmin:boolean = false;



  createdAtFilter = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  nameFilter = new FormControl();
  descFilter = new FormControl();
  isGlobalFilter = new FormControl();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private dialogRef!: MatDialogRef<FormRolComponent>;

  mapQuery = new Map();
  rf: RequestFilter= new RequestFilter(null,0,this.pageSize,this.sortId);
  translations: any;
  statusSelect: string[] = ['',  'ACTIVE', 'PREACTIVE'];

  constructor(public dialog: MatDialog, 
    public services:RolsService,
    public commonService : CommonService,
    private utilService: UtilService,
    private authService: AuthService,
    private translate: TranslateService,
    private modalManager: ModalManagerService,
    private readonly dashboardService: DashboardService
  ) {    
  }




  async lanzarLlamada(){

//mostramos solo las activas

    let value = await this.services.findService(this.rf,true);
    console.log(JSON.stringify(value))
    if(value){
      console.log(JSON.stringify(value.content))
      this.dataSource = new MatTableDataSource<Rol>(value.content);
      this.pageLength=value.totalElements;
    }
}


  ngOnInit(): void {
    let perfil = this.authService.userAccessLevel();
    if(perfil === "ADMIN"){
      this.isAdmin = true;
      this.sortId = Columns.name+",desc";
    } else {
      this.isAdmin = false;
      this.sortId = Columns_User.name+",desc";
    }
    this.rf = new RequestFilter(null, 0, this.pageSize, this.sortId);
    this.translate
      .get([
        "USERS"
      ])
      .subscribe(translation => {
        this.translations = translation.USERS.STATUS;
        this.statusSelect = ['', ...Object.keys(translation.USERS.STATUS)];
      });

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.jerarquiaSearchSbscription = this.authService.getJerarquiaSearchClicked().subscribe(() => this.cargarCache());

    this.lanzarLlamada();

    this.nameFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = this.isAdmin ? Columns.name : Columns_User.name;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();    
    });

    this.descFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = this.isAdmin ? Columns.description  : Columns_User.description;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLlamada();    
    });
   
    this.createdAtFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      const idColumn = this.isAdmin ? Columns.createdAt : Columns_User.createdAt;    
      console.log(idColumn+"->start="+value.start+"   end="+value.end);        
      this.rf.filter = QueryFilterList.construirRangoFechaFilter(this.mapQuery, idColumn, value.start,value.end);        
      this.lanzarLlamada(); 
    });
    
    if ( this.isAdmin ) {
      this.isGlobalFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
        const idColumn = Columns.isGlobal;    
        console.log(idColumn+"="+ value);        
        this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
        this.lanzarLlamada(); 
      });
    }
  }

  

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent(pag:PageEvent){
    console.log("pageEvent="+JSON.stringify(pag)) 
    this.pageSize = pag.pageSize;
    this.pageIndex = pag.pageIndex;
    this.rf = RequestFilter.construirPage(this.rf.filter , pag.pageIndex,pag.pageSize,this.sortId);
    this.lanzarLlamada();
  }
 
  announceSortChange(sortState: Sort) {
    console.log("ordenar="+JSON.stringify(sortState)) 
    let active = sortState.active.replace("_",".")
    let direccion="";
    if(sortState.direction){
      direccion=","+sortState.direction
    }
    this.sortId=active+direccion;
    this.rf = RequestFilter.construirPage(this.rf.filter , this.pageIndex,this.pageSize,this.sortId);
   this.lanzarLlamada();
  }




   onNew(){
    this.dialogRef = this.dialog.open(FormRolComponent, {
      width: '70%', panelClass: 'custom-modalbox',
      data: {//vacio al ser nuevo
      }
    });
    this.modalManager.registerModal(this.dialogRef);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {     
        console.log('CONFIRM recived from dialog window');
        this.lanzarLlamada()
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
   }

   onView(row: any) {

    this.dialogRef = this.dialog.open(FormRolComponent, {
      width: '70%', panelClass: 'custom-modalbox',
      data: {
        obj: row,
        edit: false
      }
    });
    this.modalManager.registerModal(this.dialogRef);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {      
        console.log('CONFIRM recived from dialog window');
        this.lanzarLlamada()
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }


   onEdit(row: any) {

    this.dialogRef = this.dialog.open(FormRolComponent, {
      width: '70%', panelClass: 'custom-modalbox',
      data: {
        obj: row,
        edit: true
      }
    });
    this.modalManager.registerModal(this.dialogRef);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.lanzarLlamada()
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }



   onDelete(row: any) {

    console.log('Delete pressed: ', row);
 
      const dialogData = new ConfirmDialogData();
      this.translate
      .get([
        "Delete Rol",        
        "Are you sure you want to delete it?"
      ])
      .subscribe(translation => {
          dialogData.titleI18n = translation["Delete Rol"];
          dialogData.textI18n = translation["Are you sure you want to delete it?"];
          dialogData.textValue = row.name;
      
        this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '25%', panelClass: 'custom-modalbox',
          data: dialogData
        });
        this.modalManager.registerModal(this.dialogRef);

      });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        console.log('CONFIRM recived from dialog window');
        await this.services.deleteService(row.id);
        this.lanzarLlamada();
      } else if (result === ConfirmDialogActions.CANCEL) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }

  cargarCache() {

    let value = this.utilService.cargarCacheDomain();
      
    if(value && value!="undefined"){
      this.domainSelect = value;
      // this.rf.filter = QueryFilterList.construirFilterDomain(this.mapQuery, "domain", value);        
    }else{
      this.domainSelect = "ALL";
      // this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", undefined);            
    }
      
    this.lanzarLlamada();  
  }

  statusSelectFilter(ob:MatSelectChange) {
    let value = ob.value
    const idColumn = this.isAdmin ? Columns.status : Columns_User.status;
    console.log(idColumn+"="+value);  
    if(value && value==""){
      value=null
    }   
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.lanzarLlamada(); 
  }

  public calculateTimeZone(date: any) {
    return this.dashboardService.calculateTimeZone(date);
  }

}






