

<div class="pagetitle">
    <h1  i18n>{{"Group" | translate}}</h1>      
 </div>
 <section class="section">
        <mat-tab-group  [selectedIndex]="marcado" [dynamicHeight]>
            <mat-tab label="{{ 'Info' | translate}}" [disabled]='disableEdit' >
                <app-form-groups class="ajuste-contenedor-tab"  [group]="group"></app-form-groups>
            </mat-tab>
        
            <mat-tab label="{{ 'Members' | translate}}" [disabled]='disableAddMember' >
                 <app-members-groups class="ajuste-contenedor-tab"   [group]="group"></app-members-groups>
            </mat-tab>
        </mat-tab-group>
</section>
  