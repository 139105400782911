import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { CHART_COLORS } from '../../util/chartsutils';
import { TranslateService } from '@ngx-translate/core';

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
} from 'ng-apexcharts';
import { CurrencyPipe } from '@angular/common';
import { DEFAULT_LOCALE, LOCALES } from 'src/app/util/chartsLocales';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'app-chart-compare-tipes-by-hour',
  templateUrl: './chart-compare-tipes-by-hour.component.html',
  styleUrls: ['./chart-compare-tipes-by-hour.component.css'],
  providers: [CurrencyPipe]
})
export class ChartCompareTipesByHourComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() aggCmpTransactionsByHour: any;
  @Input() transactionType!: string;
  @Input() currency?: string;
  private initialized: boolean = false;
  private translations: any;

  public chartOptions!: Partial<ChartOptions>;

  constructor(
    private translate: TranslateService,
    private currencyPipe: CurrencyPipe
  ) {}

  ngAfterViewInit(): void {}

  @ViewChild('chartCV', { static: true }) chart!: ChartComponent;

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.init();
  }

  private init() {
    this.chartOptions = {
      series: [{ data: [] }, { data: [] }],
      chart: {
        type: 'line',
        locales: LOCALES,
        defaultLocale : DEFAULT_LOCALE,
      },
      stroke: {
        curve: 'smooth',
        colors: [
          CHART_COLORS.red, CHART_COLORS.green
        ]
      },
      xaxis: {
        categories: [],
        labels: {
          rotate: -45
        }
      },
      yaxis: {
        min: 0,
      },
      legend: {
        markers: {
          width: 12,
          height: 12,
          radius: 0,
          fillColors: [
            CHART_COLORS.red, CHART_COLORS.green
          ]
        }
        
      },
      // fill: {
      //   type: "gradient",
      //   gradient: {
      //     shadeIntensity: 0.8,
      //     opacityFrom: 0.7,
      //     opacityTo: 0.5,
      //     stops: [0, 100]
      //   },
      //   colors: [
      //     CHART_COLORS.red, CHART_COLORS.green
      //   ]
      // },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        marker: {
          fillColors: [
            CHART_COLORS.red, CHART_COLORS.green
          ]
        }
      }
    };
    !this.initialized && this.translate
      .get(['operations', 'TABLES_CONFIG'])
      .subscribe((translation) => {
        this.initialized = true;
        this.translations = translation.TABLES_CONFIG.TRANSACTIONS_DAILY_PER_HOUR[this.transactionType];
        this.createChart(translation);
      });
    this.initialized && this.printTable();
    ;
  }

  private _manageOperationValues(transactionData: any) {
    const prevValuesArray: any[] = [];
    const actualValuesArray: any[] = [];
    transactionData?.actualValues?.forEach(
      (bucket: any) => {
        const formatedHour = (bucket.key).padStart(2, '0') + ':00';
        this.chartOptions.xaxis?.categories.push(formatedHour);
        
        actualValuesArray.push(this._manageSeriesByChartType(bucket));
    });
    transactionData?.prevValues?.forEach(
      (bucket: any) => {
        prevValuesArray.push(this._manageSeriesByChartType(bucket));
    });
    return {
      prevValuesArray,
      actualValuesArray,
    };
  }

  private _manageSeriesByChartType(bucket: {name: string, number: number, subAggregation: any}) {
    switch (this.transactionType) {
      case 'PAYMENTS':
        return bucket.number || 0;
        break;
      case 'TOTAL_CLEAN_AMOUNT':
        return bucket.subAggregation?.value || 0;
        break;
      case 'TRANSACTIONS_VISA':
        return bucket.subAggregation?.buckets?.find((bucket: any) => bucket.key === 'VISA')?.number || 0;
        break;
      case 'TRANSACTIONS_MASTERCARD':
        return bucket.subAggregation?.buckets?.find((bucket: any) => bucket.key === 'MASTERCARD')?.number || 0;
        break;
    }
  }

  private printTable() {
    const transaction = this._manageOperationValues(this.aggCmpTransactionsByHour);

    this.chartOptions.series![0].data = transaction.prevValuesArray;
    this.chartOptions.series![1].data = transaction.actualValuesArray;
    
    this.chartOptions.dataLabels = {enabled: false};
    this.chartOptions.stroke = {
      curve: 'smooth',
      colors: [
        CHART_COLORS.red, CHART_COLORS.green
      ]
    }
    this.chartOptions.yaxis = {
      labels: {
        formatter: (val: number) => {
          return this._transformedValue(val) as string;
        }
      }
    }
    if (this.chart && this.aggCmpTransactionsByHour) {
      this.chart?.updateOptions(this.chartOptions);
    }
  }

  private _transformedValue(val: number): string | null {
    const coin = this.currencyPipe.transform(val, this.currency, 'symbol', '1.2-2', this.translate.currentLang)
    return this.currency ? coin : val.toString();
  }

  createChart(translation: any) {
    this.chartOptions = {
      series: [
        {
          name: this.translations.PREV_LABEL,
          data: [],
        },
        {
          name: this.translations.ACTUAL_LABEL,
          data: [],
        }
      ],
      chart: {
        type: 'line',
        locales: LOCALES,
        defaultLocale : DEFAULT_LOCALE,
        zoom: {
          enabled: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        categories: [],
        tickAmount: 6,
        labels: {
          rotate: -45
        }
      },
      legend: {
        markers: {
          width: 12,
          height: 12,
          radius: 0
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + ' ' + translation.operations;
          }
        }
      },
    };
  }
}
