import { Injectable } from "@angular/core"
import { MatDialogRef } from "@angular/material/dialog"

@Injectable({ providedIn: 'root' })
export class ModalManagerService {
    private registeredModals: MatDialogRef<any>[] = [];
    
    public registerModal(dialog: MatDialogRef<any>) {
        this.registeredModals.push(dialog);
    }

    public closeAllModals() {
        this.registeredModals.forEach(dialog => {
            dialog.close();
        });
        this.registeredModals = [];
    }
}