import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common'
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import packageJson from '../../../../package.json';
import { AuthRestUrl, ENTORNO } from 'src/app/util/constants';
import { VIWER_ENTORNO } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public version: string = packageJson.version // + " ----- " + VIWER_ENTORNO+ " ----- ";
  public isAuthenticated$: Observable<boolean>;
  entorno = VIWER_ENTORNO
  titulo: string = '';

  constructor(@Inject(DOCUMENT) private document: Document,
  private _router: Router,
  private authService: AuthService) {
    this.isAuthenticated$ = this.authService.isAuthenticated$;
  }



  @ViewChild('headLogoutForm', { read: ElementRef })
  headLogoutForm!: ElementRef; 

/*
  ngAfterContentInit() {
    //console.debug("logout() form submit");
    //this.headLogoutForm.nativeElement.submit();
 }*/
  ngOnInit(): void {
    this.tituloEntorno();
  }
  
  sidebarToggle() {
    //toggle sidebar function
    this.document.body.classList.toggle('toggle-sidebar');
  }

  logout() { 
    console.debug("logout() method");
    //this.authService.logout(); 
    this.authService.logoutAndClear()
    //this._router.navigate(["/welcome"]);
    
    this.headLogoutForm.nativeElement.submit();
  }
  tituloEntorno() {
    if (this.entorno === ENTORNO.XIIBERO) {
      this.titulo = 'Xiibero';  // Asigna el título para XIIBERO
    } else {
      this.titulo = 'Merchants';   // Asigna el título para otros entornos

    }
  }
  procesaPropagar(mensaje:string) {
    this.authService.jerarquiaSearchClicked.next(mensaje);
  }
  
  get logoutBaseUrl(){
    return AuthRestUrl + 'redlogout';
  }
  get redirect(){
    return window.location.origin + '/welcome';
  }

  public get username(){
    return this.authService.username;
  }
  public get fullName(){
    return this.authService.fullName;
  } 

}
