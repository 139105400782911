import { Pipe, PipeTransform } from '@angular/core';
import { Merchants } from 'src/app/models/merchants.model';
import { MerchantService } from 'src/app/pages/views/merchants/merchant.service';

@Pipe({
  name: 'formatMerchantName'
})
export class FormatMerchantNamePipe implements PipeTransform {
  private merchants: Merchants[] = [];

  constructor(private merchantService: MerchantService) {
    this.merchantService.getFindResult().subscribe({
      next: (merchants) => {
        this.merchants = merchants.content;
      },
      error: (err) => {
        console.error('Failed to load merchants in Pipe:', err);
      },
      complete: () => console.log('Merchants loading completed.')
    });
  }

  
  transform(domain: string): string {
    const domainRoot = domain; 
    const merchant = this.merchants.find(merchant => merchant.domain === domainRoot);

    let nomb="";

    if(merchant){
      if(merchant.type==="MERCHANT"){
        nomb=merchant.name
      }
    }


    return nomb;
  }
}
