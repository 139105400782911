import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { Merchants } from 'src/app/models/merchants.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { CommonService } from 'src/app/services/common.service';
import { VIWER_ENTORNO, environment } from 'src/environments/environment';
import { MerchantService } from './merchant.service';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { FormMerchComponent } from './form-new/form-merch.component';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { ENTORNO } from 'src/app/util/constants';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { OrganizationsService } from '../organizations/organizations.service';
import { Organization } from 'src/app/models/organization.model';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';

import * as XLSX from "xlsx";
import { DataExport_Merchant_Xiibero } from 'src/app/models/dataExport/dataExport_merchant_Xiibero.model';
import { DataExport_Merchant } from 'src/app/models/dataExport/dataExport_merchant.model';
import { countryListAllIsoData } from 'src/app/util/country';
import { ModalManagerService } from 'src/app/services/modalManager.service';

declare var require:any

enum Columns_Merchant {
  merchantId = 'merchantId',
  name = 'name', 
  organization_name = 'organization.name', 
  properties_categoryCode = 'properties.categoryCode',
  info_email = 'info.email', 
  locationName = 'locationName'
}

enum Columns_Xiibero {
  name = 'name', 
  organization_xiibero = 'hierarchy.ORGANIZATION.name',
  region_xiibero = 'hierarchy.REGION.name',
  division_xiibero = 'hierarchy.DIVISION.name',
  properties_categoryCode = 'properties.categoryCode',
  info_address_country = 'info.address.country', 
  info_address_state = 'info.address.state',
  info_address_city = 'info.address.city',
  view = 'view'
}



@Component({
  selector: 'app-merchants',
  templateUrl: './merchants.component.html',
  styleUrls: ['./merchants.component.css']
})
export class MerchantsComponent implements OnInit, AfterViewInit {

  displayedColumns =  Object.keys(Columns_Merchant);
  dataSource = new MatTableDataSource<Merchants>();

  entorno = VIWER_ENTORNO
  
  pageLength=0;
  pageSize=environment.defaultPaginacion;
  pageIndex: number = 0;
  pageSizeOptions=environment.paginacion;
  sortId=Columns_Merchant.name+",desc";//idColumna + "," + direccion


  merchantEmailFilter = new FormControl();
  nameFilter = new FormControl();
  merchantIdFilter = new FormControl();
  locationNameFilter = new FormControl();
  categoryCodeFilter = new FormControl();
  organizationFilter = new FormControl();
  cityFilter = new FormControl();
  stateFilter = new FormControl();
  organizationXiiberoSelect!: Organization;
  regionXiiberoSelect!: Merchants;
  divisionXiiberoSelect!: Merchants;
  countrySelect!: Merchants;
  organizationXiiberoList:Organization[] = [];
  regionXiiberoList:Merchants[] = [];
  divisionXiiberoList:Merchants[] = [];  
  listaPaises:any[]=countryListAllIsoData;


  mapQuery = new Map();
  requestFilter: RequestFilter= new RequestFilter(null,0,this.pageSize,this.sortId);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private dialogRef!: MatDialogRef<FormMerchComponent>;


  jerarquiaSearchSbscription!: Subscription;
  private readonly notifier: NotifierService;

  constructor(
      private merchantService : MerchantService,
      public dialog: MatDialog,
      public utilServer: UtilService,
      public authServer: AuthService,notifierService: NotifierService,
      private orgService: OrganizationsService,
      public commonService : CommonService,
      private translate: TranslateService,
      private modalManager: ModalManagerService) {  
        this.notifier = notifierService;
  }
  
  categoryCodeDesc(cod:any){
    let mcc = require('merchant-category-code');  
    let val = mcc(cod);
    if(val){
      return val.usda_description;
    }
    return  this.translate.instant("NO_define");
  }

async getDataSourceAndElements() {
  if(this.entorno === ENTORNO.XIIBERO){
    this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, "type", "MERCHANT"); 
  }
  const dataSourceAndPageLength = this.merchantService.lanzarLlamada(this.requestFilter);
  this.dataSource = (await dataSourceAndPageLength as any).dataSource;
  this.pageLength = (await dataSourceAndPageLength as any).pageLength;
}



async ngOnInit(): Promise<void> {

    this.jerarquiaSearchSbscription = this.authServer.getJerarquiaSearchClicked().subscribe(() => this.cargarCache());

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if(this.entorno==ENTORNO.XIIBERO){
      this.displayedColumns =  Object.keys(Columns_Xiibero);
    } else {
      this.displayedColumns =  Object.keys(Columns_Merchant);
    }


      await this.cargar();


    this.locationNameFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns_Merchant.locationName;
      console.log(idColumn+"="+value);     
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.getDataSourceAndElements();     
    });    
    this.categoryCodeFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns_Merchant.properties_categoryCode;
      console.log(idColumn+"="+value);     
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.getDataSourceAndElements();      
    });
    this.merchantEmailFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns_Merchant.info_email;
      console.log(idColumn+"="+value);     
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.getDataSourceAndElements();     
    });
    this.nameFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      const idColumn = Columns_Merchant.name;
      console.log(idColumn+"="+value);     
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.getDataSourceAndElements();  
    });   

    this.merchantIdFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      const idColumn = Columns_Merchant.merchantId;
      console.log(idColumn+"="+value);     
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.getDataSourceAndElements();  
    });   
    

    this.organizationFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      const idColumn = Columns_Merchant.organization_name;
      console.log(idColumn+"="+value);     
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.getDataSourceAndElements();   
  }); 
 
     
      this.cityFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
        const idColumn = Columns_Xiibero.info_address_city;
        console.log(idColumn+"="+value);     
        this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
        this.getDataSourceAndElements();     
      });
      this.stateFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
        const idColumn = Columns_Xiibero.info_address_state;
        console.log(idColumn+"="+value);     
        this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
        this.getDataSourceAndElements();     
      });

      
    await this.cargarCache()

  }



  async cargar(){   
    //organizaciones
      let rfOrg: RequestFilter= new RequestFilter(null,0, 100,"name,desc");     
      let srOrg = await this.orgService.find(rfOrg,true);     
      if(srOrg){
        this.organizationXiiberoList= srOrg.content;
        if(this.organizationXiiberoList.length==1){
          // Si solo hay 1 ponemos por defecto valor
          this.organizationXiiberoSelect = this.organizationXiiberoList[0];
        }
      }


     //traemos las REGIONES
      let sr = await this.utilServer.pedirRegiones(undefined,"R");     
      if(sr){
        this.regionXiiberoList= sr.content;
      }    

       //traemos las DIVISION
       sr = await this.utilServer.pedirDivision(undefined,"R");   
        if(sr){
          this.divisionXiiberoList= sr.content;
        }


    }




   
    
    cargarCache() {
         
      let value = this.utilServer.cargarCacheDomain();
        
      if(value && value!="undefined"){        
      value = value.replace(";","")
        this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", value);        
      }else{
        this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", undefined);            
      }
        
      this.getDataSourceAndElements();  
    }


    countryFilter(ob:MatSelectChange) {    
      const idColumn = Columns_Xiibero.info_address_country;
      console.log(idColumn+"="+ob.value);     
      this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, ob.value);        
      this.getDataSourceAndElements();   

    }


  xiiberoFilter(ob:MatSelectChange, tipo:string) {
    
    const idColumn = "domain";
    let value:string | undefined

    this.organizationXiiberoSelect= new Organization;
    this.regionXiiberoSelect= new Merchants;
    this.divisionXiiberoSelect= new  Merchants;

    if(tipo=="Org"){
      let org:Organization
      org=ob.value;       
      this.organizationXiiberoSelect=org;
      if(org){
        value=org.domainRoot+"."
      }
    }else  if(tipo=="Region"){
      let org:Merchants
      org=ob.value;
      this.regionXiiberoSelect=org;
      if(org){
        value=org.domain+"."
      }
    }else  if(tipo=="Division"){
      let org:Merchants
      org=ob.value;
      this.divisionXiiberoSelect=org;
      if(org){
        value=org.domain+"."
      }
     }


    this.requestFilter.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.getDataSourceAndElements();  
  }

  
 

 
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent(pag:PageEvent){
    console.log("pageEvent="+JSON.stringify(pag));
    this.pageSize = pag.pageSize;
    this.pageIndex = pag.pageIndex;
    this.requestFilter = RequestFilter.construirPage(this.requestFilter.filter , pag.pageIndex,pag.pageSize,this.sortId);
    this.getDataSourceAndElements();
  }
 
  announceSortChange(sortState: Sort) {
    console.log("ordenar="+JSON.stringify(sortState)) 
    let active = sortState.active
    active = active.replace("_",".")

    let direccion="";
    if(sortState.direction){
      direccion=","+sortState.direction
    }
    this.sortId=active+direccion;
    this.requestFilter = RequestFilter.construirPage(this.requestFilter.filter , this.pageIndex,this.pageSize,this.sortId);
   this.getDataSourceAndElements();
  }

  refrescar() {
    this.getDataSourceAndElements();
  }








  
  onNew(){
    this.dialogRef = this.dialog.open(FormMerchComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {//vacio al ser nuevo
      }
    });
    this.modalManager.registerModal(this.dialogRef);

    this.dialogRef.afterClosed().subscribe((result) => {
            
      if (result === 1) {        
        this.notifier.notify('success', this.translate.instant("Save Successfull"));
        console.log('CONFIRM recived from dialog window');
        this.getDataSourceAndElements();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      } else if (result === 2) {
        this.notifier.notify('error', 'No Save');
        console.log('ERROR recived from dialog window');
      }
    });
   }

   onEdit(row: any) {

    this.dialogRef = this.dialog.open(FormMerchComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {
        obj: row
      }
    });
    this.modalManager.registerModal(this.dialogRef);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        this.notifier.notify('success',this.translate.instant("Save Successfull"));
        console.log('CONFIRM recived from dialog window');
        this.getDataSourceAndElements();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      } else if (result === 2) {
        this.notifier.notify('error', 'No Save');
        console.log('ERROR recived from dialog window');
      }
    });
  }



   onDelete(row: any) {

    console.log('Delete pressed: ', row);
 
      const dialogData = new ConfirmDialogData();
      this.translate
      .get([
        "Delete Merchant",        
        "Are you sure you want to delete it?"
      ])
      .subscribe(translation => {
          dialogData.titleI18n = translation["Delete Merchant"];
          dialogData.textI18n = translation["Are you sure you want to delete it?"];
          dialogData.textValue = row.name;
      
        this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '25%', panelClass: 'custom-modalbox',
          data: dialogData
        });
        this.modalManager.registerModal(this.dialogRef);

      });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        await this.merchantService.deleteService(row.id);
        console.log('CONFIRM recived from dialog window');
        this.getDataSourceAndElements();
      } else if (result === ConfirmDialogActions.CANCEL) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }





  
  exportIt() {
    let newDate = new Date();
    let nombre = "merchant-" + newDate.toISOString().slice(0, 10) + "-" + newDate.getTime();
    const fileName = nombre + ".xlsx";

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.procesarDataExport(this.dataSource.data));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    let Heading;

    // Encabezados según el entorno
    if (this.entorno == ENTORNO.XIIBERO) {
        Heading = [[this.translate.instant("Organization"), this.translate.instant("Region"),this.translate.instant("Division"),this.translate.instant("MCC"), this.translate.instant("Name"), this.translate.instant("Country"), this.translate.instant("State"), this.translate.instant("City")]];
    } else {
        // Para GETNET
        Heading = [[this.translate.instant("Organization"),
          this.translate.instant("MCC"), 
          this.translate.instant("Name"),
          this.translate.instant("Email"),
           this.translate.instant("Location")]];
    }

    // Añadir encabezados a la hoja
    XLSX.utils.sheet_add_aoa(ws, Heading);
    // Añadir hoja al libro
    XLSX.utils.book_append_sheet(wb, ws, "merchant");
    
    // Generar archivo Excel
    XLSX.writeFile(wb, fileName);
}





private procesarDataExport(data:Merchants[]): any[]  {
  
  if(this.entorno==ENTORNO.XIIBERO){
    
    let result : DataExport_Merchant_Xiibero[] = [] ;
    data.forEach(row => {
      let tempRow:DataExport_Merchant_Xiibero = new DataExport_Merchant_Xiibero(row);
      result.push(tempRow);
    });
    return result;
  }else{   
    let result : DataExport_Merchant[] = [] ;
    data.forEach(row => {
      let tempRow:DataExport_Merchant = new DataExport_Merchant(row);
      result.push(tempRow);
    });
    return result;
  }
}

}

