<div class="hierarchy_container">
    <div id="barJerarquia" class="barJerarquia d-flex justify-content-center">

        <div class="d-flex hierarchy-title" style="float: left;   margin: 16px 0px 0 0;">
            <div class="titulito">{{"Hierarchy" | translate }}:</div>
        
            <button mat-icon-button matTooltip="{{'Remove filter' | translate}}" (click)="borrarFiltro()" style="float: left;    padding-top: 8px;" >
                <i class="bi bi-trash"></i>
            </button>
        </div>
    
        <form [formGroup]="registerForm" style="padding: 0;" class="hierarchy_form">
    
                <div class="cajaJerarquia">
                    <mat-form-field ngDefaultControl class="perosnalSelect">
                        <mat-select  formControlName="orgMarcado" [(value)]="selectedOrg" (selectionChange)="organizationFilter($event)">
                            <mat-option [value]="">- {{"All" | translate }} -</mat-option>
                            <mat-option *ngFor="let op of listaOrg" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('orgMarcado')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                    </mat-form-field>
                </div>
    
    
            <div  *ngIf="verRegion" class="flecha">/</div>        
            <div  *ngIf="verRegion" class="cajaJerarquia">
                    <mat-form-field ngDefaultControl class="perosnalSelect">
                        <mat-select  formControlName="regionMarcado" [(value)]="selectedRegion" (selectionChange)="regionFilter($event)">
                            <mat-option [value]="">- {{"All" | translate }} -</mat-option>
                            <mat-option *ngFor="let op of listaRegion" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('regionMarcado')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                    </mat-form-field>
            </div>
    
    
            <div  *ngIf="verDivision" class="flecha">/</div>       
            <div  *ngIf="verDivision" class="cajaJerarquia">
                    <mat-form-field ngDefaultControl class="perosnalSelect">
                        <mat-select  formControlName="divisionMarcado" [(value)]="selectedDivision"  (selectionChange)="divisionFilter($event)">
                            <mat-option [value]=""> - {{"All" | translate }} -</mat-option>
                            <mat-option *ngFor="let op of listaDivision" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('divisionMarcado')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                    </mat-form-field>
            </div>
    
            
    
            <div *ngIf="verMerchant" class="flecha">/</div>
            <div *ngIf="verMerchant" class="cajaJerarquia">
                    <mat-form-field ngDefaultControl class="perosnalSelect"> 
                        <mat-select  formControlName="merchantMarcado" [(value)]="selectedMerchant" (selectionChange)="merchantFilter($event)" >
                            <mat-option [value]="">- {{"All" | translate }} -</mat-option>
                            <mat-option *ngFor="let op of listaMerchant" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('merchantMarcado')?.hasError('required')" i18n>{{"REQUIRED_FIELD" | translate }}</mat-error>
                    </mat-form-field>
            </div>
    </form>
    </div>
</div>

