import { Component, OnInit, ElementRef, Input, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LOCALE, LOCALES } from '../../util/chartsLocales';
import { CurrencyPipe} from '@angular/common'

import {
  ChartComponent,
  ApexChart,
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexMarkers,
  ApexAnnotations,
  ApexLegend,
  ApexStroke,
  ApexResponsive
} from "ng-apexcharts";
import { DateFormats } from 'src/app/util/constants';
import { DashboardService } from 'src/app/pages/dashboard/dashboard.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  annotations: ApexAnnotations;
  legend: ApexLegend;
  colors: any;
  toolbar: any;
  responsive: ApexResponsive[];
};

@Component({
  selector: 'app-chart-tx-daily-amount',
  templateUrl: './chart-tx-amount.component.html',
  styleUrls: ['./chart-tx-amount.component.css'],
  providers: [CurrencyPipe]
})
export class ChartTxDailyAmountComponent implements OnInit, OnChanges, AfterViewInit  {
  @Input() chartTitle !: string;
  @Input() dates!: Date[];
  @Input() values!: Map<string, number[]>;
  @Input() currency?: string;
  @Input() isMultiCurrency?: boolean = false;

  
  public chartOptions?: Partial<ChartOptions>;
  tablesTranlations: {xTitle: string, yTitle : string} = {
    xTitle: '',
    yTitle: ''
  };
  private localeFormat: keyof typeof DateFormats = 'en';

  constructor(
    private translate: TranslateService,
    private currencyPipe: CurrencyPipe,
    private readonly dashboardService: DashboardService

  ) { }

  ngAfterViewInit(): void {
  }

  @ViewChild('chartDAM', { static: true }) chart?: ChartComponent;

  ngOnInit(): void {
    
  }

  ngOnChanges() {
    this.init();
  }

  data = [
    [0,0]
  ];

  private init() {
    this.localeFormat = this.dashboardService.setFormat(navigator.language);
    this.translate.get('TABLES_CONFIG.TRANSACTIONS_DAILY_AMOUNTS').subscribe(tablesTranslation => {
      this.tablesTranlations = {
        xTitle: tablesTranslation.XAXIS_TITLE,
        yTitle: tablesTranslation.YAXIS_TITLE
      }
      this.createChart();
      this.printTableContent()
    });
  }
  
  private createChart(){
    this.chartOptions = {
      series: [
        {
          data: this.data
        }
      ],
      chart: {
        defaultLocale : DEFAULT_LOCALE,
        locales: LOCALES, 
        type: "area",
        zoom: {
          enabled: false
        }
      },
      annotations: {
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0
      },
      xaxis: {
        type: "datetime",
        tickAmount: 6,
        title: {
          text: this.tablesTranlations.xTitle
        }
      },
      yaxis : {
        labels: {
          formatter: (val: number) => {
            return this._transformedValue(val) as any;
          }
        },
        title: {
          text: this.tablesTranlations.yTitle
        }
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        offsetX: 0
      },
      tooltip: {
        x: {
          format: DateFormats[this.localeFormat]
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100]
        }
      },
      responsive: [ 
        {
        }
      ]
    };

  }

  private _transformedValue(val: number): string | null {
    return this.currencyPipe.transform(val, this.currency, 'symbol', '1.2-2', this.translate.currentLang)
  }

  private printTableContent() {
    if (!(this.chart == undefined) && !(this.chartOptions == undefined) && !(this.dates == undefined)) {
      this.chartOptions.series = [];
      if (this.dates.length > 0) {
        for (let entry of this.values.entries()) {
          if(this.isMultiCurrency || (!this.isMultiCurrency && entry[0] === this.currency)) {
            var v : number[][] = new Array<Array<number>>();
            for (let i=0; i<this.dates.length; i++) {
              var item : number[] = new Array<number>();
              item.push(new Date(this.dates[i]).getTime());
              item.push(entry[1][i]);
              v.push(item);
            }
            this.chartOptions?.series!.push({ name : entry[0], data : v});
          }
        }
        this.chart?.updateSeries(this.chartOptions?.series!);
        // this.chart?.setLocale(this.translate.currentLang);
      }
    }
  }
}
