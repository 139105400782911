
<div class="row d-flex">
  <div class="col-sm-6 d-flex align-items-stretch">
    <div class="card fixed-size card-static-box card-static-box-first">
      <div class="card-body card-body__small pb-0">
        <div class="summary-card-titles"> 
          {{ 'DASHBOARD_SUMMARY_LITERALS.ONLINE_TERMINALS' | translate }}
        </div>
        <div class="card-static-content h3 d-flex justify-content-between">
          <i class="bi bi-activity icon-green"></i>
          <span class="custos-blue">{{ staticTerminalsAmounts.terminalsOnline }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6 d-flex align-items-stretch">
    <div class="card fixed-size card-static-box card-static-box-last">
      <div class="card-body card-body__small pb-0">
        <div class="summary-card-titles">
          {{ 'DASHBOARD_SUMMARY_LITERALS.TOTAL_TRANSACTIONS' | translate }}
        </div>
        <div class="card-static-content h3 d-flex justify-content-between">
          <i class="bi bi-credit-card icon-blue"></i>
          <span class="custos-blue"> {{staticTerminalsAmounts.operationsCarriedOut }}</span>
        </div>
      </div>
    </div>
  </div>
</div>








<div class="col-xxl-12 col-md-12">
  <div class="card info-card sales-card" >
     <div class="card-body" >
        <div class="summary-card-titles">{{ "DASHBOARD_SUMMARY_LITERALS.CURRENCY_OPERATIONS" | translate }} ( {{staticTerminalsAmounts.dashboardCurrency}} )</div>
        <div class="d-flex align-items-center">
           <div class="card-icon rounded-circle d-flex align-items-center justify-content-center"> <i
                 class="bi bi-file-earmark-text"></i></div>
           <div class="ps-3 row">
              <div class="dashboard-card-amount col-sm-12">
                 <span class="dashboard-summary-amount h3">{{ staticTerminalsAmounts.operationsByCurrency}} Total</span>
              </div>
              <div class="dashboard-card-option col-sm-12">
                <span class=" fs-6 lh-1 pt-1 fw-bold">{{ staticTerminalsAmounts.operationsSuccess }}    &nbsp;</span>                  
                  <span class=" fs-6 lh-1 pt-1 icon-green" i18n>{{ 'DASHBOARD_SUMMARY_LITERALS.SUCCESS' | translate }} &nbsp;</span>  
              </div>
              <div class="dashboard-card-option col-sm-12">
                <span class="fs-6 lh-1 pt-1 fw-bold">{{ staticTerminalsAmounts.operationsDeclined}}  &nbsp;</span>
                <span class="fs-6 lh-1 pt-1 icon-red" i18n>{{ 'DASHBOARD_SUMMARY_LITERALS.DECLINED' | translate }} &nbsp;</span>
              </div>
              <div class="dashboard-card-oprion col-sm-12">
                <span class="fs-6 lh-1 pt-1 fw-bold">{{ calculateStatusOthers(staticTerminalsAmounts.operationsByCurrency,staticTerminalsAmounts.operationsDeclined,staticTerminalsAmounts.operationsSuccess)}}           &nbsp;</span>                
                <span class="fs-6 lh-1 pt-1 icon-orange" i18n>{{ 'DASHBOARD_SUMMARY_LITERALS.OTHERS' | translate }} &nbsp;</span>
              </div>
           </div>
        </div>
     </div>
  </div>
</div>

